import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spinner,
  useTheme,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { AxiosError } from "axios";

import { useMessage } from "./MessageContext";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebaseConfig";

const PasswordResetRequest: React.FC = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { setMessage } = useMessage();
  const theme = useTheme();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("パスワードリセットメールを送信しました。", "success");
    } catch (err) {
      const error = err as AxiosError;
      if (error.response && error.response.status === 400) {
        setMessage(
          "パスワードリセットはこのユーザーには可能ではありません。",
          "error"
        );
      } else {
        setMessage(
          "メール送信に失敗しました。Eメールアドレスを確認してください。",
          "error"
        );
      }
    }
    setLoading(false);
  };

  return (
    <Flex
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        w={["95%", "70%", "45%"]}
        p={theme.space[4]}
        bg="white"
        borderRadius={theme.radii.md}
      >
        <VStack align="stretch">
          <Text fontSize="2xl" mb={6}>
            パスワードリセット
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl id="email" isRequired mb={4}>
              <FormLabel>Eメールアドレス</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              width="full"
              mb={4}
              backgroundColor="#DB793D"
              color="white"
              _hover={{ opacity: 0.7 }}
              borderBottom="none"
            >
              リセットメールを送信
            </Button>
          </form>
        </VStack>
      </Box>
      {loading && (
        <Flex
          position="fixed"
          top="0"
          right="0"
          bottom="0"
          left="0"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.3)"
          zIndex="9999"
        >
          <Spinner size="xl" color="white" />
        </Flex>
      )}
    </Flex>
  );
};

export default PasswordResetRequest;
