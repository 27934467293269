/** @format */

import { useRoutes, useLocation, Navigate } from "react-router-dom";
import SignUp from "./SignUp";
import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import CreateTalkScript from "./CreateTalkScript";
import TalkScriptWithProvider from "./TalkScriptWithProvider";
import PasswordResetRequest from "./PasswordResetRequest";
import ReportDetail from "./ReportDetail";
import ReportForm from "./ReportForm";
import SimulationWizard from "./SimulationWizard";
import Simulation from "./Simulation";
import Dashboard from "./Dashboard";
import ShareRedirect from "./ShareRedirect";
import UserApproval from "./UserApproval";
import EmailVerificationPrompt from "./EmailVerificationPrompt";
import WaitingApproval from "./WaitingApproval";
import DemonstrationDetail from "./DemonstrationDetail";
import BrickWall from "./BrickWall";
import NoPermission from "./NoPermission";
import { useAuth } from "./AuthProvider";

const Main: React.FC = () => {
  const DashboardOrEmailVerification: React.FC = () => {
    const { user } = useAuth();
    if (user && user.provider === "password" && !user.email_verified) {
      return <EmailVerificationPrompt />;
    }
    return <Dashboard />;
  };

  const routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "login", element: <Login /> },
    { path: "forgot-password", element: <PasswordResetRequest /> },
    {
      path: "signup/:companyuuid",
      element: <SignUp />,
    },
    {
      path: "waiting-approval",
      element: <WaitingApproval />,
    },
    {
      path: "share/:token",
      element: <ShareRedirect />,
    },
    {
      path: "approve-user",
      element: <UserApproval />,
    },
    {
      path: "dashboard",
      element: (
        <PrivateRoute>
          <DashboardOrEmailVerification />
        </PrivateRoute>
      ),
    },
    {
      path: "script/new",
      element: (
        <PrivateRoute>
          <CreateTalkScript />
        </PrivateRoute>
      ),
    },
    {
      path: "script/:id",
      element: (
        <PrivateRoute>
          <TalkScriptWithProvider />
        </PrivateRoute>
      ),
    },
    {
      path: "report/:id",
      element: (
        <PrivateRoute>
          <ReportDetail />
        </PrivateRoute>
      ),
    },
    {
      path: "report/new",
      element: (
        <PrivateRoute>
          <ReportForm />
        </PrivateRoute>
      ),
    },
    {
      path: "simulation/new",
      element: (
        <PrivateRoute>
          <SimulationWizard />
        </PrivateRoute>
      ),
    },
    {
      path: "simulation/:id",
      element: (
        <PrivateRoute>
          <Simulation />
        </PrivateRoute>
      ),
    },
    {
      path: "demonstration/:demoId",
      element: (
        <PrivateRoute>
          <DemonstrationDetail />
        </PrivateRoute>
      ),
    },
    {
      path: "brick-wall/:brickWallId",
      element: (
        <PrivateRoute>
          <BrickWall />
        </PrivateRoute>
      ),
    },
    { path: "*", element: <NoPermission /> },
  ]);

  return routes;
};

export default Main;
