import React from "react";
import { Alert, AlertIcon, Box } from "@chakra-ui/react";
import { useMessage } from "./MessageContext";

const MessageDisplay: React.FC = () => {
  const { message, status } = useMessage();

  return message ? (
    <Box mt={4}>
      <Alert status={status} variant="left-accent">
        <AlertIcon />
        {message}
      </Alert>
    </Box>
  ) : null;
};

export default MessageDisplay;
