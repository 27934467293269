import React from "react";
import { ReactFlowProvider } from "reactflow";
import TalkScript from "./TalkScript";

const TalkScriptWithProvider: React.FC = (props) => {
  return (
    <ReactFlowProvider>
      <TalkScript {...props} />
    </ReactFlowProvider>
  );
};

export default TalkScriptWithProvider;
