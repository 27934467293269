/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Heading,
  Select,
  Flex,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { useMessage } from "./MessageContext";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";
import apiClient from "./apiClient";

const Container = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  overflow-y: auto;
`;

const BackButton = styled(Button)`
  background-color: #4a5568;
  color: white;
  &:hover {
    background-color: #4a5568;
    opacity: 0.7;
  }
`;

const ResponsiveButton = styled(Button)`
  @media (max-width: 719px) {
    font-size: 48%;
  }
`;

const FileName = styled(InputRightAddon)`
  @media (max-width: 719px) {
    font-size: 48%;
  }
`;

interface TalkScript {
  id: number;
  name: string;
}

const ReportForm: React.FC = () => {
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    talk_script: "",
    improvements: "",
    remarks: "",
  });
  const [talkScripts, setTalkScripts] = useState<TalkScript[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [talkScriptId, setTalkScriptId] = useState("");
  const [improvements, setImprovements] = useState("");
  const [remarks, setRemarks] = useState("");
  const navigate = useNavigate();
  const { setMessage } = useMessage();
  const { user } = useAuth();
  const { setLoading } = useLoading();
  const [fileName, setFileName] = useState("");
  const [goalAchieved, setGoalAchieved] = useState("false");

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "talk_script") {
      setTalkScriptId(value);
    }
  };

  // ファイルが必須であることを含むバリデーション関数
  const validateForm = () => {
    if (!date || !title || !file) {
      setMessage("*は必須入力項目です", "error");
      return false;
    }
    // トークスクリプトの選択を確認
    if (!talkScriptId) {
      setMessage("使用するトークスクリプトを選択してください。", "error");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchTalkScripts = async () => {
      const { data } = await apiClient.get("/api/talk_scripts/");
      setTalkScripts(data);
      if (data && data.length > 0) {
        setFormData((prev) => ({
          ...prev,
          talk_script: data[0].id.toString(),
        }));
        setTalkScriptId(data[0].id.toString());
      }
    };
    fetchTalkScripts();
  }, []);

  const handleGoBack = () => {
    navigate("/dashboard?tab=report");
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileSizeLimit = 1000 * 1024 * 1024;
      const supportedFormats = ["audio/mpeg", "audio/mp3"];
      // ファイルサイズの確認
      if (file.size > fileSizeLimit) {
        setMessage(
          "ファイルサイズが大きすぎます（1GB以下にしてください）。",
          "error"
        );
        return;
      }
      if (!supportedFormats.includes(file.type)) {
        // サポートされていないファイル形式の場合
        setMessage(".mp3の音声データ形式でアップロードしてください。", "error");
        return;
      }
      // サポートされているファイル形式の場合、ファイルを設定
      setFile(file);
      setFileName(file.name);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleImprovementsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setImprovements(e.target.value);
  };

  const handleRemarksChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemarks(e.target.value);
  };

  const createReport = async () => {
    if (!validateForm()) return;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("date", date);
    formData.append("talk_script", talkScriptId);
    formData.append("remarks", remarks);
    formData.append("improvements", improvements);
    formData.append(
      "goal_achieved",
      goalAchieved === "true" ? "true" : "false"
    );
    if (file) {
      formData.append("audio", file);

      try {
        setLoading(true);
        const response = await apiClient.post(
          "/api/reviews/reports/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 201) {
          setMessage("商談記録の作成に成功しました。", "success");
          const newReportId = response.data.id;
          apiClient.post("/api/cohort_analysis_logs/create_log/", {
            company: user?.company?.id,
            event_type: "create_report",
            additional_info: {
              created_report_id: newReportId,
              talk_script_id: talkScriptId,
              goal_achieved: goalAchieved,
            },
          });
          navigate(`/report/${newReportId}`);
        } else if (
          response.status === 400 &&
          response.data.code === "upload_failed"
        ) {
          setMessage(
            "音声データが不正です。音声データをご確認ください。",
            "error"
          );
        }
      } catch (error) {
        console.error("Error uploading and converting the file:", error);
        setMessage("ファイルのアップロードと変換に失敗しました。", "error");
      } finally {
        setLoading(false);
      }
    } else {
      setMessage("アップロードするファイルが選択されていません。", "warning");
    }
  };

  const handleFileButtonClick = () => {
    // 隠されたファイルインプットを参照し、クリックイベントを発火させる
    const fileInput = document.getElementById("file-input");
    fileInput?.click();
  };

  return (
    <Container>
      <Box p={8}>
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <Button colorScheme="gray" size="md" onClick={handleGoBack}>
            戻る
          </Button>
        </Flex>
        <VStack spacing={6} align="stretch">
          <Heading size="lg" textAlign="center" mb={4}>
            商談記録
          </Heading>
          <FormControl w="50%" isRequired>
            <FormLabel>商談実施日</FormLabel>
            <Input
              name="date"
              type="date"
              value={date}
              onChange={handleDateChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>タイトル</FormLabel>
            <Input
              name="title"
              type="text"
              value={title}
              onChange={handleTitleChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>使用したトークスクリプト</FormLabel>
            <Select name="talk_script" onChange={handleChange}>
              {talkScripts.map((script, index) => (
                <option key={index} value={script.id}>
                  {script.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>
              トークスクリプトに改善が必要な点や上手くできなかった箇所
            </FormLabel>
            <Textarea
              name="improvements"
              placeholder="例：トークスクリプトには存在しないトークパターンとなりましたので改善を希望します"
              value={improvements}
              onChange={handleImprovementsChange}
            />
          </FormControl>
          <FormControl as="fieldset" isRequired>
            <FormLabel as="legend">商談の目的を達成できましたか？</FormLabel>
            <RadioGroup onChange={setGoalAchieved} value={goalAchieved}>
              <HStack spacing={4}>
                <Radio value="true">達成できました</Radio>
                <Radio value="false">達成できませんでした</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel>商談実施者のコメント</FormLabel>
            <Textarea
              name="remarks"
              placeholder="備考を入力"
              value={remarks}
              onChange={handleRemarksChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="file-input">
              商談音声ファイル（mp3形式）
            </FormLabel>
            <InputGroup size="lg">
              <Input
                id="file-input"
                name="file"
                type="file"
                onChange={handleFileChange}
                accept=".mp3"
                style={{ display: "none" }}
              />
              <ResponsiveButton
                leftIcon={<FiUpload />}
                colorScheme="gray"
                variant={file ? "outline" : "solid"}
                onClick={handleFileButtonClick}
                mr={2}
              >
                <span className="button-text">
                  {file ? "ファイルを変更" : "ファイルを選択"}
                </span>
              </ResponsiveButton>
              {file && <FileName children={fileName} borderRadius="md" />}
            </InputGroup>
          </FormControl>
        </VStack>
        <Flex justifyContent="flex-end" my={8}>
          <Box>
            <Button
              mt={4}
              p={6}
              size="lg"
              colorScheme="teal"
              onClick={createReport}
            >
              商談記録を保存
            </Button>
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default ReportForm;
