/** @format */

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import styled from "styled-components";
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  useToast,
} from "@chakra-ui/react";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";

const SuccessText = styled(Text)`
  text-align: center;
  font-weight: bold;
  color: teal;
  margin-bottom: 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
  font-size: larger;
`;

const UserApproval: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const companyUuid = queryParams.get("companyUuid");
  const usernameEncoded = queryParams.get("username");
  const username = usernameEncoded ? decodeURIComponent(usernameEncoded) : "";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { setLoading } = useLoading();
  const [modalContent, setModalContent] = useState<{
    title: string;
    description: string;
  }>();

  const approveUser = async () => {
    if (!userId || !companyUuid) {
      // userIdまたはcompanyUuidが空の場合
      toast({
        title: "エラー",
        description:
          "承認URLが不正です。メールに届いているユーザー承認リンクより、もう一度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      const response = await apiClient.post(`/api/companies/approve-user/`, {
        userId,
        companyUuid,
      });
      setModalContent({
        title: "チームへの参加承認に成功しました",
        description: "この画面は閉じてください。",
      });
      onOpen();
    } catch (error: unknown) {
      let errorMessage =
        "承認処理に失敗しました。時間を置いてから再度お試しください。";
      if (error instanceof Error && (error as AxiosError).response) {
        const axiosError = error as AxiosError;
        // axiosError.responseが存在することを確認
        if (axiosError.response) {
          // ここで型アサーションを使用して、errorの型をより具体的にします
          const responseData = axiosError.response.data as { error?: string };
          if (responseData.error) {
            if (
              responseData.error ===
              "チームに参加できるユーザー数を超えているため、チームに参加できません。"
            ) {
              errorMessage =
                "チームの最大ユーザー数を超えているため承認できません";
            } else {
              errorMessage = responseData.error;
            }
          }
        }
      }

      toast({
        title: "失敗",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex h="100%" align="center" justify="center">
      <Box textAlign="center" p="5" w="80%">
        <Text fontSize="xl" mb="4">
          <BoldText>{username}</BoldText>様のチームへの参加を承認
        </Text>
        <Text mb="8">
          下記のボタンをクリックしてユーザーの参加を承認してください。
          本ページに心当たりのないユーザーはこの画面を閉じてください。
        </Text>
        <Button colorScheme="blue" onClick={approveUser}>
          ユーザーを承認する
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent padding="6">
            <ModalHeader textAlign="center">{modalContent?.title}</ModalHeader>
            <ModalBody>
              <SuccessText>{modalContent?.description}</SuccessText>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default UserApproval;
