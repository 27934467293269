import React, { useEffect, useState } from "react";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
} from "@chakra-ui/react";
import styled from "styled-components";

const StyledTh = styled(Th)<{ width: string }>`
  width: ${(props) => props.width};
`;

const StyledTd = styled(Td)<{ width: string }>`
  width: ${(props) => props.width};
`;

const ClickableTr = styled(Tr)`
  cursor: pointer;
  &:hover {
    background-color: #e8f6fa;
  }
`;

const SelectedTr = styled(ClickableTr)`
  background-color: #f6ebfa;
`;

interface TalkScript {
  id: string;
  name: string;
  created_at: string;
}

interface TalkScriptSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  currentScriptId: number;
  currentNodeId: number;
  fetchTalk: () => void;
  onLinkSetSuccess: () => void;
}

const TalkScriptSelectModal: React.FC<TalkScriptSelectModalProps> = ({
  isOpen,
  onClose,
  currentScriptId,
  currentNodeId,
  fetchTalk,
  onLinkSetSuccess,
}) => {
  const [talkScripts, setTalkScripts] = useState<TalkScript[]>([]);
  const [linkedScriptId, setLinkedScriptId] = useState<number | null>(null);
  const [search, setSearch] = useState("");
  const { loading, setLoading } = useLoading();
  const { user } = useAuth();

  const fetchLinkedScriptId = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`/api/talks/${currentNodeId}/`);
      const linkUrl = data.link_url;
      if (!linkUrl) {
        setLinkedScriptId(null);
        return;
      }
      const linkedId = parseInt(linkUrl.split("/").pop() || "0", 10);
      setLinkedScriptId(linkedId);
    } catch (error) {
      console.error("Error fetching linked script ID:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTalkScripts = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/api/talk_scripts/");
      const filteredData = data.filter((script: any) => {
        return script.id !== currentScriptId && script.users.includes(user?.id);
      });
      setTalkScripts(filteredData);
    } catch (error) {
      console.error("Error fetching talk scripts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchTalkScripts();
      fetchLinkedScriptId();
    }
  }, [isOpen, currentNodeId, currentScriptId]);

  useEffect(() => {
    if (!isOpen) {
      setLinkedScriptId(null);
    }
  }, [isOpen]);

  const handleSelect = async (scriptId: string) => {
    try {
      // 選択されたTalkScriptのIDを元にAPIを呼び出してlink_urlを更新
      const response = await apiClient.post(
        "api/talk_scripts/generate-share-link/",
        {
          talk_script_id: scriptId,
        }
      );

      const shareLink = response.data.share_link;

      await apiClient.patch(`/api/talks/${currentNodeId}/`, {
        link_url: shareLink,
      });
      onLinkSetSuccess();
      fetchTalk();
      onClose();
    } catch (error) {
      console.error("Error updating link_url:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>リンクするトークスクリプトを選択</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {talkScripts.length === 0 && !loading ? (
            <Center height="200px">
              {" "}
              {/* メッセージを中央に配置 */}
              リンクできるトークスクリプトが存在しません
            </Center>
          ) : (
            <>
              <Input
                placeholder="スクリプト検索"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Table variant="unstyled" mt={4}>
                <Thead>
                  <Tr>
                    <StyledTh width="60%">トークスクリプト</StyledTh>
                    <StyledTh width="30%">作成日時</StyledTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {talkScripts
                    .filter((talkScript) =>
                      talkScript.name
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((talkScript) => {
                      const date = new Date(talkScript.created_at);
                      const formattedDate = date.toLocaleString("ja-JP", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      const isLinkedScript =
                        Number(talkScript.id) === linkedScriptId;
                      return isLinkedScript ? (
                        <SelectedTr key={talkScript.id}>
                          <StyledTd width="60%">{talkScript.name}</StyledTd>
                          <StyledTd width="30%">{formattedDate}</StyledTd>
                        </SelectedTr>
                      ) : (
                        <ClickableTr
                          key={talkScript.id}
                          onClick={() => handleSelect(talkScript.id)}
                        >
                          <StyledTd width="60%">{talkScript.name}</StyledTd>
                          <StyledTd width="30%">{formattedDate}</StyledTd>
                        </ClickableTr>
                      );
                    })}
                </Tbody>
              </Table>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TalkScriptSelectModal;
