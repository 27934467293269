/** @format */

import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Textarea,
  Text,
  Button,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  StepTitle,
  useSteps,
  Heading,
  Select,
} from "@chakra-ui/react";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useMessage } from "./MessageContext";

const steps = [
  {
    title: "あなたの業種や職種・役割",
  },
  {
    title: "対談相手の業種",
  },
  {
    title: "対談相手が所属する企業の従業員数",
  },
  {
    title: "対談相手の職種",
  },
  {
    title: "この対談で達成したいこと",
  },
];

const SimulationWizard: React.FC = () => {
  const [industry, setIndustry] = useState("");
  const [employeeCount, setEmployeeCount] = useState("1〜9人");
  const [wanttobe, setWanttobe] = useState("");
  const [yourRole, setYourRole] = useState("");
  const [partnerRole, setPartnerRole] = useState("");
  const { goToNext, goToPrevious, activeStep } = useSteps({
    count: steps.length,
  });
  const { setMessage } = useMessage();
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === 0 && !yourRole) {
      setMessage("あなたの業種や職種は必須入力項目です", "error");
      return;
    }
    if (activeStep === 1 && !industry) {
      setMessage("対談相手の業種は必須入力項目です", "error");
      return;
    }
    if (activeStep === 2 && !employeeCount) {
      setMessage("対談相手が所属する会社の従業員数は必須入力項目です", "error");
      return;
    }
    if (activeStep === 3 && !partnerRole) {
      setMessage("対談相手の職種は必須入力項目です", "error");
      return;
    }
    if (activeStep === 4 && !wanttobe) {
      setMessage("この対談で達成したいことは必須入力項目です", "error");
      return;
    }
    if (activeStep === 4) {
      apiClient
        .post("/api/simulation_sessions/create/", {
          yourRole,
          industry,
          employee_count: employeeCount,
          partnerRole,
          want_to_be: wanttobe,
        })
        .then((response) => {
          const createdId = response.data.id;
          navigate(`/simulation/${createdId}`);
        })
        .catch((error) => {
          setMessage(
            "新規作成に失敗しました。もう一度時間を置いてお試しください。",
            "error"
          );
        });
    } else {
      goToNext();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%"
      width="60%"
      mx="auto"
    >
      <Heading marginTop={14} marginBottom={14}>
        シミュレーション相手とシーンを入力
      </Heading>
      <Stepper
        index={activeStep}
        orientation="vertical"
        height="400px"
        gap="0"
        marginBottom={16}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            {activeStep === index && (
              <Box
                flexShrink="0"
                marginTop={4}
                marginBottom={4}
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {" "}
                {/* gapを調整 */}
                <StepTitle>{step.title}</StepTitle>
                {index === 0 && (
                  <Textarea
                    mt={2}
                    size="lg"
                    placeholder={"例：人材紹介サービスの営業"}
                    value={yourRole}
                    onChange={(e) => setYourRole(e.target.value)}
                  />
                )}
                {index === 1 && (
                  <Textarea
                    mt={2}
                    size="lg"
                    placeholder={"例：医療用の医薬品製造業"}
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                  />
                )}
                {index === 2 && (
                  <Select
                    mt={2}
                    width="100%"
                    size="lg"
                    value={employeeCount}
                    onChange={(e) => setEmployeeCount(e.target.value)}
                  >
                    <option value="1〜9人">1〜9人</option>
                    <option value="10〜29人">10〜29人</option>
                    <option value="30〜49人">30〜49人</option>
                    <option value="50〜99人">50〜99人</option>
                    <option value="100〜299人">100〜299人</option>
                    <option value="300人以上">300人以上</option>
                  </Select>
                )}
                {index === 3 && (
                  <Textarea
                    mt={2}
                    width="100%"
                    size="lg"
                    placeholder={"例：営業本部長"}
                    value={partnerRole}
                    onChange={(e) => setPartnerRole(e.target.value)}
                  />
                )}
                {index === 4 && (
                  <Textarea
                    mt={2}
                    width="100%"
                    size="lg"
                    placeholder={"例：商談のアポをとりたい"}
                    value={wanttobe}
                    onChange={(e) => setWanttobe(e.target.value)}
                  />
                )}
              </Box>
            )}
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Button
          onClick={goToPrevious}
          isDisabled={activeStep === 0}
          colorScheme="teal"
        >
          前へ
        </Button>
        <Button onClick={handleNext} colorScheme="teal">
          {activeStep === 2 ? "完了" : "次へ"}
        </Button>
      </Box>
    </Box>
  );
};

export default SimulationWizard;
