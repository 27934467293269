/** @format */

import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";

const NoPermission: React.FC = () => {
  return (
    <VStack
      spacing={4}
      justify="center"
      align="center"
      height="100%"
      width="80%"
      margin="auto"
    >
      <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
        <Text fontSize="xl" color="teal.600">
          不正なアクセス
        </Text>
        <Text mt={4}>このページにアクセスする権限がありません。</Text>
      </Box>
    </VStack>
  );
};

export default NoPermission;
