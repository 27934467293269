import React, { createContext, useState, useContext, ReactNode } from "react";

interface ViewModeContextType {
  viewMode: boolean;
  setViewMode: (viewMode: boolean) => void;
  isViewer: boolean;
  setIsViewer: (isViewer: boolean) => void;
}

const ViewModeContext = createContext<ViewModeContextType>({
  viewMode: false,
  setViewMode: () => {
    throw new Error("setViewMode function must be overridden");
  },
  isViewer: false,
  setIsViewer: () => {
    throw new Error("setIsViewer function must be overridden");
  },
});

interface ViewModeProviderProps {
  children: ReactNode;
}

export const ViewModeProvider: React.FC<ViewModeProviderProps> = ({
  children,
}) => {
  const [viewMode, setViewMode] = useState(false);
  const [isViewer, setIsViewer] = useState(false);

  return (
    <ViewModeContext.Provider
      value={{ viewMode, setViewMode, isViewer, setIsViewer }}
    >
      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewMode = () => useContext(ViewModeContext);
