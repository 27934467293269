import React from "react";
import { BrowserRouter } from "react-router-dom";

import Header from "./components/Header";
import Main from "./components/Main";
import { MessageProvider } from "./components/MessageContext";
import MessageDisplay from "./components/MessageDisplay";
import { AuthProvider } from "./components/AuthProvider";
import { ViewModeProvider } from "./components/ViewModeContext";
import { LoadingProvider } from "./components/LoadingContext";

const App: React.FC = () => {
  return (
    <MessageProvider>
      <ViewModeProvider>
        <LoadingProvider>
          <AuthProvider>
              <BrowserRouter>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                  }}
                >
                  <Header />
                  <MessageDisplay />
                  <Main />
                </div>
              </BrowserRouter>
          </AuthProvider>
        </LoadingProvider>
      </ViewModeProvider>
    </MessageProvider>
  );
};

export default App;
