/** @format */

import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  Icon,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FaGoogle } from "react-icons/fa";
import { auth } from "./firebaseConfig";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useMessage } from "./MessageContext";
import { useLoading } from "./LoadingContext";

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FormBox = styled(Box)`
  width: 100%;
  max-width: 420px;
  padding: 2rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
`;

interface ErrorResponse {
  error: string;
}

const SignUp: React.FC = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { setMessage } = useMessage();
  const { isAuthenticated } = useAuth();
  const { setLoading } = useLoading();
  const { companyuuid } = useParams();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    try {
      // Firebaseでユーザー登録
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;
      // FirebaseユーザーのdisplayNameを更新
      await updateProfile(firebaseUser, {
        displayName: username,
      });

      // Eメール確認メールを送信
      if (firebaseUser.providerData[0].providerId === "password") {
        await sendEmailVerification(firebaseUser);
        setMessage(
          "確認メールを送信しました。メール内のメールアドレス有効性証明を完了させてください。",
          "success"
        );
      }

      await apiClient.post("/api/users/", {
        username: firebaseUser.displayName,
        email: firebaseUser.email,
        firebase_uid: firebaseUser.uid,
        company_uuid: companyuuid,
      });

      navigate("/waiting-approval");
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        if (
          axiosError.response.status === 400 &&
          axiosError.response.data.error
        ) {
          // 特定のエラーメッセージをユーザーに表示
          setMessage(axiosError.response.data.error, "error");
        }
      } else {
        setMessage("登録に失敗しました。もう一度お試しください。", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  // Googleサインアップボタンのクリックイベントハンドラ
  const handleGoogleSignUp = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();

    try {
      // Googleでのサインインを試行
      const result = await signInWithPopup(auth, provider);
      const firebaseUser = result.user;

      // DjangoバックエンドにFirebaseユーザーを登録
      await apiClient.post("/api/users/", {
        username: firebaseUser.displayName,
        email: firebaseUser.email,
        firebase_uid: firebaseUser.uid,
        company_uuid: companyuuid,
      });

      // サインアップ成功時の処理
      setMessage("ユーザー登録に成功しました", "success");
      navigate("/waiting-approval");
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        if (
          axiosError.response.status === 400 &&
          axiosError.response.data.error
        ) {
          // 特定のエラーメッセージをユーザーに表示
          setMessage(axiosError.response.data.error, "error");
        }
      } else {
        setMessage("登録に失敗しました。もう一度お試しください。", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormBox as="form" onSubmit={handleSubmit}>
        <Text fontSize="2xl" mb={6} textAlign="center">
          サインアップ
        </Text>
        <FormControl id="username" isRequired mb={4}>
          <FormLabel>ユーザーネーム</FormLabel>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl id="email" isRequired mb={4}>
          <FormLabel>Eメールアドレス</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="password" isRequired mb={6}>
          <FormLabel>パスワード</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          width="full"
          mb={4}
          backgroundColor="#DB793D"
          color="white"
          _hover={{ opacity: 0.7 }}
        >
          サインアップ
        </Button>
        <Button
          leftIcon={<Icon as={FaGoogle} boxSize={6} />}
          type="button"
          backgroundColor="#4285F4"
          color="white"
          mb={4}
          _hover={{ opacity: 0.7 }}
          width="full"
          onClick={handleGoogleSignUp}
        >
          Googleでサインアップ 🚀
        </Button>
        <Text fontSize="sm" textAlign="center">
          <Link
            color="teal.500"
            href="https://site.talkscript.app/terms-of-service"
            isExternal
          >
            利用規約
          </Link>{" "}
          と{" "}
          <Link
            color="teal.500"
            href="https://site.talkscript.app/privacy-policy"
            isExternal
          >
            プライバシーポリシー
          </Link>
          に同意してサインアップします。
        </Text>
        <Text fontSize="sm" textAlign="center" mt={4}>
          すでにアカウントをお持ちですか？{" "}
          <Link color="teal.500" href="/login">
            ログイン
          </Link>
        </Text>
      </FormBox>
    </Container>
  );
};

export default SignUp;
