/** @format */

import React, { useEffect, useState } from "react";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  useToast,
} from "@chakra-ui/react";
import styled from "styled-components";

const StyledTh = styled(Th)<{ width: string }>`
  width: ${(props) => props.width};
`;

const StyledTd = styled(Td)<{ width: string }>`
  width: ${(props) => props.width};
`;

const ClickableTr = styled(Tr)`
  cursor: pointer;
  &:hover {
    background-color: #e8f6fa;
  }
`;

const SelectedTr = styled(ClickableTr)`
  background-color: #f6ebfa;
`;

interface Demonstration {
  id: number;
  title: string;
  use_case: string;
  created_at: string;
}

interface DemonstratioSelectModalProps {
  isDemoSelectOpen: boolean;
  onDemoSelectClose: () => void;
  onCancel: () => void;
  currentNodeId: number;
  fetchTalk: () => void;
}

const DemonstrationSelectModal: React.FC<DemonstratioSelectModalProps> = ({
  isDemoSelectOpen,
  onDemoSelectClose,
  currentNodeId,
  fetchTalk,
}) => {
  const [demonstrations, setDemonstrations] = useState<Demonstration[]>([]);
  const [demonstrationId, setDemonstrationId] = useState<number | null>(null);
  const [search, setSearch] = useState("");
  const { loading, setLoading } = useLoading();
  const { user } = useAuth();
  const toast = useToast();

  const fetchDemonstration = async () => {
    try {
      setLoading(true);
      const demoresponse = await apiClient.get(
        `/api/demonstrations/companies/${user?.company?.id}/`
      );
      setDemonstrations(demoresponse.data);
    } catch (error) {
      console.error("Error fetching linked script ID:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLinkedDemonstration = async () => {
    if (currentNodeId) {
      try {
        setLoading(true);
        const response = await apiClient.get(`/api/talks/${currentNodeId}/`);
        const linkedDemoId = response.data.demo;
        if (linkedDemoId) {
          setDemonstrationId(linkedDemoId);
        }
      } catch (error) {
        console.error("Error fetching linked demonstration:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isDemoSelectOpen) {
      fetchLinkedDemonstration();
      fetchDemonstration();
    }
  }, [isDemoSelectOpen]);

  useEffect(() => {
    if (!isDemoSelectOpen) {
      setDemonstrationId(null);
    }
  }, [isDemoSelectOpen]);

  const handleSelect = async (demo: Demonstration) => {
    try {
      setLoading(true);
      await apiClient.patch(`/api/talks/${currentNodeId}/`, {
        demo_id: demo.id,
        content_wysiwyg: `<h1>デモ｜${demo.title}</h1><p>${demo.use_case}</p>`,
      });
      toast({
        title: demonstrationId ? "更新成功" : "紐付け成功",
        description: demonstrationId
          ? "デモの更新が完了しました。"
          : "新しいデモとの紐付けが完了しました。",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      fetchTalk();
      onDemoSelectClose();
    } catch (error) {
      console.error("Error updating link_url:", error);
      toast({
        title: "失敗",
        description: "トークとデモの紐付けに失敗しました。。",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isDemoSelectOpen} onClose={onDemoSelectClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>デモを選択</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {demonstrations.length === 0 && !loading ? (
            <Center height="200px">
              デモが存在しません。
              ダッシュボードに戻り、サイドバーの「デモ」→「デモ登録」より登録できます。
            </Center>
          ) : (
            <>
              <Input
                placeholder="デモを検索"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Table variant="unstyled" mt={4}>
                <Thead>
                  <Tr>
                    <StyledTh width="60%">タイトル</StyledTh>
                    <StyledTh width="30%">作成日時</StyledTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {demonstrations
                    .filter((demo) =>
                      demo.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((demo) => {
                      const date = new Date(demo.created_at);
                      const formattedDate = date.toLocaleString("ja-JP", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      return demonstrationId === demo.id ? (
                        <SelectedTr key={demo.id}>
                          <StyledTd width="60%">{demo.title}</StyledTd>
                          <StyledTd width="30%">{formattedDate}</StyledTd>
                        </SelectedTr>
                      ) : (
                        <ClickableTr
                          key={demo.id}
                          onClick={() => handleSelect(demo)}
                        >
                          <StyledTd width="60%">{demo.title}</StyledTd>
                          <StyledTd width="30%">{formattedDate}</StyledTd>
                        </ClickableTr>
                      );
                    })}
                </Tbody>
              </Table>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DemonstrationSelectModal;
