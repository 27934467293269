const getCookieValue = (cookieName: string): string | null => {
  const cookieString = document.cookie;
  const cookieList = cookieString.split("; ");
  const cookieObj: Record<string, string> = {};

  for (let cookie of cookieList) {
    const [key, value] = cookie.split("=");
    cookieObj[key] = value;
  }

  return cookieObj[cookieName] || null;
};

export default getCookieValue;
