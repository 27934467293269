/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  Table,
  Tbody,
  Tr,
  Td,
  Link,
  Flex,
  Input,
  FormControl,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";
import {
  signOut,
  verifyBeforeUpdateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { auth } from "./firebaseConfig";
import styled from "styled-components";

const CustomIconButton = styled(IconButton)`
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

interface Profile {
  userId: number | null;
  username: string;
  email: string;
  plan: string;
  provider: string;
}

interface AccountProps {
  modalOpen: boolean;
  closeAccountModal: () => void;
}

const Account: React.FC<AccountProps> = ({ modalOpen, closeAccountModal }) => {
  const { isAuthenticated, setIsAuthenticated, checkToken, user } = useAuth();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<Profile>({
    userId: null,
    username: "",
    email: "",
    plan: "",
    provider: "",
  });
  const [company, setCompany] = useState({
    id: null,
    name: "",
    address: "",
    phoneNumber: "",
    website: "",
    users: [],
    admin: null,
    max_token: 0,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [username, setUsername] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const { setLoading } = useLoading();
  const {
    isOpen: isOpenEmailModal,
    onOpen: onOpenEmailModal,
    onClose: onCloseEmailModal,
  } = useDisclosure();
  const toast = useToast();

  //必要情報のフェッチ
  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        await checkToken();
        if (user) {
          setProfile({
            userId: user.id,
            username: user.username,
            email: user.email,
            plan: user.plan,
            provider: user.provider,
          });
        }
      } catch (error) {
        console.error("プロフィール取得に失敗しました:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    if (profile.plan === "team") {
      const fetchCompany = async () => {
        try {
          const companyResponse = await apiClient.get(
            "/api/companies/user_company/"
          );
          setCompany({
            id: companyResponse.data[0].id,
            name: companyResponse.data[0].name,
            address: companyResponse.data[0].address,
            phoneNumber: companyResponse.data[0].phone_number,
            website: companyResponse.data[0].website,
            users: companyResponse.data[0].users,
            admin: companyResponse.data[0].admin,
            max_token: companyResponse.data[0].max_token,
          });
        } catch (error) {
          console.error("会社情報の取得に失敗しました:", error);
        }
      };

      fetchCompany();
    }
  }, [profile.plan]);

  //テキスト編集状態モード
  const handleEditClick = () => {
    setUsername(profile.username);
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      // Djangoでのusernameの更新
      await apiClient.patch(`/api/users/${profile.userId}/`, {
        username: username,
      });
      // プロフィールの表示内容を更新
      setProfile((prevProfile) => ({
        ...prevProfile,
        username: username,
      }));
      await checkToken();
      toast({
        title: "更新成功",
        description: "ユーザー名が更新されました",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.error("ユーザー名の更新に失敗しました:", error);
      toast({
        title: "更新失敗",
        description: "ユーザー名が更新に失敗しました。再度お試しください。",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  //再認証処理
  const reauthenticate = async (currentPassword: string) => {
    const firebaseUser = auth.currentUser;
    if (firebaseUser && firebaseUser.email) {
      const cred = EmailAuthProvider.credential(
        firebaseUser.email,
        currentPassword
      );
      return reauthenticateWithCredential(firebaseUser, cred);
    }
  };

  //firebase_authのパスワード変更処理
  const handleChangePassword = async () => {
    if (profile.provider !== "password") {
      toast({
        title: "エラー",
        description:
          "ソーシャルログインで作成されたアカウントはパスワードを変更できません",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setLoading(true);
    try {
      const firebaseUser = auth.currentUser;
      if (firebaseUser) {
        await updatePassword(firebaseUser, newPassword);
      }
      toast({
        title: "更新成功",
        description: "パスワードが変更されました",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
    } catch (error) {
      console.error("パスワードの変更に失敗しました:", error);
      toast({
        title: "エラー",
        description: "既存のパスワードが正しくありません",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  //firebase_authのEメールアドレス変更処理
  const handleUpdateEmail = async () => {
    setLoading(true);
    try {
      const firebaseUser = auth.currentUser;
      if (firebaseUser) {
        await reauthenticate(currentPassword);
        await verifyBeforeUpdateEmail(firebaseUser, newEmail);
        toast({
          title: "メールボックスをご確認ください",
          description:
            "変更処理を変更後のメールアドレスに送信しました。変更を確定するにはメールをご確認いただき、リンクをクリックしてください。",
          status: "info",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        onCloseEmailModal();
      }
    } catch (error) {
      console.error("Eメールアドレスの更新に失敗しました:", error);
      toast({
        title: "エラー",
        description: "Eメールアドレスの更新に失敗しました。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  //firebase_authのログアウト処理
  const handleLogout = async () => {
    try {
      setLoading(true);
      await signOut(auth);
      setIsAuthenticated(false);
      closeAccountModal();
      navigate("/login");
      toast({
        title: "ログアウト成功",
        description: "ログアウトしました",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: "ログアウト失敗",
        description: "ログアウトに失敗しました。再度お試しください。",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      console.error("ログアウトに失敗しました:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={modalOpen} onClose={closeAccountModal}>
      <ModalOverlay />
      <ModalContent sx={{ width: "100%", maxWidth: "500px" }}>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            direction="column"
            align="center"
            justify="space-between"
            padding={5}
          >
            <Box display="flex" flexDirection="column">
              <Menu>
                <Box position="absolute" top="14" right="24">
                  <MenuButton
                    as={CustomIconButton}
                    icon={<MdSettings size={24} color="#607D8B" />}
                  ></MenuButton>
                </Box>
                <MenuList>
                  <MenuItem onClick={handleEditClick}>ユーザー名変更</MenuItem>
                  {user && user.provider === "password" && (
                    <MenuItem onClick={onOpenEmailModal}>
                      Eメールアドレス変更
                    </MenuItem>
                  )}
                  {user && user.provider === "password" && (
                    <MenuItem onClick={onOpen}>パスワード変更</MenuItem>
                  )}
                  <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
                  {company.admin === user?.id && (
                    <MenuItem
                      color="red.500"
                      onClick={() =>
                        window.open(
                          "https://site.talkscript.app/inquiry",
                          "_blank"
                        )
                      }
                    >
                      解約手続き
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
              <Heading as="h2" size="lg" m={8} textAlign="center">
                アカウント情報
              </Heading>
              <Table variant="simple">
                <Tbody>
                  {profile.plan === "team" && (
                    <Tr>
                      <Td>会社名</Td>
                      <Td>{company.name}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>ユーザーネーム</Td>
                    <Td>
                      {isEditing ? (
                        <FormControl>
                          <Input
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </FormControl>
                      ) : (
                        profile.username
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Eメールアドレス</Td>
                    <Td>{profile.email}</Td>
                  </Tr>
                  <Tr>
                    <Td>プラン</Td>
                    <Td>
                      {profile.plan === "team" ? "チーム" : "プラン未設定"}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <Box alignSelf="flex-end" p={3} display="flex" gap={2}>
                {isEditing && (
                  <>
                    <Button
                      onClick={handleCancelClick}
                      backgroundColor="#A0AEC0"
                      color="#FFFFFF"
                      _hover={{ opacity: 0.7 }}
                      size="md"
                    >
                      キャンセル
                    </Button>
                    <Button
                      onClick={handleSaveClick}
                      backgroundColor="#3182CE"
                      color="#FFFFFF"
                      _hover={{ opacity: 0.7 }}
                      size="md"
                    >
                      保存
                    </Button>
                  </>
                )}
              </Box>
              <Box mt={8} textAlign="center">
                <Text fontSize="sm">
                  <Link
                    href="https://site.talkscript.app/terms-of-service"
                    color="teal.500"
                    textDecoration="underline"
                    isExternal
                  >
                    利用規約
                  </Link>
                  {" | "}
                  <Link
                    href="https://site.talkscript.app/privacy-policy"
                    color="teal.500"
                    textDecoration="underline"
                    isExternal
                  >
                    プライバシーポリシー
                  </Link>
                  {" | "}
                  <Link
                    href="https://mediowl.co.jp/company/"
                    color="teal.500"
                    textDecoration="underline"
                    isExternal
                  >
                    運営会社
                  </Link>
                </Text>
              </Box>
              <Text mt={2} textAlign="center">
                &copy; {new Date().getFullYear()} talkscript. All rights
                reserved.
              </Text>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>パスワード変更</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl mt={4}>
                    <FormLabel>新しいパスワード</FormLabel>
                    <Input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={handleChangePassword}
                  >
                    変更
                  </Button>
                  <Button variant="ghost" onClick={onClose}>
                    キャンセル
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Modal isOpen={isOpenEmailModal} onClose={onCloseEmailModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Eメールアドレスの更新</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl mt={4}>
                    <FormLabel mt={4}>変更後Eメールアドレス</FormLabel>
                    <Input
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <FormLabel>現在のパスワードを入力</FormLabel>
                    <Input
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={handleUpdateEmail}>
                    変更処理をメール送信
                  </Button>
                  <Button variant="ghost" onClick={onCloseEmailModal}>
                    キャンセル
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Account;
