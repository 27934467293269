/** @format */

import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Flex,
  Box,
  Button,
  Input,
  Text,
} from "@chakra-ui/react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { format as formatDate } from "date-fns";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";

const StyledTr = styled(Tr)`
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 90%;
  margin: auto;
`;

interface User {
  id: number;
  username: string;
  email: string;
}

interface TalkScript {
  id: number;
  name: string;
}

interface Report {
  id: number;
  title: string;
  date: string;
  user: User;
  talk_script: TalkScript;
  updated_at: Date;
}

const ReportList: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { user } = useAuth();

  useEffect(() => {
    setLoading(true);
    apiClient
      .get("/api/reviews/reports/")
      .then((response) => {
        const updatedReports = response.data.map((report: any) => ({
          ...report,
          updated_at: new Date(report.updated_at),
        }));
        setReports(updatedReports);
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleRowClick = (id: number) => {
    navigate(`/report/${id}`);
  };

  const handleAddReport = () => {
    navigate("/report/new");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredReports = reports.filter(
    (report) =>
      report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      report.talk_script.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      report.user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Box mt={10}>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading as="h2" size="md" fontWeight="bold">
            商談記録一覧
          </Heading>
          <Button
            onClick={handleAddReport}
            backgroundColor="#59a3d4"
            color="white"
            _hover={{ opacity: 0.7 }}
          >
            新規作成
          </Button>
        </Flex>
        <Box mb={4}>
          <Input
            placeholder="タイトル,トークスクリプト,実施者で絞り込み"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Box>
        <Table variant="simple" mt={8}>
          <Thead>
            <Tr>
              <Th width="8%">実施日</Th>
              <Th width="37%">タイトル</Th>
              <Th width="37%">トークスクリプト</Th>
              <Th width="18%">実施者</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredReports
              .sort((a, b) => b.updated_at.getTime() - a.updated_at.getTime())
              .map((report) => (
                <StyledTr
                  key={report.id}
                  onClick={() => handleRowClick(report.id)}
                >
                  <Td>{formatDate(new Date(report.date), "yyyy/MM/dd")}</Td>
                  <Td>{report.title}</Td>
                  <Td>{report.talk_script.name}</Td>
                  <Td>{report.user.username}</Td>
                </StyledTr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default ReportList;
