/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Grid,
  GridItem,
  Select,
  Badge,
  Heading,
} from "@chakra-ui/react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  addMonths,
  subMonths,
  eachDayOfInterval,
  format,
} from "date-fns";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";

interface SerializedChartDataItem {
  date: string;
  personalCount: number;
  averageCount: number;
}

interface ProcessedLogs {
  [date: string]: {
    [userId: number]: {
      username: string;
      event_type: {
        [eventType: string]: number;
      };
    };
  };
}

interface AverageActivityData {
  [date: string]: {
    event_type: {
      [eventType: string]: number;
    };
  };
}

interface Scope {
  type: "week" | "month";
  startDate: Date;
  endDate: Date;
}

interface CompanyUser {
  user: number;
  username: string;
}

// 初期スコープを設定するヘルパー関数
const getInitialScope = (): Scope => {
  const now = new Date();
  return {
    type: "week",
    startDate: startOfWeek(now, { weekStartsOn: 1 }),
    endDate: endOfWeek(now, { weekStartsOn: 1 }),
  };
};

const WorkoutLog = ({ isActive }: { isActive: boolean }) => {
  const [currentScope, setCurrentScope] = useState<Scope>(getInitialScope());
  const [serializedRoleplayData, setSerializedRoleplayData] = useState<
    SerializedChartDataItem[]
  >([]);
  const [serializedRecordingData, setSerializedRecordingData] = useState<
    SerializedChartDataItem[]
  >([]);
  const [serializedCreateReportData, setSerializedCreateReportData] = useState<
    SerializedChartDataItem[]
  >([]);
  const [serializedDialogueData, setSerializedDialogueData] = useState<
    SerializedChartDataItem[]
  >([]);
  const [serializedHearingData, setSerializedHearingData] = useState<
    SerializedChartDataItem[]
  >([]);
  const [serializedBrickWallData, setSerializedBrickWallData] = useState<
    SerializedChartDataItem[]
  >([]);
  const [yAxisMax, setYAxisMax] = useState(0);
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const { setLoading } = useLoading();
  const { user } = useAuth();

  //currentScope変更時のフック
  useEffect(() => {
    if (selectedUserId !== null && isActive) {
      fetchCompanyLogs(selectedUserId);
    }
  }, [selectedUserId, currentScope, isActive]);

  useEffect(() => {
    const fetchCompanyUsers = async () => {
      try {
        setLoading(true);
        if (!user || !user.company)
          throw new Error("ユーザー情報または会社情報が見つかりません。");
        const companyResponse = await apiClient.get(
          "/api/companies/user_company/"
        );
        if (companyResponse.data.length > 0) {
          let usersList = companyResponse.data[0].users.map((user: any) => ({
            user: user.user,
            username: user.username,
            class_type: user.class_type,
          }));

          // ユーザーがleaderの場合は全員を、memberの場合は自分自身のみをリストに含める
          if (user.company_user?.class_type === "member") {
            usersList = usersList.filter((u: any) => u.user === user.id);
          }

          // ログインユーザー自身をリストの先頭に配置
          const userIndex = usersList.findIndex((u: any) => u.user === user.id);
          if (userIndex > -1) {
            const currentUser = usersList.splice(userIndex, 1)[0];
            usersList.unshift(currentUser);
          }

          setCompanyUsers(usersList);
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyUsers();
  }, []);

  //companyのlogデータをフェッチ
  const fetchCompanyLogs = async (companyUserId: number) => {
    if (!isActive || !user) return;

    try {
      setLoading(true);

      const response = await apiClient.get(
        "/api/cohort_analysis_logs/company_activity/",
        {
          params: { company_id: user?.company?.id },
        }
      );

      const fetchedLogs = response.data;

      const avgResponse = await apiClient.get(
        "/api/cohort_analysis_logs/company_average_activity/",
        {
          params: { company_id: user?.company?.id },
        }
      );
      const avgFetchedLogs = avgResponse.data;

      serializeAndSetData(fetchedLogs, avgFetchedLogs, companyUserId);
    } catch (error) {
      console.error("Error fetching company activity logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const serializeAndSetData = (
    personalData: ProcessedLogs,
    averageData: AverageActivityData,
    userId: number
  ) => {
    setLoading(true);
    const eventTypes = [
      "roleplay",
      "recording",
      "create_report",
      "create_feedback",
      "dialogue",
      "hearing",
      "brick_wall",
    ];
    eventTypes.forEach((eventType) => {
      const serializedData = serializeDataForChart(
        personalData,
        averageData,
        userId,
        eventType,
        currentScope
      );

      // シリアライズされたデータを適切なステートにセット
      switch (eventType) {
        case "roleplay":
          setSerializedRoleplayData(serializedData);
          break;
        case "recording":
          setSerializedRecordingData(serializedData);
          break;
        case "create_report":
          setSerializedCreateReportData(serializedData);
          break;
        case "dialogue":
          setSerializedDialogueData(serializedData);
          break;
        case "hearing":
          setSerializedHearingData(serializedData);
          break;
        case "brick_wall":
          setSerializedBrickWallData(serializedData);
          break;
        default:
          // ここには来ないはず
          break;
      }
    });
    // 最大値を計算
    const maxCount = calculateMaxCount(personalData, averageData);
    setYAxisMax(maxCount);

    setLoading(false);
  };

  const serializeDataForChart = (
    personalData: ProcessedLogs,
    averageData: AverageActivityData,
    userId: number,
    eventType: string,
    currentScope: Scope
  ) => {
    const dateRange = eachDayOfInterval({
      start: currentScope.startDate,
      end: currentScope.endDate,
    });

    return dateRange.map((date) => {
      const dateString = format(date, "yyyy-MM-dd");
      const personalCount =
        personalData[dateString]?.[userId]?.event_type[eventType] || 0;
      const averageCount = averageData[dateString]?.event_type[eventType] || 0;

      return {
        date: format(date, "MM/dd"),
        personalCount,
        averageCount,
      };
    });
  };

  // currentScopeの範囲を変更する関数
  const changeScope = (direction: "next" | "previous") => {
    setCurrentScope((prevScope) => {
      const { type, startDate, endDate } = prevScope;
      if (type === "week") {
        const newStartDate =
          direction === "next"
            ? addWeeks(startDate, 1)
            : subWeeks(startDate, 1);
        return {
          type,
          startDate: newStartDate,
          endDate:
            direction === "next" ? addWeeks(endDate, 1) : subWeeks(endDate, 1),
        };
      } else {
        // month
        const newStartDate =
          direction === "next"
            ? addMonths(startDate, 1)
            : subMonths(startDate, 1);
        return {
          type,
          startDate: newStartDate,
          endDate:
            direction === "next"
              ? addMonths(endDate, 1)
              : subMonths(endDate, 1),
        };
      }
    });
  };

  const calculateMaxCount = (
    personalData: ProcessedLogs,
    averageData: AverageActivityData
  ) => {
    let maxCount = 0;
    Object.values(personalData).forEach((day) => {
      Object.values(day).forEach((user) => {
        Object.values(user.event_type).forEach((count) => {
          if (count > maxCount) {
            maxCount = count;
          }
        });
      });
    });

    Object.values(averageData).forEach((day) => {
      Object.values(day.event_type).forEach((count) => {
        if (count > maxCount) {
          maxCount = count;
        }
      });
    });

    return maxCount;
  };

  return (
    <Flex direction="column" height="100%">
      <Heading as="h2" size="md" mt={5} ml={6}>
        トレーニングログ
      </Heading>
      <Box ml="6" mr="6" mt="4" mb="4" width="280px">
        <Text mb="2" fontSize="lg">
          <Badge colorScheme="teal" p="1">
            ユーザーを選択
          </Badge>
        </Text>
        <Select
          bg="#f2f7f7"
          borderColor="#d0dadb"
          placeholder="ユーザーを選択"
          onChange={(e) => setSelectedUserId(Number(e.target.value))}
        >
          {companyUsers.map((user) => (
            <option key={user.user} value={user.user}>
              {user.username}
            </option>
          ))}
        </Select>
      </Box>
      <Box flex="1" overflowY="auto" height="100%" pl={4} pr={4} pb={4}>
        {selectedUserId === null ? (
          <Flex
            height="12%"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            mt={10}
          >
            <Text fontSize="2xl" fontWeight="bold" color="gray.600">
              ユーザーを選択してください
            </Text>
          </Flex>
        ) : (
          <Box>
            <Box my="4" width="88%" textAlign="center">
              <Flex alignItems="center" justifyContent="left">
                <Button mr="2" onClick={() => changeScope("previous")}>
                  前週
                </Button>
                <Button onClick={() => changeScope("next")}>次週</Button>
              </Flex>
            </Box>
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={6}
            >
              <GridItem
                colSpan={[2, 1]}
                p={5}
                boxShadow="md"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
              >
                <Text fontSize="xl" mb="2" textAlign="center">
                  ロープレ閲覧ポイント
                </Text>
                <ResponsiveContainer width="100%" height={340}>
                  <ComposedChart
                    data={serializedRoleplayData}
                    margin={{
                      top: 8,
                      right: 8,
                      bottom: 8,
                      left: 8,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="averageCount"
                      name="所属企業平均"
                      fill="#413ea0"
                    />
                    <Line
                      type="monotone"
                      dataKey="personalCount"
                      name="個人実績"
                      stroke="#e0a046"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </GridItem>
              <GridItem
                colSpan={[2, 1]}
                p={5}
                boxShadow="md"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
              >
                <Text fontSize="xl" mb="2" textAlign="center">
                  ヒアリングトレーニングポイント
                </Text>
                <ResponsiveContainer width="100%" height={340}>
                  <ComposedChart
                    data={serializedHearingData}
                    margin={{
                      top: 8,
                      right: 8,
                      bottom: 8,
                      left: 8,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="averageCount"
                      name="所属企業平均"
                      fill="#413ea0"
                    />
                    <Line
                      type="monotone"
                      dataKey="personalCount"
                      name="個人実績"
                      stroke="#e0a046"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </GridItem>
              <GridItem
                colSpan={[2, 1]}
                p={5}
                boxShadow="md"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
              >
                <Text fontSize="xl" mb="2" textAlign="center">
                  アウトプット回数
                </Text>
                <ResponsiveContainer width="100%" height={340}>
                  <ComposedChart
                    data={serializedRecordingData}
                    margin={{
                      top: 8,
                      right: 8,
                      bottom: 8,
                      left: 8,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="averageCount"
                      name="所属企業平均"
                      fill="#413ea0"
                    />
                    <Line
                      type="monotone"
                      dataKey="personalCount"
                      name="個人実績"
                      stroke="#e0a046"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </GridItem>
              <GridItem
                colSpan={[2, 1]}
                p={5}
                boxShadow="md"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
              >
                <Text fontSize="xl" mb="2" textAlign="center">
                  反論応酬話法トレーニング数
                </Text>
                <ResponsiveContainer width="100%" height={340}>
                  <ComposedChart
                    data={serializedDialogueData}
                    margin={{
                      top: 8,
                      right: 8,
                      bottom: 8,
                      left: 8,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="averageCount"
                      name="所属企業平均"
                      fill="#413ea0"
                    />
                    <Line
                      type="monotone"
                      dataKey="personalCount"
                      name="個人実績"
                      stroke="#e0a046"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </GridItem>
              <GridItem
                colSpan={[2, 1]}
                p={5}
                boxShadow="md"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
              >
                <Text fontSize="xl" mb="2" textAlign="center">
                  商談記録数
                </Text>
                <ResponsiveContainer width="100%" height={340}>
                  <ComposedChart
                    data={serializedCreateReportData}
                    margin={{
                      top: 8,
                      right: 8,
                      bottom: 8,
                      left: 8,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="averageCount"
                      name="所属企業平均"
                      fill="#413ea0"
                    />
                    <Line
                      type="monotone"
                      dataKey="personalCount"
                      name="個人実績"
                      stroke="#e0a046"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </GridItem>
              <GridItem
                colSpan={[2, 1]}
                p={5}
                boxShadow="md"
                borderRadius="lg"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
              >
                <Text fontSize="xl" mb="2" textAlign="center">
                  壁打ち数
                </Text>
                <ResponsiveContainer width="100%" height={340}>
                  <ComposedChart
                    data={serializedBrickWallData}
                    margin={{
                      top: 8,
                      right: 8,
                      bottom: 8,
                      left: 8,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, yAxisMax]} />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="averageCount"
                      name="所属企業平均"
                      fill="#413ea0"
                    />
                    <Line
                      type="monotone"
                      dataKey="personalCount"
                      name="個人実績"
                      stroke="#e0a046"
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </GridItem>
            </Grid>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default WorkoutLog;
