/** @format */

import React, { useEffect, useState, useContext, useRef } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Link,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  VStack,
  Text,
} from "@chakra-ui/react";
import { EditIcon, LinkIcon } from "@chakra-ui/icons";
import Joyride, { Step, CallBackProps } from "react-joyride";
import styled from "styled-components";
import { useMessage } from "./MessageContext";

const Container = styled.div`
  height: 100%;
  width: 90%;
  margin: auto;
`;

const StyledTh = styled(Th)<{ width: string }>`
  width: ${(props) => props.width};
`;

const StyledTd = styled(Td)<{ width: string }>`
  width: ${(props) => props.width};
`;

interface User {
  id: string;
  username: string;
}

interface TalkScript {
  id: string;
  name: string;
  users: User[];
  owner: User;
  created_at: string;
}

const TalkScriptList: React.FC = () => {
  const [talkScripts, setTalkScripts] = useState<TalkScript[]>([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const { setLoading } = useLoading();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { setMessage } = useMessage();
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [upsellModalOpen, setUpsellModalOpen] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [runTour, setRunTour] = useState(false);

  // fetchTalkScriptsをトップレベルに移動
  const fetchTalkScripts = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get("/api/talk_scripts/");
      setTalkScripts(data);
      if (data.length === 0) {
        setSteps([
          {
            target: ".create-talkscript-button",
            content: "ここをクリックして新しいトークスクリプトを作成しましょう",
          },
        ]);
        setRunTour(true);
      }
    } catch (error) {
      console.error("Error fetching talk scripts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTalkScripts();
  }, []);

  // 検索フィルター関数
  const filteredTalkScripts = talkScripts.filter((talkScript) => {
    return talkScript.name.toLowerCase().includes(search.toLowerCase());
  });

  // 削除処理の関数
  const handleDelete = async (id: string) => {
    const confirmed = window.confirm(
      "トークスクリプトを削除しますと、紐づいているトークや手本動画、発話データも削除されます。本当によろしいですか？"
    );
    if (confirmed) {
      setLoading(true);
      try {
        await apiClient.delete(`/api/talk_scripts/${id}/`);
        setTalkScripts(talkScripts.filter((ts) => ts.id !== id));
      } catch (error) {
        console.error("Error deleting talk script:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  // 複製処理の関数
  const handleDuplicate = async (id: string) => {
    setLoading(true);
    try {
      // 元のトークスクリプトを取得する
      const { data: originalScriptData } = await apiClient.get(
        `/api/talk_scripts/${id}/dl/`
      );

      // 元のトークスクリプトに含まれているトークとエッジをコピーする
      const newTalks = originalScriptData.talks.map((talk: any) => ({
        ...talk,
        talk_script: null,
      })); // talk_scriptは後で更新する
      const newEdges = originalScriptData.edges.map((edge: any) => ({
        ...edge,
        talk_script: null,
      })); // talk_scriptは後で更新する

      // 新たなトークスクリプトの情報を作成する
      const newScriptData = {
        ...originalScriptData.talk_script,
        name: `${originalScriptData.talk_script.name} コピー`,
        initial_node: null, // initial_nodeは後で更新する
      };

      // 新たなトークスクリプトを作成する
      const { data: newScript } = await apiClient.post(
        `/api/talk_scripts/`,
        newScriptData
      );

      let newTalksWithQuestions = [];

      // 元のトークIDと新しいトークIDのマップを作成する
      let oldIdToNewIdMap: { [key: number]: number } = {};

      // 新たなトークを作成し、それぞれに新たなトークスクリプトのIDを設定する
      for (let i = 0; i < newTalks.length; i++) {
        newTalks[i].talk_script = newScript.id;
        const { data: newTalk } = await apiClient.post(
          "/api/talks/",
          newTalks[i]
        );
        oldIdToNewIdMap[newTalks[i].id] = newTalk.id;

        // 新しいトークと紐付くexpected_questionsを保存
        newTalksWithQuestions.push({
          talk: newTalk,
          expectedQuestions: originalScriptData.expected_questions.filter(
            (eq: any) => eq.talk === newTalks[i].id
          ),
        });

        if (i == 0) newScript.initial_node = newTalk.id;
      }

      // 作成したトークスクリプトの初期ノードを更新する
      await apiClient.patch(`/api/talk_scripts/${newScript.id}/`, {
        initial_node: newScript.initial_node,
      });

      // 新たなエッジを作成し、それぞれに新たなトークスクリプトのIDと新しいソースとターゲットのIDを設定する
      for (let edge of newEdges) {
        edge.talk_script = newScript.id;
        edge.source = oldIdToNewIdMap[edge.source];
        edge.target = oldIdToNewIdMap[edge.target];
        edge.label = `トークID：${edge.target}へ移動`;
        await apiClient.post("/api/talks/edges/", edge);
      }

      // トークごとに期待される反論を複製する
      for (let talkWithQuestions of newTalksWithQuestions) {
        for (let originalQuestion of talkWithQuestions.expectedQuestions) {
          let newQuestion = {
            ...originalQuestion,
            talk: talkWithQuestions.talk.id, // 新しいトークIDに紐付け
            talk_script: newScript.id, // 新しいトークスクリプトIDに紐付け
          };
          delete newQuestion.id; // 新しいエンティティのために既存のIDを削除
          await apiClient.post("/api/expected_questions/", newQuestion);
        }
      }

      // 新たなトークスクリプトをリストに追加する
      setTalkScripts([...talkScripts, newScript]);
      await fetchTalkScripts();
    } catch (error) {
      console.error("Error duplicating talk script:", error);
      setMessage(
        "複製に失敗しました。サポートにお問い合わせください。",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const contents = e.target?.result;
          if (typeof contents === "string") {
            const data = JSON.parse(contents);

            const originalScriptData = data;

            // 元のトークスクリプトに含まれているトークとエッジをコピーする
            const newTalks = originalScriptData.talks.map((talk: any) => ({
              ...talk,
              talk_script: null,
            })); // talk_scriptは後で更新する
            const newEdges = originalScriptData.edges.map((edge: any) => ({
              ...edge,
              talk_script: null,
            })); // talk_scriptは後で更新する

            // 新たなトークスクリプトの情報を作成する
            const newScriptData = {
              ...originalScriptData.talk_script,
              name: `${originalScriptData.talk_script.name} `,
              initial_node: null, // initial_nodeは後で更新する
            };

            // 新たなトークスクリプトを作成する
            const { data: newScript } = await apiClient.post(
              `/api/talk_scripts/`,
              newScriptData
            );

            let newTalksWithQuestions = [];

            // 元のトークIDと新しいトークIDのマップを作成する
            let oldIdToNewIdMap: { [key: number]: number } = {};

            // 新たなトークを作成し、それぞれに新たなトークスクリプトのIDを設定する
            for (let i = 0; i < newTalks.length; i++) {
              newTalks[i].talk_script = newScript.id;
              const { data: newTalk } = await apiClient.post(
                "/api/talks/",
                newTalks[i]
              );
              oldIdToNewIdMap[newTalks[i].id] = newTalk.id;

              // 新しいトークと紐付くexpected_questionsを保存
              newTalksWithQuestions.push({
                talk: newTalk,
                expectedQuestions: originalScriptData.expected_questions.filter(
                  (eq: any) => eq.talk === newTalks[i].id
                ),
              });

              if (i == 0) newScript.initial_node = newTalk.id;
            }

            // 作成したトークスクリプトの初期ノードを更新する
            await apiClient.patch(`/api/talk_scripts/${newScript.id}/`, {
              initial_node: newScript.initial_node,
            });

            // 新たなエッジを作成し、それぞれに新たなトークスクリプトのIDと新しいソースとターゲットのIDを設定する
            for (let edge of newEdges) {
              edge.talk_script = newScript.id;
              edge.source = oldIdToNewIdMap[edge.source];
              edge.target = oldIdToNewIdMap[edge.target];
              edge.label = `トークID：${edge.target}へ移動`;
              await apiClient.post("/api/talks/edges/", edge);
            }

            // トークごとに期待される質問を複製する
            for (let talkWithQuestions of newTalksWithQuestions) {
              for (let originalQuestion of talkWithQuestions.expectedQuestions) {
                let newQuestion = {
                  ...originalQuestion,
                  talk: talkWithQuestions.talk.id,
                  talk_script: newScript.id,
                };
                delete newQuestion.id;
                await apiClient.post("/api/expected_questions/", newQuestion);
              }
            }

            // 新たなトークスクリプトをリストに追加する
            setTalkScripts([...talkScripts, newScript]);
            navigate(`/script/${newScript.id}`);
          }
        };
        reader.readAsText(file);
      } catch (error) {
        console.error("Error reading file:", error);
        setMessage(
          "ファイルが形式が不正です。正しい.tksファイルを読み込んでください。",
          "error"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const checkPlan = async () => {
    try {
      const { data } = await apiClient.get("/api/talk_scripts/plan-check/");
      return data.can_create_script;
    } catch (error) {
      console.error("Error checking plan:", error);
      return false;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const payment = urlParams.get("payment");

    if (payment === "success") {
      setMessage(
        "支払いが成功し、プランがアップグレードされました。",
        "success"
      );
    }

    fetchTalkScripts();
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      setRunTour(false);
      localStorage.removeItem("GuidanceTS");
    }
  };

  return (
    <Container>
      <Joyride steps={steps} run={runTour} callback={handleJoyrideCallback} />
      <Box mt={10}>
        {upsellModalOpen && (
          <Modal
            isOpen={upsellModalOpen}
            onClose={() => setUpsellModalOpen(false)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <VStack>
                <Flex justifyContent="flex-end" mb="4">
                  <ModalCloseButton />
                </Flex>
                <ModalHeader textAlign="center">
                  トークスクリプト作成数の上限です
                </ModalHeader>
              </VStack>
              <ModalBody>
                <VStack spacing={4} align="stretch">
                  <Text>
                    アップグレードすると無制限にトークスクリプトを作成していただけます。
                  </Text>
                  <Button
                    colorScheme="teal"
                    onClick={() => {
                      onClose();
                      window.open(
                        "https://site.talkscript.app/#price",
                        "_blank"
                      );
                    }}
                  >
                    アップグレード
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => setUpsellModalOpen(false)}
                  >
                    キャンセル
                  </Button>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
        <Flex justify="space-between" align="center" my={4}>
          <Heading as="h2" size="md" fontWeight="bold">
            スクリプト一覧
          </Heading>
          <div>
            <Button
              onClick={async () => {
                if (await checkPlan()) {
                  navigate("/script/new");
                } else {
                  setUpsellModalOpen(true);
                }
              }}
              backgroundColor="#59a3d4"
              color="white"
              _hover={{ opacity: 0.7 }}
            >
              新規作成
            </Button>
          </div>
        </Flex>
        <Input
          placeholder="スクリプト検索"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Table variant="unstyled" mt={4}>
          <Thead>
            <Tr>
              <StyledTh width="60%">トークスクリプト</StyledTh>
              <StyledTh width="30%">作成日時</StyledTh>
              <StyledTh width="10%">操作</StyledTh>
            </Tr>
          </Thead>
          <Tbody>
            {filteredTalkScripts.map((talkScript) => {
              // 日付データの変換
              const date = new Date(talkScript.created_at);
              const formattedDate = date.toLocaleString("ja-JP", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              });

              return (
                <Tr key={talkScript.id}>
                  <StyledTd width="60%">
                    <Link
                      as={RouterLink}
                      to={`/script/${talkScript.id}`}
                      fontWeight="bold"
                      color="teal.600"
                    >
                      {talkScript.users.length > 1 && (
                        <LinkIcon color="red" marginRight="8px" />
                      )}
                      {talkScript.name}
                    </Link>
                  </StyledTd>
                  <StyledTd width="30%">{formattedDate}</StyledTd>
                  <StyledTd width="10%">
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<EditIcon />}
                        variant="outline"
                      />
                      <MenuList>
                        <MenuItem
                          onClick={async () => {
                            if (await checkPlan()) {
                              handleDuplicate(talkScript.id);
                            } else {
                              setUpsellModalOpen(true);
                            }
                          }}
                        >
                          複製
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            if (
                              currentUser &&
                              Number(talkScript.owner) === currentUser.id
                            ) {
                              handleDelete(talkScript.id);
                            }
                          }}
                          isDisabled={
                            currentUser
                              ? Number(talkScript.owner) !== currentUser.id
                              : true
                          }
                          color="#d94666"
                        >
                          削除
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </StyledTd>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default TalkScriptList;
