import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { Box, Button, Text, Center, Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TextContainer = styled(Box)`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const EmailVerificationPrompt: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, checkToken } = useAuth();

  const handleReload = async () => {
    setLoading(true);
    await checkToken();
    setLoading(false);
  };

  useEffect(() => {
    if (user?.email_verified) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <Container>
      <TextContainer>
        <Text fontSize="xl" mb={4}>
          メールアドレスの確認が必要です
        </Text>
        <Text mb={8}>
          登録したメールアドレスに送信された確認メールのリンクをクリックしてください。リンクをクリック後、以下のリロードボタンを押下してください。
        </Text>
      </TextContainer>
      {loading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Button colorScheme="blue" onClick={handleReload}>
          リロード
        </Button>
      )}
    </Container>
  );
};

export default EmailVerificationPrompt;
