/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Stack,
  useToast,
  Flex,
  CloseButton,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";

type ShareTalkScriptDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  talkScriptId: string;
  companyId: string | undefined;
};

type User = {
  id: number;
  username: string;
  email: string;
};

type UserRole = {
  role: string;
  user: User;
};

type Company = {
  admin: number;
  max_users: number;
  company_users: UserRole[];
};

type TalkScript = {
  id: number;
  owner: number;
};

const ShareTalkScriptDialog: React.FC<ShareTalkScriptDialogProps> = ({
  isOpen,
  onClose,
  talkScriptId,
  companyId,
}) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [talkScript, setTalkScript] = useState<TalkScript | null>(null);
  const [talkScriptUsers, setTalkScriptUsers] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const toast = useToast();
  const dummyRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      apiClient
        .get(`api/companies/${companyId}/`)
        .then((response) => {
          setCompany(response.data);
        })
        .catch((error) => console.error(error));

      apiClient
        .get(`api/talk_scripts/${talkScriptId}/`)
        .then((response) => {
          setTalkScript(response.data);
          setTalkScriptUsers(response.data.users);
        })
        .catch((error) => console.error(error));
    }
  }, [isOpen, companyId, talkScriptId]);

  useEffect(() => {
    setSelectedUsers(talkScriptUsers);
  }, [talkScriptUsers]);

  const handleCheckboxChange = (userId: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const handleShareClick = () => {
    setLoading(true);
    const loggedInUserId = loggedInUser?.id || 0;
    const userIdsToShare = selectedUsers.includes(loggedInUserId)
      ? selectedUsers
      : [...selectedUsers, loggedInUserId];
    apiClient
      .put(`api/talk_scripts/${talkScriptId}/add_users/`, {
        user_ids: userIdsToShare,
      })
      .then(() => {
        toast({
          title: "共有・解除設定に成功",
          description: "TalkScriptを共有・解除に成功しました",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "共有に失敗しました",
          description: "何か問題が発生しました。再度お試しください",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateAndCopyShareLink = async () => {
    try {
      // 共有URLを生成するAPIを呼び出す
      const response = await apiClient.post(
        "api/talk_scripts/generate-share-link/",
        {
          talk_script_id: talkScriptId,
        }
      );

      const shareLink = response.data.share_link;

      // URLをクリップボードにコピー
      navigator.clipboard.writeText(shareLink);
      toast({
        title: "共有URLをクリップボードにコピーしました",
        description:
          "チームメンバーにトークスクリプトを共有後にこのURLを伝えてください。共有していないユーザーはこのリンクをご利用いただけません。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "共有URLの生成に失敗しました",
        description: "何か問題が発生しました。再度お試しください",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const handleTransferOwnership = () => {
    if (!company || !loggedInUser) return;

    if (window.confirm("本当に移管してよろしいでしょうか？")) {
      setLoading(true);
      apiClient
        .patch(`api/talk_scripts/${talkScriptId}/transfer_ownership/`, {
          new_owner_id: company.admin,
        })
        .then(() => {
          toast({
            title: "移管に成功しました",
            description: "TalkScriptの管理者が変更されました。",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          navigate("/dashboard");
        })
        .catch((error) => {
          toast({
            title: "移管に失敗しました",
            description: "何か問題が発生しました。再度お試しください",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={dummyRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            as={Flex}
            justifyContent="space-between"
            alignItems="center"
            fontSize="md"
          >
            ユーザーへの共有・共有解除
            <CloseButton onClick={onClose} />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Stack spacing={3}>
              {company?.company_users.map(
                (companyUser) =>
                  loggedInUser?.id !== companyUser.user.id && (
                    <Checkbox
                      key={companyUser.user.id}
                      isChecked={selectedUsers.includes(companyUser.user.id)}
                      onChange={() => handleCheckboxChange(companyUser.user.id)}
                    >
                      {" "}
                      {companyUser.user.username}
                    </Checkbox>
                  )
              )}
              {talkScript?.owner === loggedInUser?.id &&
                loggedInUser?.id !== company?.admin && (
                  <Link
                    color="red.400"
                    onClick={handleTransferOwnership}
                    textDecoration="underline"
                    fontSize="xs"
                    fontWeight="bold"
                    _hover={{ color: "red.600" }}
                  >
                    管理者に所有権を移管
                  </Link>
                )}
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter
            as={Flex}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              size="sm"
              fontSize="xs"
              colorScheme="teal"
              onClick={generateAndCopyShareLink}
              borderRadius="50px"
              borderWidth="2px"
              bg="white"
              color="teal.500"
              borderColor="teal.500"
              _hover={{
                bg: "teal.500",
                color: "white",
              }}
            >
              URLをコピー
            </Button>
            <Button colorScheme="blue" onClick={handleShareClick} ml={3}>
              共有・解除
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ShareTalkScriptDialog;
