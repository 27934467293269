/** @format */

import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Link,
  Image,
  Spacer,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { MdHelp } from "react-icons/md";
import logo from "./assets/ts_logo.png";
import userIcon from "./assets/me.png";
import { useAuth } from "./AuthProvider";
import Account from "./Account";

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });
  const {
    isOpen: modalOpen,
    onOpen: openAccountModal,
    onClose: closeAccountModal,
  } = useDisclosure();
  const navigate = useNavigate();

  const tutorialLink = (
    <Link href="https://help.talkscript.app/" isExternal mr={8}>
      <Box _hover={{ opacity: 0.7 }}>
        <MdHelp style={{ fontSize: "28px" }} />
      </Box>
    </Link>
  );

  const tutorialMenuItem = (
    <MenuItem
      as="a"
      href="https://help.talkscript.app/"
      target="_blank"
      rel="noopener noreferrer"
      color="black"
    >
      ヘルプ
    </MenuItem>
  );

  // ロゴをクリックしたときの挙動をカスタマイズする関数
  const handleLogoClick = () => {
    if (isAuthenticated) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const menuAuthenticated = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        {tutorialMenuItem}
        <MenuItem onClick={openAccountModal} color="black">
          マイアカウント
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const menuNotAuthenticated = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList>
        {tutorialMenuItem}
        <MenuItem as={RouterLink} to="/login" color="black">
          ログイン
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const largeScreenNavigationAuthenticated = (
    <>
      {tutorialLink}
      <Box mr={8} onClick={openAccountModal}>
        <Image
          src={userIcon}
          alt="マイアカウント"
          boxSize="38px"
          _hover={{ opacity: 0.7, cursor: "pointer" }}
        />
      </Box>
    </>
  );

  const largeScreenNavigationNotAuthenticated = (
    <>
      {tutorialLink}
      <Link as={RouterLink} to="/login" mr={8}>
        ログイン
      </Link>
    </>
  );

  return (
    <Box
      bg="white"
      p={3}
      color="black"
      borderBottom="2px solid"
      borderColor="gray.200"
    >
      <Flex align="center">
        <Heading as="h1" size="lg" ml={1}>
          <Link onClick={handleLogoClick}>
            <Image
              src={logo}
              alt="talkscript｜トークスクリプト"
              width="188px"
            />
          </Link>
        </Heading>
        <Spacer />
        {isSmallScreen
          ? isAuthenticated
            ? menuAuthenticated
            : menuNotAuthenticated
          : isAuthenticated
          ? largeScreenNavigationAuthenticated
          : largeScreenNavigationNotAuthenticated}
        {modalOpen && (
          <Account
            modalOpen={modalOpen}
            closeAccountModal={closeAccountModal}
          />
        )}
      </Flex>
    </Box>
  );
};

export default Header;
