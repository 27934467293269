/** @format */

import React, { useState, useEffect } from "react";
import { Handle, Position } from "reactflow";
import styled, { css } from "styled-components";
import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { BsGripHorizontal } from "react-icons/bs";
import {
  FaTimesCircle,
  FaTrash,
  FaCloudUploadAlt,
  FaPlay,
} from "react-icons/fa";
import ReactPlayer from "react-player";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import TalkScriptSelectModal from "./TalkScriptSelectModal";
import ExpectedQuestionsTable from "./ExpectedQuestionsTable";
import { useViewMode } from "./ViewModeContext";
import { useLoading } from "./LoadingContext";
import TiptapEditor from "./TiptapEditor";
import DemonstrationSelectModal from "./DemonstrationSelectModal";
import bgImage from "./assets/bg_linknode.png";

const CustomNode = styled.div<{ selected: boolean; $isIndependent?: boolean }>`
  cursor: default;
  display: flex;
  flex-flow: column;
  min-height: 440px;
  width: 360px;
  border: 4px solid #a5c4cc;
  padding: 4px 4px 16px;
  border-radius: 12px;
  background: white;
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  ${(props) =>
    props.$isIndependent &&
    css`
      background: #faf5fc;
    `}
`;

const LinkNode = styled.div<{ selected: boolean; $isIndependent?: boolean }>`
  display: flex;
  flex-flow: column;
  height: 464px;
  width: 360px;
  border: 4px solid #e3c6c5;
  padding: 4px 4px 16px;
  border-radius: 12px;
  background-image: url(${bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 18px;
  transition: all 0.3s ease;
  position: relative;
  background-color: white;
  ${(props) =>
    props.$isIndependent &&
    css`
      background-color: #faf5fc;
    `}
`;

const DemoNode = styled.div<{ selected: boolean; $isIndependent?: boolean }>`
  display: flex;
  flex-flow: column;
  min-height: 540px;
  width: 480px;
  border: 4px solid #bce8c0;
  padding: 4px 4px 16px;
  border-radius: 12px;
  background: #edeefc;
  font-size: 18px;
  transition: all 0.3s ease;
  position: relative;
  ${(props) =>
    props.$isIndependent &&
    css`
      background-color: #faf5fc;
    `}
`;

const DemoContent = styled.div`
  width: 96%;
  margin: 2px auto;
  text-align: center;
`;

const VideoContainer = styled.div`
  position: relative;
  margin-top: 4px;
  border: 1px solid #ddd;
  background-color: #000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
`;

const DemoTitle = styled(Text)`
  margin: 0 auto 
  text-align: center;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 18px;
  color: gray;
`;

const DemoTextChank = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
`;

const DemoTextArea = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
`;

const DemoLabel = styled.label`
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #5ab6b8;
  text-align: left;
`;

const DemoText = styled.p`
  font-size: 16px;
  color: #666;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const Label = styled.label`
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 2px;
  color: #5ab6b8;
`;

const TextChank = styled.div`
  margin-bottom: 2px;
  padding: 8px 8px;
`;

const TextArea = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 2px;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
`;

const DeleteNodeButton = styled.button`
  color: #ea7d7d;
  width: 22px;
  height: 22px;
  border: none;
  background: none;
  font-weight: bold;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const LinkToTalkScriptButton = styled(Button)`
  background-color: #8f3d0b;
  color: white;
  margin: auto;
  &:hover {
    background-color: #8f3d0b;
    opacity: 0.7;
    cursor: pointer;
  }
`;

const FlexRightGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  margin-right: 4px;
  margin-top: 2px;
`;

const FlexLeftGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-top: 2px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const DragHandle = styled.button`
  width: 30px;
  height: 30px;
  margin-top: 30px;
  color: #5ab6b8;
  position: absolute;
  left: 46%;
  transform: translate(0, -50%);
  &:hover {
    opacity: 0.7;
    cursor: move;
  }
`;

const MovieButton = styled.button`
  background-color: #87bf73;
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    opacity: 0.7;
  }
`;

const DeleteMovieButton = styled.button`
  background-color: #ea7d7d;
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    opacity: 0.7;
  }
`;

const QuestionMiniButton = styled.button`
  position: relative;
  background-color: #b85ab0;
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    opacity: 0.7;
  }
`;

const RecResMiniButton = styled.button`
  position: relative;
  background-color: #35bddb;
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);

  &:hover {
    opacity: 0.7;
  }
`;

const DemoMiniButton = styled.button<{ $hasDemo?: boolean }>`
  background-color: ${(props) => (props.$hasDemo ? "#de6676" : "#5760c9")};
  color: white;
  font-weight: bold;
  font-size: 0.8em;
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    opacity: 0.7;
  }
`;

const Badge = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff0544;
  border: 1px solid #dbdbdb;
  position: absolute;
  right: -6px;
  top: -4px;
  transform: translate(50%, -50%);
`;

const ButtonText = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

interface Demo {
  id: number;
  title: string;
  use_case: string;
  demo_video_url: string;
}

interface TextUpdaterNodeProps {
  data: {
    id: string;
    title?: string;
    content?: string;
    content_wysiwyg?: string;
    isInitialNode: boolean;
    nodeId: string;
    position_x: number;
    position_y: number;
    talk_script: number;
    node_type: string;
    link_url?: string;
    edges?: EdgeOrder[];
    onChange?: (title: string, content: string) => void;
    onSelect: (id: string, selected: boolean) => void;
    fetchTalks: () => void;
    currentNode?: Node;
    isIndependent: boolean;
    hasQuestions: boolean;
    isMediaModalOpen: boolean;
    media_url?: string;
    demo?: Demo;
    onPlayMedia?: (nodeId: string) => void;
  };
  isConnectable: boolean;
  selected: boolean;
}

interface EdgeOrder {
  id: string;
  source: string;
  target: string;
  label: string;
  type: string;
  animated: boolean;
  deletable: boolean;
  labelStyle?: React.CSSProperties;
  source_title: string;
  target_title: string;
  target_node_type: string;
}

type TalkScript = {
  id: number;
  initial_node: number;
  name: string;
  owner: number;
  users: number[];
  created_at: string;
  updated_at: string;
};

const TextUpdaterNode: React.FC<TextUpdaterNodeProps> = ({
  data,
  selected,
}) => {
  const [contentWysiwyg, setContentWysiwyg] = useState(
    data.content_wysiwyg || ""
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [talkScript, setTalkScript] = useState<TalkScript | null>(null);
  const { viewMode } = useViewMode();
  const { loading, setLoading } = useLoading();
  const isConnectable = !viewMode;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dialogueHistoryId, setDialogueHistoryId] = useState<number | null>(
    null
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [freeLimitOpen, setFreeLimitOpen] = useState(false);
  const [randomQuestion, setRandomQuestion] = useState("");
  const { user } = useAuth();
  const [isLinkSet, setIsLinkSet] = useState(!!data.link_url);
  const [isYoutubeModalOpen, setIsYoutubeModalOpen] = useState(false);
  const [isDemoSelectOpen, setIsDemoSelectOpen] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState("");
  const toast = useToast();

  const sourceHandleStyle = {
    background: "transparent",
    border: "4px solid #b85ab0",
    borderLeftColor: "#b85ab0",
    width: "12px",
    height: "24px",
    borderRadius: "0 100% 100% 0",
    right: "-12px",
  };

  const targetHandleStyle = {
    background: "transparent",
    border: "4px solid #b85ab0",
    borderRightColor: "#b85ab0",
    width: "12px",
    height: "24px",
    borderRadius: "100% 0 0 100%",
    left: "-12px",
  };

  const saveDataToServer = async (
    nodeId: string,
    position_x: number,
    position_y: number,
    talk_script: number
  ) => {
    try {
      const response = await apiClient.put(`/api/talks/${nodeId}/`, {
        position_x: position_x,
        position_y: position_y,
        talk_script: talk_script,
      });

      if (response.status === 200) {
      } else {
        console.error("Error saving data to server:", response.status);
      }
    } catch (error) {
      console.error("Error saving data to server:", error);
    }
  };

  useEffect(() => {
    if (selected) {
      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter" && e.ctrlKey) {
          saveDataToServer(
            data.nodeId,
            data.position_x,
            data.position_y,
            data.talk_script
          );
        }
      };

      document.addEventListener("keyup", handleKeyUp);
      return () => {
        document.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [selected]);

  const deleteNode = async (nodeId: string) => {
    setLoading(true);
    try {
      const talkResponse = await apiClient.get(`/api/talks/${nodeId}/`);
      const talkData = talkResponse.data;

      if (talkData.is_independent) {
        await apiClient.delete(`/api/talks/${nodeId}/`);
        data.fetchTalks();
        return;
      }

      const edgesFromNodeResponse = await apiClient.get(
        `/api/talks/edges/?source=${nodeId}`
      );
      const edgesToNodeResponse = await apiClient.get(
        `/api/talks/edges/?target=${nodeId}`
      );
      const edgesFromNode = edgesFromNodeResponse.data;
      const edgesToNode = edgesToNodeResponse.data;

      const connectedNodes: Set<string> = new Set();

      const updateIndependentStatus = async (ids: string[]) => {
        for (const id of ids) {
          const edgesTo = await apiClient.get(`/api/talks/edges/?target=${id}`);
          const edgesFrom = await apiClient.get(
            `/api/talks/edges/?source=${id}`
          );

          if (edgesTo.data.length === 0 && edgesFrom.data.length === 0) {
            await apiClient.patch(`/api/talks/${id}/`, {
              is_independent: true,
            });
          }
        }
      };

      // Case 1
      if (edgesFromNode.length > 0 && edgesToNode.length === 0) {
        for (const edge of edgesFromNode) {
          connectedNodes.add(edge.target);
          await apiClient.delete(`/api/talks/edges/${edge.id}/`);
        }
        await apiClient.delete(`/api/talks/${nodeId}/`);
        await updateIndependentStatus(Array.from(connectedNodes));
      }
      // Case 2
      else if (edgesToNode.length > 0 && edgesFromNode.length === 0) {
        for (const edge of edgesToNode) {
          connectedNodes.add(edge.source);
          await apiClient.delete(`/api/talks/edges/${edge.id}/`);
        }
        await apiClient.delete(`/api/talks/${nodeId}/`);
        await updateIndependentStatus(Array.from(connectedNodes));
      }
      // Case 3
      else if (edgesFromNode.length > 0 && edgesToNode.length > 0) {
        for (const edge of edgesFromNode) {
          connectedNodes.add(edge.target);
          await apiClient.delete(`/api/talks/edges/${edge.id}/`);
        }
        for (const edge of edgesToNode) {
          connectedNodes.add(edge.source);
          await apiClient.delete(`/api/talks/edges/${edge.id}/`);
        }
        await apiClient.delete(`/api/talks/${nodeId}/`);
        await updateIndependentStatus(Array.from(connectedNodes));
      }

      data.fetchTalks();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchTalkScriptData = async () => {
      if (data.link_url) {
        const url = new URL(data.link_url);
        // URLからトークンを取得
        const token = url.pathname.split("/")[2];

        try {
          // トークンを使用してTalkScriptのIDを取得
          const tokenResponse = await apiClient.get(
            `/api/talk_scripts/get_id_from_token/?token=${token}`
          );
          if (tokenResponse.data.talk_script_id) {
            // TalkScriptのIDを使用して詳細情報を取得
            const talkScriptResponse = await apiClient.get(
              `/api/talk_scripts/${tokenResponse.data.talk_script_id}/`
            );
            setTalkScript(talkScriptResponse.data);
          }
        } catch (error) {
          console.error("Error fetching talk script data:", error);
        }
      }
    };

    fetchTalkScriptData();
  }, [data.link_url]);

  const handleQuestionListButtonClick = () => {
    onOpen();
  };

  const handleFileUpload = async (event: any, nodeId: string) => {
    const file = event.target.files[0];
    if (!file) return;

    // ファイルのバリデーション
    const validMimeTypes = ["video/mp4", "audio/mpeg"];

    const maxSize = 1000 * 1024 * 1024;

    if (!validMimeTypes.includes(file.type) || file.size > maxSize) {
      toast({
        title: "無効なファイル形式またはファイルサイズ",
        description:
          "ファイル形式が未対応、もしくはファイルサイズの上限（1GB）を超えています。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // ファイルのアップロード
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const response = await apiClient.post(
        `/api/talks/${nodeId}/upload_media/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast({
          title: "アップロード成功",
          description: "ファイルが正常にアップロードされました。",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        data.fetchTalks();
        setIsYoutubeModalOpen(false);
      }
    } catch (error) {
      toast({
        title: "アップロード失敗",
        description: "ファイルのアップロードに失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (nodeId: string) => {
    const fileInput = document.getElementById(`file-upload-${nodeId}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error("File input not found");
    }
  };

  const handleDeleteMedia = async (nodeId: string) => {
    const confirmDelete = window.confirm(
      "本当にこのメディアを削除してもよろしいですか？"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      setLoading(true);
      const response = await apiClient.delete(
        `/api/talks/${nodeId}/delete_media/`
      );

      if (response.status === 200) {
        toast({
          title: "メディア削除成功",
          description: "メディアが正常に削除されました。",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });

        data.fetchTalks();
      }
    } catch (error) {
      toast({
        title: "メディア削除失敗",
        description: "メディアの削除に失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  // YTモーダルを開く関数
  const openMediaModal = (nodeId: string) => {
    setSelectedNodeId(nodeId);
    setIsYoutubeModalOpen(true);
  };

  // YTモーダルを閉じる関数
  const closeYoutubeModal = () => setIsYoutubeModalOpen(false);

  const truncateText = (text: string, maxLength: number = 16): string => {
    return text.length > maxLength ? text.substring(0, maxLength) + "…" : text;
  };

  const handleDemoSelectToggle = () => {
    setIsDemoSelectOpen(!isDemoSelectOpen);
  };

  if (data.node_type === "demo") {
    return (
      <DemoNode
        className={selected ? "selected" : ""}
        selected={selected}
        $isIndependent={data.isIndependent}
        onClick={viewMode ? undefined : () => data.onSelect(data.nodeId, true)}
      >
        {!viewMode && (
          <ButtonArea>
            <FlexLeftGroup>
              {!data.isInitialNode && (
                <DeleteNodeButton
                  disabled={loading}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (
                      window.confirm(
                        "トークを削除すると関連している、お手本動画や発話データも削除されます。本当にトークを消してよろしいですか？"
                      )
                    ) {
                      deleteNode(data.nodeId);
                    }
                  }}
                >
                  <FaTimesCircle size={22} />
                </DeleteNodeButton>
              )}
            </FlexLeftGroup>
            <DragHandle className="material-symbols-outlined custom-drag-handle">
              <BsGripHorizontal size={30} />
            </DragHandle>
            <FlexRightGroup>
              <DemoMiniButton
                disabled={loading}
                onClick={handleDemoSelectToggle}
                $hasDemo={!!data.demo && !!data.demo.demo_video_url}
              >
                {data.demo ? "別のデモ動画にリンク" : "デモ動画リンク"}
              </DemoMiniButton>
            </FlexRightGroup>
          </ButtonArea>
        )}
        {data.demo && (
          <DemoContent>
            <DemoTitle>{data.title}</DemoTitle>
            <VideoContainer>
              <ReactPlayer
                url={data.demo.demo_video_url || ""}
                playing={false}
                width="100%"
                height="100%"
                controls={true}
                onReady={() => setLoading(false)}
                onStart={() => setLoading(false)}
              />
            </VideoContainer>
            <DemoTextChank>
              <DemoLabel>説明</DemoLabel>
              <DemoTextArea>
                <DemoText>{data.content}</DemoText>
              </DemoTextArea>
            </DemoTextChank>
          </DemoContent>
        )}

        <Handle
          type="target"
          position={Position.Left}
          id="a"
          isConnectable={isConnectable}
          style={targetHandleStyle}
        />
        <Handle
          type="source"
          position={Position.Right}
          id="b"
          isConnectable={isConnectable}
          style={sourceHandleStyle}
        />
        <DemonstrationSelectModal
          isDemoSelectOpen={isDemoSelectOpen}
          onDemoSelectClose={() => setIsDemoSelectOpen(false)}
          onCancel={() => setIsDemoSelectOpen(false)}
          currentNodeId={Number(data.nodeId)}
          fetchTalk={data.fetchTalks}
        />
      </DemoNode>
    );
  }

  if (data.node_type === "linked") {
    return (
      <LinkNode
        className={selected ? "selected" : ""}
        selected={selected}
        $isIndependent={data.isIndependent}
        onClick={viewMode ? undefined : () => data.onSelect(data.nodeId, true)}
      >
        {!viewMode && (
          <ButtonArea>
            <FlexLeftGroup>
              {!data.isInitialNode && (
                <DeleteNodeButton
                  disabled={loading}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (
                      window.confirm("本当にトークを消してよろしいですか？")
                    ) {
                      deleteNode(data.nodeId);
                    }
                  }}
                >
                  <FaTimesCircle size={22} />
                </DeleteNodeButton>
              )}
            </FlexLeftGroup>
            <DragHandle className="material-symbols-outlined custom-drag-handle">
              <BsGripHorizontal size={30} />
            </DragHandle>
          </ButtonArea>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ flex: "1" }}></div>{" "}
          <Box mb={16} textAlign="center">
            {isLinkSet ? (
              <>
                <Box mb={3}>
                  {talkScript?.users.includes(user?.id ?? -1) ? (
                    <LinkToTalkScriptButton>
                      <a
                        href={data.link_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${truncateText(talkScript.name ?? "")}へ移動`}
                      </a>
                    </LinkToTalkScriptButton>
                  ) : (
                    <span
                      style={{
                        pointerEvents: "none",
                        color: "#ccc",
                        fontWeight: "bold",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      無効なリンク
                    </span>
                  )}
                </Box>
                <Box>
                  {!viewMode && (
                    <Button
                      fontSize="small"
                      fontWeight="bold"
                      onClick={() => setModalOpen(true)}
                    >
                      変更する
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <Button
                colorScheme="blue"
                isDisabled={viewMode}
                onClick={() => setModalOpen(true)}
              >
                {viewMode ? "未設定" : "リンク先を設定"}
              </Button>
            )}
          </Box>
        </div>
        <Handle
          type="target"
          position={Position.Left}
          id="a"
          isConnectable={isConnectable}
          style={targetHandleStyle}
        />
        <TalkScriptSelectModal
          isOpen={modalOpen}
          onClose={handleCloseModal}
          onCancel={handleCloseModal}
          currentScriptId={data.talk_script}
          currentNodeId={Number(data.nodeId)}
          fetchTalk={data.fetchTalks}
          onLinkSetSuccess={() => {
            setIsLinkSet(true);
          }}
        />
      </LinkNode>
    );
  }

  return (
    <CustomNode
      className={selected ? "selected" : ""}
      selected={selected}
      $isIndependent={data.isIndependent}
      onClick={viewMode ? undefined : () => data.onSelect(data.nodeId, true)}
    >
      {viewMode ? (
        <ButtonArea>
          <FlexRightGroup>
            {data.media_url && (
              <MovieButton
                disabled={loading}
                onClick={() =>
                  data.onPlayMedia && data.onPlayMedia(data.nodeId)
                }
                style={{
                  opacity: data.isMediaModalOpen ? 0.5 : 1,
                }}
              >
                <ButtonText>
                  <Icon as={FaPlay} mr="2px" />
                  手本
                </ButtonText>
              </MovieButton>
            )}

            <QuestionMiniButton
              disabled={loading}
              onClick={handleQuestionListButtonClick}
              style={{
                visibility: data.isMediaModalOpen ? "hidden" : "visible",
              }}
            >
              <ButtonText>
                反論・質問{data.hasQuestions && <Badge />}
              </ButtonText>
            </QuestionMiniButton>
          </FlexRightGroup>
        </ButtonArea>
      ) : (
        <ButtonArea>
          <FlexLeftGroup>
            {!data.isInitialNode && (
              <DeleteNodeButton
                disabled={loading}
                onClick={(event) => {
                  event.stopPropagation();
                  if (
                    window.confirm(
                      "トークを削除すると関連している、お手本動画や発話データも削除されます。本当にトークを消してよろしいですか？"
                    )
                  ) {
                    deleteNode(data.nodeId);
                  }
                }}
              >
                <FaTimesCircle size={22} />
              </DeleteNodeButton>
            )}
          </FlexLeftGroup>
          {!data.isInitialNode && (
            <DragHandle className="material-symbols-outlined custom-drag-handle">
              <BsGripHorizontal size={30} />
            </DragHandle>
          )}
          <FlexRightGroup>
            {data.media_url ? (
              <DeleteMovieButton
                disabled={loading}
                onClick={() => handleDeleteMedia(data.nodeId)}
              >
                <ButtonText>
                  <Icon as={FaTrash} mr="2px" />
                  手本
                </ButtonText>
              </DeleteMovieButton>
            ) : (
              <MovieButton
                disabled={loading}
                onClick={() => openMediaModal(data.nodeId)}
              >
                <ButtonText>
                  <Icon as={FaCloudUploadAlt} mr="2px" />
                  手本
                </ButtonText>
              </MovieButton>
            )}
            <QuestionMiniButton
              disabled={loading}
              onClick={handleQuestionListButtonClick}
            >
              反論・質問
            </QuestionMiniButton>
          </FlexRightGroup>
        </ButtonArea>
      )}
      <TextChank>
        <Label id="talk-content-label">トーク内容</Label>
        <TextArea>
          <TiptapEditor
            initialContent={contentWysiwyg}
            nodeId={data.nodeId}
            talkScriptId={data.talk_script}
            id="talk-content-label"
            aria-labelledby="talk-content-label"
          />
        </TextArea>
      </TextChank>
      {!data.isInitialNode && (
        <Handle
          type="target"
          position={Position.Left}
          id="a"
          isConnectable={isConnectable}
          style={targetHandleStyle}
        />
      )}
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        style={sourceHandleStyle}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>このトークの反論・質問一覧</DrawerHeader>
            <DrawerBody>
              <ExpectedQuestionsTable
                talkId={Number(data.nodeId)}
                talkScriptId={data.talk_script}
              />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Modal isOpen={isYoutubeModalOpen} onClose={closeYoutubeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">手本動画をアップロード</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <ul
                style={{
                  listStyleType: "circle",
                  paddingLeft: "20px",
                  color: "gray.500",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <Text fontSize="sm">
                    対応ファイル形式は{" "}
                    <span style={{ color: "#ff6666", fontWeight: "bold" }}>
                      mp4,mp3
                    </span>
                    です
                  </Text>
                </li>
                <li>
                  <Text fontSize="sm">
                    アップロードファイルサイズ上限は{" "}
                    <span style={{ color: "#ff6666", fontWeight: "bold" }}>
                      1GB
                    </span>
                    です
                  </Text>
                </li>
              </ul>
              {user && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "14px",
                  }}
                >
                  <Button
                    colorScheme="blue"
                    disabled={loading}
                    onClick={() => {
                      handleButtonClick(selectedNodeId);
                    }}
                  >
                    動画アップロード
                    <input
                      id={`file-upload-${selectedNodeId}`}
                      type="file"
                      onChange={(event) =>
                        handleFileUpload(event, selectedNodeId)
                      }
                      style={{ display: "none" }}
                    />
                  </Button>
                </div>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </CustomNode>
  );
};

export default TextUpdaterNode;
