/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  VStack,
  Text,
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  CircularProgress,
  useToast,
  IconButton,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
} from "@chakra-ui/react";
import { MdOutlineVolumeUp, MdBuild, MdOutlineSmartToy } from "react-icons/md";
import { FiCircle } from "react-icons/fi";
import {
  BsFillMicFill,
  BsStopCircleFill,
  BsFillSignStopFill,
} from "react-icons/bs";
import styled, { keyframes, css } from "styled-components";
import Lottie from "react-lottie";
import { saveAs } from "file-saver";
import apiClient from "./apiClient";
import { speak } from "./SpeechUtils";
import { useAuth } from "./AuthProvider";
import manAnimation from "./assets/man_animation.json";
import womanAnimation from "./assets/woman_animation.json";

const StyledText = styled.p<{ color?: string; fontWeight?: string }>`
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 18px;
  color: ${(props) => props.color || "inherit"};
  font-weight: ${(props) => props.fontWeight || "inherit"};
`;

const BlinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 660px;
  height: 100%;
`;

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftColumn = styled.div`
  flex: 0 0 65%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightColumn = styled.div`
  flex: 0 0 35%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChatContainer = styled(Box)`
  position: relative;
  display: flex;
  margin-top: 108px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  max-height: calc(100% - 188px);
  width: 96%;
  margin-bottom: 226px;
  padding: 8px;
`;

const EmptyChatContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const VoiceContainer = styled(Box)`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 104px;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 999;
  button {
    height: 100%;
  }
`;

const AssistantMessage = styled.div`
  position: relative;
  display: inline-block;
  margin: 1.5em 15px 1.5em 0;
  padding: 7px 10px;
  max-width: 90%;
  color: #555;
  font-size: 16px;
  background: #fff;
  border: solid 3px #555;
  border-radius: 12px;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: -24px;
    margin-top: -12px;
    border: 12px solid transparent;
    border-left: 12px solid #fff;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -30px;
    margin-top: -14px;
    border: 14px solid transparent;
    border-left: 14px solid #555;
    z-index: 1;
  }

  p {
    margin: 6px;
    padding: 0;
  }
`;

const CenterButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const LeftButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const RightButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.role === "user" ? "flex-start" : "flex-end"};
  align-items: center;
  width: 100%;
  margin: 5px 0;
`;

const ChakraMdOutlineVolumeUp = chakra(MdOutlineVolumeUp);
const BlinkingIcon = styled(ChakraMdOutlineVolumeUp)`
  animation: ${css`
    ${BlinkAnimation} 2s infinite
  `};
`;

const ChakraMdOutlineSmartToy = chakra(MdOutlineSmartToy);
const BlinkingIconAnotherType = styled(ChakraMdOutlineSmartToy)`
  animation: ${css`
    ${BlinkAnimation} 2s infinite
  `};
`;

interface BrickWallData {
  id: number;
  purpose: string;
  summarized_text?: string;
  chat_history: {
    messages: Array<{ role: string; content: string }>;
  };
  persona: {
    gender: string;
  };
}

interface DownloadChatHistoryButtonProps {
  brickWallId: number;
}

const BrickWall: React.FC = () => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunks = useRef<Blob[]>([]);
  const [brickWall, setBrickWall] = useState<BrickWallData | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [underGenProcess, setUnderGenProcess] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [instructionModal, setInstructionModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("まだ話さないでください");
  const toast = useToast();
  const { brickWallId } = useParams<{ brickWallId: string }>();
  const brickWallIdNumber = Number(brickWallId);
  const navigate = useNavigate();
  const { user } = useAuth();

  //必要情報のフェッチ
  const fetchBrickWall = async (id: number) => {
    try {
      const response = await apiClient.get(`/api/brick_walls/${id}/`);
      if (response.data.user !== user?.id) {
        toast({
          title: "アクセス拒否",
          description: "この壁打ちにはアクセスできません。",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        navigate("/");
      } else {
        setBrickWall(response.data);
      }
    } catch (error) {
      console.error("Error fetching brick wall:", error);
      toast({
        title: "エラー",
        description: "壁打ちの取得に失敗しました。",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (brickWallIdNumber) {
      fetchBrickWall(brickWallIdNumber);
    }
  }, [brickWallIdNumber]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [brickWall]);

  //音声のテキスト化
  const sendVoiceDataToAPI = async (blob: Blob) => {
    try {
      const formData = new FormData();
      if (brickWallId !== null) {
        const mimeType =
          recordedChunks.current && recordedChunks.current.length > 0
            ? recordedChunks.current[0].type
            : null;
        if (!mimeType) {
          console.error("MIME type is missing from the recorded chunks");
          return;
        }
        formData.append("audio", blob, "voice.webm");
        formData.append("brick_wall_pk", brickWallIdNumber.toString());
        formData.append("mime_type", mimeType);
      } else {
        console.error("brickWallId is null");
        return;
      }

      const response = await apiClient.post(
        "/api/brick_walls/voice/",
        formData,
        {
          headers: {
            ...apiClient.defaults.headers.common,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.status) {
        if (response.data.status === "brick_wall_updated_with_voice_text") {
          fetchBrickWall(brickWallIdNumber);
          await updateHistoryWithGPT(brickWallIdNumber);
        }
      }
    } catch (error) {
      console.error("Error sending voice data:", error);
      toast({
        title: "エラーが発生しました。",
        description:
          "発話データのデジタル化に失敗しました。60秒以内で話す内容をまとめ、周囲のノイズに注意して、もう一度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setUnderGenProcess(false);
    }
  };

  //返答の生成
  const updateHistoryWithGPT = async (brickWallId: number) => {
    try {
      const response = await apiClient.post(
        `/api/brick_walls/update_with_ai/`,
        { brick_wall_pk: brickWallId }
      );
      if (response.data && response.data.status === "success") {
        if (brickWallId) {
          fetchBrickWall(brickWallId);
        }
        const filteredContent = filterSystemText(
          response.data.generated_content
        );
        const gender =
          brickWall?.persona.gender === "female" ? "female" : "male";
        setIsSpeaking(true);
        speak(filteredContent, gender, () => setIsSpeaking(false));
        setUnderGenProcess(false);
      } else {
        // 必要に応じてエラーメッセージや処理を追加
        console.error("Failed to update history with GPT:", response.data);
        toast({
          title: "エラーが発生しました。",
          description:
            "返答の生成に失敗しました。何度かお試しいただき、解決しない場合にはサポートにお知らせください。",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        setUnderGenProcess(false);
      }
    } catch (error) {
      console.error("Error updating history with GPT:", error);
      toast({
        title: "エラーが発生しました。",
        description:
          "返答の生成に失敗しました。何度かお試しいただき、解決しない場合にはサポートにお知らせください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setUnderGenProcess(false);
    }
  };

  //録音処理
  const startRecording = async () => {
    setModalMessage("話してください");
    setInstructionModal(true);
    setTimeout(() => {
      setInstructionModal(false);
    }, 1000);

    const micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const mediaRecorder = new MediaRecorder(micStream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.start();
    setRecording(true);
  };

  const stopRecording = () => {
    setRecording(false);
    setUnderGenProcess(true);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunks.current);
        sendVoiceDataToAPI(blob);
        recordedChunks.current = [];
      };
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  const filterSystemText = (text: string) => {
    const systemTextStart = text.indexOf("#前述の文章に対して");

    if (systemTextStart !== -1) {
      return text.substring(0, systemTextStart).trim();
    }

    return text;
  };

  const handleBackClick = () => {
    navigate("/dashboard/?tab=personalist");
  };

  const handleSummarizeClick = async () => {
    // 要約処理のロジックをここに追加します
  };

  const handleDownload = async (brickWallId: number) => {
    try {
      const response = await apiClient.get(
        `/api/brick_walls/${brickWallId}/download/`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "text/plain" });
      saveAs(blob, `chat_history_${brickWallId}.txt`);
    } catch (error) {
      console.error("Error downloading chat history:", error);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      brickWall?.persona.gender === "female" ? womanAnimation : manAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container>
      <Layout>
        <LeftColumn>
          <ChatContainer ref={chatContainerRef}>
            {brickWall && brickWall.chat_history.messages.length > 1 ? (
              <VStack spacing={12} align="stretch">
                {brickWall.chat_history.messages
                  .slice(1)
                  .map((message, index) => {
                    const filteredContent = filterSystemText(message.content);
                    return message.role === "assistant" ? (
                      <MessageWrapper role="assistant" key={index}>
                        <AssistantMessage>
                          <p>{filteredContent}</p>
                        </AssistantMessage>
                      </MessageWrapper>
                    ) : null;
                  })}
              </VStack>
            ) : (
              <EmptyChatContainer>
                <Text fontSize="2xl" fontWeight="bold" color="gray.500">
                  発話して壁打ちをスタート
                </Text>
              </EmptyChatContainer>
            )}
          </ChatContainer>
        </LeftColumn>
        <RightColumn>
          <Flex direction="column" align="center">
            {isSpeaking && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <BlinkingIcon boxSize={16} mx="auto" />
                <StyledText fontWeight="bold">
                  返答中…。音声を聞いて下さい。
                </StyledText>
              </Box>
            )}
            {underGenProcess && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <BlinkingIconAnotherType boxSize={16} mx="auto" />
                <StyledText fontWeight="bold">考えています…。</StyledText>
              </Box>
            )}
            <Lottie
              options={defaultOptions}
              height="100%"
              width="160px"
              isStopped={!isSpeaking}
            />
          </Flex>
        </RightColumn>
      </Layout>

      <VoiceContainer>
        <LeftButtonWrapper>
          <Button
            onClick={handleBackClick}
            colorScheme="gray"
            p={4}
            isDisabled={isSpeaking || underGenProcess}
          >
            終了する
          </Button>
        </LeftButtonWrapper>
        <CenterButtonWrapper>
          {!recording && !underGenProcess ? (
            <Button
              mt={6}
              mb={6}
              p={6}
              colorScheme="blue"
              size="md"
              fontSize="20px"
              borderRadius="50"
              boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)"
              leftIcon={<BsFillMicFill size="24px" />}
              onClick={startRecording}
              isDisabled={isSpeaking || underGenProcess}
            >
              AI顧客に発話
            </Button>
          ) : underGenProcess ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CircularProgress isIndeterminate color="blue.400" size="32px" />
              <Text fontSize="20px" fontWeight="bold" marginTop="8px">
                AI思考中
              </Text>
            </Box>
          ) : (
            <Button
              mt={6}
              mb={6}
              p={6}
              colorScheme="red"
              size="md"
              fontSize="20px"
              borderRadius="50"
              boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)"
              leftIcon={<BsStopCircleFill size="24px" />}
              onClick={stopRecording}
            >
              発話終了
            </Button>
          )}
        </CenterButtonWrapper>
        <RightButtonWrapper>
          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="オプション"
                icon={<MdBuild size="24px" />}
                color="gray.700"
                backgroundColor="transparent"
                border="2px solid"
                borderColor="gray.700"
                borderRadius="full"
                _hover={{
                  backgroundColor: "gray.100",
                }}
                _active={{
                  backgroundColor: "gray.200",
                }}
                p={2}
                isDisabled={isSpeaking || underGenProcess}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text
                  onClick={() => handleDownload(brickWallIdNumber)}
                  cursor="pointer"
                  _hover={{ color: "blue.500" }}
                  mb={2}
                >
                  壁打ち内容ダウンロード
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </RightButtonWrapper>
      </VoiceContainer>

      <Modal
        isOpen={instructionModal}
        onClose={() => setInstructionModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={5}
          >
            <ModalBody>
              {modalMessage === "まだ話さないでください" ? (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={4}>
                    <BsFillSignStopFill size="100" color="#d9626d" />
                  </Box>
                  <StyledText color="#d9626d" fontWeight="bold">
                    {modalMessage}
                  </StyledText>
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={4}>
                    <FiCircle size="100" color="#6bd6c8" />
                  </Box>
                  <StyledText color="#6bd6c8" fontWeight="bold">
                    {modalMessage}
                  </StyledText>
                </Flex>
              )}
            </ModalBody>
          </Flex>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default BrickWall;
