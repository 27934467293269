/** @format */

import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import { EditorContent, useEditor } from "@tiptap/react";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Placeholder from "@tiptap/extension-placeholder";
import {
  FaBold,
  FaItalic,
  FaQuoteRight,
  FaCode,
  FaListUl,
  FaListOl,
  FaUndo,
  FaRedo,
  FaFillDrip,
  FaRegSave,
} from "react-icons/fa";
import apiClient from "./apiClient";
import { useViewMode } from "./ViewModeContext";

const StyledEditorContent = styled(EditorContent)`
  .ProseMirror {
    min-height: 280px;
    padding: 6px;
    cursor: text;
    border-radius: 6px;

    h1 {
      font-size: 1.2em;
      color: #333;
      border-bottom: 2px solid #ddd;
      padding-bottom: 4px;
      margin-top: 2px;
      margin-bottom: 10px;
      line-height: 1.5em;
      position: relative;
    }

    h1[data-placeholder]:before {
      content: attr(data-placeholder);
      color: #aaa;
      pointer-events: none;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 1.5em;
    }

    a {
      color: blue;
      text-decoration: underline;
      target: _blank;
    }

    blockquote {
      border-left: 4px solid #ddd;
      padding-left: 1em;
      color: #777;
      font-style: italic; // 引用のスタイル
    }

    pre {
      background-color: black;
      padding: 6px;
      border-radius: 6px;
      overflow-x: auto;

      code {
        display: block;
        color: white;
        font-family: "Courier New", monospace;
        font-size: 0.8em;
      }
    }
    ol {
      margin-left: 20px;
      padding-left: 0;

      li {
        margin-bottom: 0.4em;
      }
    }

    /* 通常リストのスタイリング */
    ul {
      margin-left: 18px;
      padding-left: 0;

      li {
        margin-bottom: 0.4em;
      }
    }
  }
`;

const ButtonStyle = styled.button`
  background-color: white;
  color: #333;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 4px;
  transition: all 0.3s ease-in-out;

  &.is-active {
    background-color: #333;
    color: white;
  }

  &:hover {
    background-color: #e9e9e9;
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #b3b3b3;
    border: 1px solid #b3b3b3;
    cursor: not-allowed;
  }
`;

const NotificationStyle = styled.div<NotificationProps>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  color: #fff;
  font-size: 0.75rem;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 999;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(${(props) => (props.$isVisible ? "0" : "50px")});
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
`;

interface TiptapEditorProps {
  initialContent?: string;
  nodeId: string;
  talkScriptId: number;
  id?: string;
}

interface NotificationProps {
  $isVisible: boolean;
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({
  initialContent,
  nodeId,
  talkScriptId,
}) => {
  const { viewMode } = useViewMode();
  const [isChanged, setIsChanged] = useState(false);
  const [currentContent, setCurrentContent] = useState(initialContent || "");
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const CustomDocument = Document.extend({
    content: "heading block*",
  });

  const editor = useEditor({
    extensions: [
      CustomDocument,
      Link,
      TextStyle,
      StarterKit.configure({
        document: false,
      }),
      Color,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading" && node.attrs.level === 1) {
            if (!initialContent || initialContent === "<h1></h1>") {
              return "最初に見出しを入力しましょう";
            }
          }
          return "";
        },
        showOnlyCurrent: true,
        showOnlyWhenEditable: true,
      }),
    ],
    content: initialContent || "",
    onUpdate: ({ editor }) => {
      if (!editor.getHTML().startsWith("<h1>")) {
        editor.commands.clearContent();
        editor.chain().focus().setHeading({ level: 1 }).run();
      }
    },
  });

  const editorRef = useRef<HTMLDivElement>(null);

  const isColorActive = (color: string) => {
    if (!editor) return;
    const { state } = editor;
    const { from, to } = state.selection;
    let isActive = false;

    state.doc.nodesBetween(from, to, (node) => {
      const marks = node.marks;
      for (let mark of marks) {
        if (mark.type.name === "textStyle" && mark.attrs.color === color) {
          isActive = true;
        }
      }
    });

    return isActive;
  };

  useEffect(() => {
    if (editor) {
      if (viewMode) {
        editor.setOptions({ editable: false });
      } else {
        editor.setOptions({ editable: true });
      }
    }
  }, [viewMode, editor]);

  // エディタの内容が変更されたかどうかを監視するeffect
  useEffect(() => {
    if (editor) {
      editor.on("update", ({ editor }) => {
        const newContent = editor.getHTML();
        if (newContent !== currentContent) {
          setIsChanged(true);
          setCurrentContent(newContent);
        }
      });
    }
  }, [editor]);

  const handleSaveTrigger = useCallback(() => {
    if (isChanged) {
      apiClient
        .put(`/api/talks/${nodeId}/`, {
          content_wysiwyg: currentContent,
          talk_script: talkScriptId,
        })
        .then(() => {
          setIsChanged(false);
          setSaveStatus("saved");
          setTimeout(() => setSaveStatus("idle"), 2000);
        })
        .catch(() => {
          setSaveStatus("error");
          setTimeout(() => setSaveStatus("idle"), 2000);
        });
    }
  }, [isChanged, currentContent, nodeId, talkScriptId]);

  // Enterキーやエディタ外のクリックを検知するeffect
  useEffect(() => {
    const handleGlobalSaveTrigger = (event: KeyboardEvent | MouseEvent) => {
      if (event instanceof KeyboardEvent && event.key !== "Enter") return;
      if (
        event instanceof MouseEvent &&
        editorRef.current?.contains(event.target as Node)
      )
        return;

      handleSaveTrigger();
    };

    document.addEventListener("keydown", handleGlobalSaveTrigger);
    document.addEventListener("mousedown", handleGlobalSaveTrigger);

    return () => {
      document.removeEventListener("keydown", handleGlobalSaveTrigger);
      document.removeEventListener("mousedown", handleGlobalSaveTrigger);
    };
  }, [handleSaveTrigger]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (isChanged) {
        e.preventDefault();
        e.returnValue =
          "変更が保存されていません。このページから移動してもよろしいですか？";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChanged]);

  return (
    <div
      ref={editorRef}
      style={{
        position: "relative",
      }}
    >
      {editor && !viewMode && (
        <div
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            display: "flex",
            gap: "7px",
            marginBottom: "8px",
            marginTop: "8px",
            justifyContent: "center",
          }}
        >
          <ButtonStyle
            onClick={() => {
              if (isColorActive("#e86b6b")) {
                editor.chain().focus().unsetColor().run();
              } else {
                editor.chain().focus().setColor("#e86b6b").run();
              }
            }}
            className={isColorActive("#e86b6b") ? "is-active" : ""}
          >
            <FaFillDrip />
          </ButtonStyle>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            <FaBold />
          </ButtonStyle>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            <FaItalic />
          </ButtonStyle>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            <FaListUl />
          </ButtonStyle>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "is-active" : ""}
          >
            <FaListOl />
          </ButtonStyle>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={editor.isActive("codeBlock") ? "is-active" : ""}
          >
            <FaCode />
          </ButtonStyle>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={editor.isActive("blockquote") ? "is-active" : ""}
          >
            <FaQuoteRight />
          </ButtonStyle>
          <ButtonStyle onClick={() => editor.chain().focus().undo().run()}>
            <FaUndo />
          </ButtonStyle>
          <ButtonStyle onClick={() => editor.chain().focus().redo().run()}>
            <FaRedo />
          </ButtonStyle>
          <ButtonStyle
            onClick={handleSaveTrigger}
            className={isChanged ? "is-active" : ""}
          >
            <FaRegSave />
          </ButtonStyle>
        </div>
      )}
      <StyledEditorContent editor={editor} id="talk-content-label" />
      <>
        <NotificationStyle $isVisible={saveStatus === "saved"}>
          保存に成功しました
        </NotificationStyle>
        <NotificationStyle $isVisible={saveStatus === "error"}>
          保存に失敗しました
        </NotificationStyle>
      </>
    </div>
  );
};

export default TiptapEditor;
