import { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";

const ShareRedirect: React.FC = () => {
  const { token } = useParams();
  const { isAuthenticated } = useAuth();
  const [talkScriptId, setTalkScriptId] = useState<number | null>(null);
  const { setLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    // apiClientを使用してAPIを呼び出します
    apiClient
      .get(`api/talk_scripts/get_id_from_token/?token=${token}`)
      .then((response) => {
        setTalkScriptId(response.data.talk_script_id);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    setLoading(true);
    if (talkScriptId) {
      if (!isAuthenticated) {
        sessionStorage.setItem("redirectTo", `/script/${talkScriptId}`);
        navigate("/login");
      } else {
        navigate(`/script/${talkScriptId}`);
      }
    }
    setLoading(false);
  }, [talkScriptId, isAuthenticated, navigate]);
  return null;
};

export default ShareRedirect;
