/** @format */

import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";
import { ListBox } from "primereact/listbox";
import { OrganizationChart } from "primereact/organizationchart";
import styled, { css } from "styled-components";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import {
  Box,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  TableContainer,
  useToast,
  Button,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { FaTrash } from "react-icons/fa";
import { BsArrowLeftCircleFill, BsChatDotsFill } from "react-icons/bs";
import { MdHearing } from "react-icons/md";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";
import { useViewMode } from "./ViewModeContext";

interface NodeContentProps {
  clickable?: boolean;
  isTop?: boolean;
}

const StyledListBox = styled.div`
  .p-listbox {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const NodeContent = styled.div.attrs<{ clickable?: boolean; isTop?: boolean }>(
  (props) => {
    // clickable と isTop を分割代入で取り出し、残りの props を filteredProps として扱う
    const { clickable, isTop, ...filteredProps } = props;
    return filteredProps;
  }
)<NodeContentProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  min-width: 200px;
  padding: 10px;
  text-align: left;
  box-shadow: ${({ isTop }) =>
    isTop ? "none" : "0 4px 6px rgba(0, 0, 0, 0.2)"};
  background-color: ${({ isTop }) => (!isTop ? "#f1f1fb;" : "white")};
  border-radius: ${({ isTop }) => (!isTop ? "10px" : 0)};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  border-bottom: ${({ isTop }) => (isTop ? "4px solid black" : "none")};

  &:hover {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  & > div {
    margin-bottom: 8px; // div間のマージン
    width: 100%;

    &:last-child {
      margin-bottom: 0; // 最後のdivにはマージンを適用しない
    }
  }

  & > div:first-child {
    display: ${({ isTop }) => (isTop ? "none" : "block")};
    text-align: left;
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
`;

const HearingToTalkLinkDetailStyled = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 62px;
`;

// アイコン用のスタイル付きコンポーネントを作成
const StyledBackIcon = styled(BsArrowLeftCircleFill)`
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 10;
  font-size: 36px;
  cursor: pointer;
  color: #454545;
`;

const OrganizationChartContainer = styled.div`
  .p-organizationchart {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
  }

  .p-organizationchart-table {
    width: auto;
  }

  .p-organizationchart-line-down,
  .p-organizationchart-line-right,
  .p-organizationchart-line-left,
  .p-organizationchart-line-top {
    display: none !important;
  }

  .p-organizationchart-lines {
    height: 20px;
  }
`;

const StyledIconContainer = styled.div`
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
`;

interface ComponentProps {
  talkScriptId: string;
  onTalkSelected: (talk: any) => void;
}

interface User {
  id: number;
  username: string;
}

interface Talk {
  id: number;
  title: string | null;
  position_x: number;
  position_y: number;
}

interface Hearing {
  id: number;
  hearing_text: string;
  phase: "setup" | "hearing" | "presentation" | "closing";
  talk_script: number;
  user: User;
}

interface HearingToTalkLink {
  id: number;
  hearing: number;
  talk: Talk | null;
  socket_hearing: Hearing | null;
  condition_text: string;
  link_type: "talk" | "hearing";
  talk_script: number;
  user: User;
}

interface SerializedNode {
  label: string;
  className: string;
  expanded?: boolean;
  children?: SerializedNode[];
  data?: {
    edge_text?: string;
    link_type?: string;
    link_id?: number;
    is_top?: boolean;
  };
}

const phaseColors = {
  setup: "green",
  hearing: "blue",
  presentation: "orange",
  closing: "red",
};

const phaseOrder = {
  setup: 1,
  hearing: 2,
  presentation: 3,
  closing: 4,
};

const phaseDisplayNames = {
  setup: "準備",
  hearing: "調査",
  presentation: "提案",
  closing: "クロージング",
};

export const Hearing: React.FC<ComponentProps> = ({
  talkScriptId,
  onTalkSelected,
}) => {
  const [hearings, setHearings] = useState<Hearing[]>([]);
  const [hearingToTalkLinks, setHearingToTalkLinks] = useState<
    HearingToTalkLink[]
  >([]);
  const { setLoading } = useLoading();
  const [phase, setPhase] = useState("setup");
  const [hearingText, setHearingText] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingHearing, setEditingHearing] = useState<Hearing | null>(null);
  const [currentView, setCurrentView] = useState<
    "hearingsList" | "hearingDetail"
  >("hearingsList");
  const { viewMode } = useViewMode();
  const { user } = useAuth();
  const toast = useToast();
  const {
    isOpen: isLinkCreateModalOpen,
    onOpen: onLinkCreateModalOpen,
    onClose: onLinkCreateModalClose,
  } = useDisclosure();
  const {
    isOpen: isHearingModalOpen,
    onOpen: onHearingModalOpen,
    onClose: onHearingModalClose,
  } = useDisclosure();

  const {
    isOpen: isEditLinkModalOpen,
    onOpen: onEditLinkModalOpen,
    onClose: onEditLinkModalClose,
  } = useDisclosure();

  const [linkType, setLinkType] = useState<"talk" | "hearing">("hearing");
  const [selectedHearing, setSelectedHearing] = useState<Hearing | null>(null);
  const [selectedTalk, setSelectedTalk] = useState<Talk | null>(null);
  const [filteredTalks, setFilteredTalks] = useState<Talk[]>([]);
  const [selectedSocketHearing, setSelectedSocketHearing] =
    useState<Hearing | null>(null);
  const [conditionText, setConditionText] = useState("");
  const [chartData, setChartData] = useState<SerializedNode[]>([]);

  // サーバからHearing情報をフェッチするロジック
  const fetchHearings = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/hearings/${talkScriptId}/`);

      const sortedHearings = response.data.sort(
        (a: Hearing, b: Hearing) => phaseOrder[a.phase] - phaseOrder[b.phase]
      );
      setHearings(sortedHearings);
    } catch (error) {
      console.error("Hearingsのフェッチ中にエラーが発生しました", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHearingToTalkLinks = async (
    hearingId: number
  ): Promise<HearingToTalkLink[]> => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/hearings/links/${hearingId}/`);
      setHearingToTalkLinks(response.data);
      return response.data;
    } catch (error) {
      console.error("HearingToTalkLinkの取得中にエラーが発生しました", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // サーバからTalk情報をフェッチする関数
  const fetchTalks = async (talkScriptId: number) => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/talks/`, {
        params: { talk_script_id: talkScriptId },
      });
      setFilteredTalks(response.data);
    } catch (error) {
      console.error("Talksのフェッチ中にエラーが発生しました", error);
      toast({
        title: "データの取得に失敗しました",
        description: "トークデータの取得中に問題が発生しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHearings();
  }, [talkScriptId]);

  const handleHearingSubmit = async () => {
    try {
      // POSTリクエストの本文に、新しいHearingオブジェクトのデータを指定
      setLoading(true);
      const payload = {
        phase,
        hearing_text: hearingText,
        talk_script: talkScriptId,
      };

      // APIへのPOSTリクエストを送信
      await apiClient.post("/api/hearings/", payload);

      toast({
        title: "登録成功",
        description: "新しいヒアリングが登録されました。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onHearingModalClose();
      setHearingText("");
      setPhase("setup");
      fetchHearings();
    } catch (error) {
      toast({
        title: "登録失敗",
        description: "ヒアリングの登録に失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Hearingの登録中にエラーが発生しました", error);
    } finally {
      setLoading(false);
    }
  };

  // ヒアリングの削除機能
  const handleDeleteHearing = async (hearingId: number) => {
    if (window.confirm("本当に削除しますか？")) {
      setLoading(true);
      try {
        await apiClient.delete(`/api/hearings/delete/${hearingId}/`);
        toast({
          title: "削除成功",
          description: "ヒアリングが削除されました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchHearings();
      } catch (error) {
        toast({
          title: "削除失敗",
          description: "ヒアリングの削除に失敗しました。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditHearingSubmit = async () => {
    if (!editingHearing) return;

    const updateData = {
      hearing_text: hearingText,
      phase: phase,
    };

    try {
      setLoading(true);
      await apiClient.patch(
        `/api/hearings/update/${editingHearing.id}/`,
        updateData
      );

      toast({
        title: "編集成功",
        description: "ヒアリングが更新されました。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchHearings();
      onHearingModalClose();
    } catch (error) {
      toast({
        title: "編集失敗",
        description: "ヒアリングの更新に失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Hearingの編集中にエラーが発生しました", error);
    } finally {
      setLoading(false);
    }
  };

  // 新規作成用のモーダルを開く関数
  const openNewHearingModal = () => {
    setIsEditMode(false);
    setEditingHearing(null);
    setPhase("setup");
    setHearingText("");
    onHearingModalOpen();
  };

  // 編集用のモーダルを開く関数
  const openEditHearingModal = (hearing: Hearing) => {
    setIsEditMode(true);
    setEditingHearing(hearing);
    setPhase(hearing.phase);
    setHearingText(hearing.hearing_text);
    onHearingModalOpen();
  };

  const onLinkCreate = async () => {
    const numericTalkScriptId = Number(talkScriptId);
    if (!isNaN(numericTalkScriptId)) {
      await fetchTalks(numericTalkScriptId);
      onLinkCreateModalOpen();
    } else {
      console.error("Invalid talkScriptId:", talkScriptId);
    }
  };

  const handleHearingClick = async (hearingId: number) => {
    try {
      setLoading(true);
      const links = await fetchHearingToTalkLinks(hearingId);
      const serializedData = serializeDataForOrgChart(links, hearingId);
      if (serializedData.children.length === 0) {
        toast({
          title: "遷移先が未設定",
          description:
            "このヒアリングは遷移先が設定されておりません。遷移設定より遷移を設定してください。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      apiClient.post("/api/cohort_analysis_logs/create_log/", {
        company: user?.company?.id,
        event_type: "hearing",
        additional_info: {
          hearing_id: hearingId,
        },
      });
      setChartData([serializedData]);
      setCurrentView("hearingDetail");
    } catch (error) {
      console.error("データの取得に失敗しました", error);
      toast({
        title: "データの取得に失敗しました",
        description: "遷移情報の取得中に問題が発生しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBackToHearingsList = () => {
    setCurrentView("hearingsList");
  };

  const handleLinkTypeChange = (nextValue: string) => {
    setLinkType(nextValue as "talk" | "hearing");
  };

  const truncateText = (
    text: string | null,
    maxLength: number = 28
  ): string => {
    if (!text) return "ノータイトル";
    return text.length > maxLength ? `${text.substring(0, maxLength)}…` : text;
  };

  // リンク作成処理前に呼ばれるバリデーション関数
  const validateForm = () => {
    // リンクタイプ、遷移元ヒアリング、遷移条件の必須チェック
    if (!linkType || !selectedHearing || !conditionText.trim()) {
      toast({
        title: "エラー",
        description: "リンクタイプ、遷移元ヒアリング、遷移条件は必須です",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    // 遷移先トークまたは遷移先ヒアリングが選択されていない場合のチェック
    if (linkType === "talk" && !selectedTalk) {
      toast({
        title: "エラー",
        description: "遷移先のトークを選択してください",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    } else if (linkType === "hearing" && !selectedSocketHearing) {
      toast({
        title: "エラー",
        description: "遷移先のヒアリングを選択してください",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const handleCreateLink = async () => {
    if (!validateForm()) {
      return;
    }

    interface LinkRequestBody {
      hearing: number | undefined;
      condition_text: string;
      link_type: string;
      talk_script: number;
      talk_id?: number;
      socket_hearing_id?: number;
    }

    // リクエストボディの構築
    let requestBody: LinkRequestBody = {
      hearing: selectedHearing?.id,
      condition_text: conditionText,
      link_type: linkType,
      talk_script: Number(talkScriptId),
    };

    if (linkType === "talk" && selectedTalk) {
      requestBody = { ...requestBody, talk_id: selectedTalk?.id };
    } else if (linkType === "hearing" && selectedSocketHearing) {
      requestBody = {
        ...requestBody,
        socket_hearing_id: selectedSocketHearing?.id,
      };
    } else {
      toast({
        title: "エラー",
        description: "必要な情報が揃っていません。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setLoading(true);
      // APIへのPOSTリクエストを送信
      await apiClient.post("/api/hearings/link/", requestBody);
      // 成功した場合の処理
      toast({
        title: "リンク作成成功",
        description: "ヒアリングリンクが正常に作成されました。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      if (selectedHearing?.id) {
        fetchHearingToTalkLinks(selectedHearing.id);
      }
      setSelectedHearing(null);
      setSelectedTalk(null);
      setSelectedSocketHearing(null);
      setConditionText("");
      setLinkType("hearing");
      onLinkCreateModalClose();
    } catch (error: unknown) {
      console.error("リンク作成中にエラーが発生しました", error);
      // AxiosError型であるかを確認
      if (error instanceof AxiosError) {
        // エラーレスポンスが存在する場合、その内容をログに出力
        if (error.response) {
          console.log("Error response:", error.response.data);
        } else {
          console.log("Error message:", error.message);
        }
      } else {
        // それ以外のエラーの場合
        console.log("An unexpected error occurred:", error);
      }
      toast({
        title: "リンク作成失敗",
        description: "ヒアリングリンクの作成中に問題が発生しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // 遷移情報編集モーダルを開く関数（引数にはヒアリングのIDを取る）
  const openEditLinkModal = async (hearingId: number) => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/hearings/links/${hearingId}/`);
      // レスポンスデータをhearingToTalkLinks状態に格納
      setHearingToTalkLinks(response.data);
      onEditLinkModalOpen();
    } catch (error) {
      console.error("HearingToTalkLinkの取得中にエラーが発生しました", error);
      toast({
        title: "データの取得に失敗しました",
        description: "遷移情報の取得中に問題が発生しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLink = async (linkId: number) => {
    if (window.confirm("この遷移情報を削除してもよろしいですか？")) {
      try {
        setLoading(true);
        await apiClient.delete(`/api/hearings/link/delete/${linkId}/`);
        toast({
          title: "削除成功",
          description: "遷移情報が削除されました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // 削除後の遷移情報リストを再取得または更新
        const updatedLinks = hearingToTalkLinks.filter(
          (link) => link.id !== linkId
        );
        setHearingToTalkLinks(updatedLinks);
      } catch (error) {
        console.error("遷移情報の削除中にエラーが発生しました", error);
        toast({
          title: "削除失敗",
          description: "遷移情報の削除に失敗しました。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const truncate = (text: string | null, length = 20) => {
    if (!text) return "ノータイトル";
    return text.length > length ? `${text.substring(0, length)}...` : text;
  };

  const serializeDataForOrgChart = (
    links: HearingToTalkLink[],
    currentHearingId: number
  ) => {
    const fetchedHearing = hearings.find(
      (hearing) => hearing.id === currentHearingId
    );
    const children = links
      .filter((link) => {
        return link.hearing === currentHearingId;
      })
      .map((link) => ({
        label:
          link.link_type === "hearing"
            ? link.socket_hearing?.hearing_text ?? "未入力"
            : link.talk?.title ?? "ノータイトル",
        className: link.link_type === "hearing" ? "hearing-node" : "talk-node",
        expanded: true,
        children: [],
        data: {
          edge_text: link.condition_text,
          link_type: link.link_type,
          link_id:
            link.link_type === "talk" ? link.talk?.id : link.socket_hearing?.id,
        },
      }));

    // 親ノードの設定
    const parentNode = {
      label: fetchedHearing?.hearing_text ?? "未入力",
      className: "hearing-node",
      expanded: true,
      children: children,
      data: { is_top: true },
    };

    return parentNode;
  };

  // トーククリック時のハンドラー
  const handleTalkClick = async (talkId: number) => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/talks/${talkId}/`);
      const talk = response.data;
      onTalkSelected(talk);
    } catch (error) {
      toast({
        title: "エラー",
        description:
          "トークが見つかりません。おそらく削除された可能性があります。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // OrganizationChartのNodeテンプレート
  const nodeTemplate = (node: SerializedNode) => {
    const isTop = node.data?.is_top ?? false;
    const iconColor = node.className === "hearing-node" ? "blue" : "red";

    // ノードクリック時の挙動を定義
    const handleNodeClick = () => {
      if (isTop) {
        // is_topがtrueの場合は何もしない
        return;
      }
      const linkId = node.data?.link_id;
      if (typeof linkId === "number") {
        // link_idがnumber型の時のみ実行
        if (node.data?.link_type === "hearing") {
          // link_typeが"hearing"の場合、handleHearingClickを実行
          handleHearingClick(linkId);
        } else if (node.data?.link_type === "talk") {
          // link_typeが"talk"の場合、handleTalkClickを実行
          handleTalkClick(linkId);
        }
      }
    };

    return (
      <NodeContent clickable={!isTop} isTop={isTop} onClick={handleNodeClick}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
          }}
        >
          {!isTop && (
            <div
              style={{
                width: "100%",
                padding: "6px",
                backgroundColor: "white",
                borderBottom: "2px solid #e0e0e0",
              }}
            >
              {node.className === "hearing-node" ? (
                <StyledIconContainer>
                  <MdHearing color="#5987e3" size="20px" />
                </StyledIconContainer>
              ) : (
                <StyledIconContainer>
                  <BsChatDotsFill size="18px" color="#e06987" />
                </StyledIconContainer>
              )}
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "12px",
                  color:
                    node.className === "hearing-node" ? "#5987e3" : "#e06987",
                }}
              >
                <Badge size="sm" colorScheme={iconColor} mr={1}>
                  遷移条件
                </Badge>
                {node.data?.edge_text}
              </span>
            </div>
          )}
        </div>
        <ContentWrapper>
          <span style={{ color: "black", fontSize: "16px" }}>{node.label}</span>
        </ContentWrapper>
      </NodeContent>
    );
  };

  const HearingToTalkLinkDetail = () => {
    return (
      <HearingToTalkLinkDetailStyled>
        <Heading size="lg" fontWeight="bold" mb={2}>
          ヒアリングからの遷移
        </Heading>
        <StyledBackIcon onClick={() => handleBackToHearingsList()} />
        <OrganizationChartContainer>
          <OrganizationChart value={chartData} nodeTemplate={nodeTemplate} />
        </OrganizationChartContainer>
      </HearingToTalkLinkDetailStyled>
    );
  };

  const EditLinkModal = () => (
    <Modal
      isOpen={isEditLinkModalOpen}
      onClose={onEditLinkModalClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>遷移情報編集</ModalHeader>

        <ModalBody>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>遷移先</Th>
                  <Th>遷移条件</Th>
                  <Th>操作</Th>
                </Tr>
              </Thead>
              <Tbody>
                {hearingToTalkLinks.map((link) => (
                  <Tr key={link.id}>
                    <Td>
                      {truncate(
                        link.socket_hearing
                          ? link.socket_hearing.hearing_text
                          : link.talk && link.talk.title
                          ? link.talk.title
                          : "ノータイトル"
                      )}
                    </Td>
                    <Td>{truncate(link.condition_text)}</Td>
                    <Td>
                      <IconButton
                        aria-label="Delete link"
                        icon={<FaTrash />}
                        size="sm"
                        color="red.500"
                        variant="ghost"
                        onClick={() => {
                          handleDeleteLink(link.id);
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onEditLinkModalClose}>閉じる</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const NoHearingsContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="380px"
        width="100%"
        textAlign="center"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={8}
          mt={2}
        ></Flex>
        <Text fontSize="xl" fontWeight="bold" marginBottom="6">
          ヒアリングは未登録です
        </Text>
        {!viewMode && (
          <Text color="gray.600" marginBottom="2">
            ヒアリング項目を登録しましょう（複数登録可）
          </Text>
        )}
        {!viewMode && (
          <Button onClick={openNewHearingModal} colorScheme="purple">
            ヒアリング登録
          </Button>
        )}
      </Box>
    );
  };

  const HearingContent = () => {
    return (
      <>
        <Flex justifyContent="space-between" alignItems="center" mb={8} mt={2}>
          <Heading size="lg" fontWeight="bold" mr={4}>
            ヒアリングリスト
          </Heading>
          {!viewMode && (
            <Flex>
              <Button onClick={onLinkCreate} colorScheme="blue" mr={4}>
                遷移設定
              </Button>
              <Button onClick={openNewHearingModal} colorScheme="purple">
                ヒアリング登録
              </Button>
            </Flex>
          )}
        </Flex>

        <TableContainer height="100%" overflowY="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width="12%">段階</Th>
                <Th width="80%">ヒアリング内容</Th>
                {!viewMode && <Th width="8%">操作</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {hearings.map((hearing) => (
                <Tr key={hearing.id}>
                  <Td>
                    <Badge size="sm" colorScheme={phaseColors[hearing.phase]}>
                      {phaseDisplayNames[hearing.phase]}
                    </Badge>
                  </Td>
                  <Td
                    fontSize="sm"
                    padding="6"
                    onClick={() => handleHearingClick(hearing.id)}
                    cursor="pointer"
                    _hover={{ bg: "gray.100" }}
                  >
                    {hearing.hearing_text}
                  </Td>
                  {!viewMode && (
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<EditIcon />}
                          variant="outline"
                          isDisabled={hearing.user.id !== user?.id}
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() => openEditLinkModal(hearing.id)}
                          >
                            遷移情報編集
                          </MenuItem>
                          <MenuItem
                            onClick={() => openEditHearingModal(hearing)}
                          >
                            ヒアリング編集
                          </MenuItem>
                          <MenuItem
                            color="#d94666"
                            onClick={() => handleDeleteHearing(hearing.id)}
                          >
                            削除
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Box height="100%" margin="18px">
      {hearings.length === 0 ? (
        <NoHearingsContent />
      ) : currentView === "hearingsList" ? (
        <HearingContent />
      ) : currentView === "hearingDetail" ? (
        <HearingToTalkLinkDetail />
      ) : null}
      <Modal
        isOpen={isHearingModalOpen}
        onClose={onHearingModalClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditMode ? "ヒアリング編集" : "ヒアリング登録"}
          </ModalHeader>
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel fontWeight="bold">段階</FormLabel>
              <RadioGroup onChange={setPhase} value={phase}>
                <Stack direction="row">
                  <Radio value="setup">準備</Radio>
                  <Radio value="hearing">調査</Radio>
                  <Radio value="presentation">提案</Radio>
                  <Radio value="closing">クロージング</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <FormControl mt={6}>
              <FormLabel fontWeight="bold">ヒアリング内容</FormLabel>
              <Textarea
                value={hearingText}
                size="lg"
                height="150px"
                onChange={(e) => setHearingText(e.target.value)}
                placeholder="例：予算はどのくらいをお考えでしょうか？"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={
                isEditMode ? handleEditHearingSubmit : handleHearingSubmit
              }
            >
              {isEditMode ? "更新する" : "登録する"}
            </Button>
            <Button onClick={onHearingModalClose}>キャンセル</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isLinkCreateModalOpen}
        onClose={onLinkCreateModalClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent maxW="600px" minW="360px">
          <ModalHeader>遷移設定</ModalHeader>
          <Text px={4} py={1} fontSize="sm" color="gray.600" fontWeight="bold">
            ヒアリングからトークやヒアリングへの遷移を設定できます。
          </Text>
          <ModalBody pb={4}>
            <Stack spacing={4}>
              <FormControl mt={4} mb={2}>
                <FormLabel fontWeight="bold">リンクタイプ</FormLabel>
                <RadioGroup onChange={handleLinkTypeChange} value={linkType}>
                  <Stack direction="row">
                    <Radio value="hearing">ヒアリング</Radio>
                    <Radio value="talk">トーク</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Box display="flex" justifyContent="center" gap="10">
                <Box flex="1" maxW="240px" minW="160px">
                  <FormLabel fontWeight="bold">遷移元のヒアリング</FormLabel>
                  <StyledListBox>
                    <ListBox
                      filter
                      value={selectedHearing}
                      options={hearings.map((hearing) => ({
                        ...hearing,
                        hearing_text: truncateText(hearing.hearing_text),
                      }))}
                      onChange={(e) => setSelectedHearing(e.value)}
                      optionLabel="hearing_text"
                      className="w-full"
                      listStyle={{
                        maxHeight: "calc(2.25rem * 6)",
                        overflowY: "auto",
                      }}
                    />
                  </StyledListBox>
                </Box>

                {linkType === "hearing" && (
                  <Box flex="1" maxW="240px" minW="160px">
                    <FormLabel fontWeight="bold">遷移先のヒアリング</FormLabel>
                    <StyledListBox>
                      <ListBox
                        filter
                        value={selectedSocketHearing}
                        options={hearings.map((hearing) => ({
                          ...hearing,
                          hearing_text: truncateText(hearing.hearing_text),
                        }))}
                        onChange={(e) => setSelectedSocketHearing(e.value)}
                        optionLabel="hearing_text"
                        className="w-full"
                        listStyle={{
                          maxHeight: "calc(2.25rem * 6)",
                          overflowY: "auto",
                        }}
                      />
                    </StyledListBox>
                  </Box>
                )}

                {linkType === "talk" && (
                  <Box flex="1" maxW="240px" minW="160px">
                    <FormLabel fontWeight="bold">遷移先のトーク</FormLabel>
                    <StyledListBox>
                      <ListBox
                        filter
                        value={selectedTalk}
                        options={filteredTalks.map((talk) => ({
                          ...talk,
                          title: truncateText(talk.title),
                        }))}
                        onChange={(e) => setSelectedTalk(e.value)}
                        optionLabel="title"
                        className="w-full"
                        listStyle={{
                          maxHeight: "calc(2.25rem * 6)",
                          overflowY: "auto",
                        }}
                      />
                    </StyledListBox>
                  </Box>
                )}
              </Box>

              <FormControl mt={6}>
                <FormLabel fontWeight="bold">遷移条件</FormLabel>
                <Textarea
                  value={conditionText}
                  onChange={(e) => setConditionText(e.target.value)}
                  placeholder="例：「はい」と答えた場合"
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateLink}>
              作成する
            </Button>
            <Button onClick={onLinkCreateModalClose}>キャンセル</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <EditLinkModal />
    </Box>
  );
};
