/** @format */

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  ButtonGroup,
  Alert,
  AlertIcon,
  AlertDescription,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";

interface EdgeUpdatePopupProps {
  isOpen: boolean;
  onClose: () => void;
  edgeId: string;
  deletable: boolean;
  fetchTalks: () => void;
}

const EdgeUpdatePopup: React.FC<EdgeUpdatePopupProps> = ({
  isOpen,
  onClose,
  edgeId,
  deletable,
  fetchTalks,
}) => {
  const [label, setLabel] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { setLoading } = useLoading();
  const toast = useToast();

  const handleLabelChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel(event.target.value);
  };

  const handleSave = async () => {
    if (!label.trim()) {
      setShowAlert(true);
      return;
    }
    try {
      setLoading(true);
      await apiClient.patch(`/api/talks/edges/${edgeId}/`, { label: label });
      toast({
        title: "ラベルテキストを更新しました。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating edge label:", error);
      toast({
        title: "ラベルテキストの更新に失敗しました。",
        description: "再試行してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleDelete = async () => {
    // ユーザーに確認を求めるプロンプトを表示
    const isConfirmed = window.confirm("本当に削除してよろしいですか?");

    // ユーザーがOKをクリックした場合のみ削除を実行
    if (isConfirmed) {
      try {
        setLoading(true);
        await apiClient.delete(`/api/talks/edges/${edgeId}/`);
        toast({
          title: "削除しました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchTalks();
      } catch (error) {
        console.error("Error deleting edge:", error);
        toast({
          title: "削除に失敗しました。",
          description: "再試行してください。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };

  useEffect(() => {
    const fetchEdgeData = async () => {
      try {
        const response = await apiClient.get(`/api/talks/edges/${edgeId}/`);
        setLabel(response.data.label);
      } catch (error) {
        console.error("Error fetching edge data:", error);
      }
    };

    if (isOpen) {
      fetchEdgeData();
    }
  }, [isOpen, edgeId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>次のスクリプトへ進む条件</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {showAlert && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              <AlertDescription>
                ラベルテキストに空白は設定できません
              </AlertDescription>
            </Alert>
          )}
          <FormControl>
            <FormLabel>簡潔に入力してください</FormLabel>
            <Textarea value={label} onChange={handleLabelChange} />
          </FormControl>
          <ButtonGroup spacing={4} mt={4}>
            <Button
              backgroundColor="#59bfd4"
              color="white"
              _hover={{ opacity: 0.7 }}
              onClick={handleSave}
            >
              更新
            </Button>
            {deletable && (
              <Button
                backgroundColor="#de717e"
                color="white"
                _hover={{ opacity: 0.7 }}
                onClick={handleDelete}
              >
                削除
              </Button>
            )}
          </ButtonGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EdgeUpdatePopup;
