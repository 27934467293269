/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DataView } from "primereact/dataview";
import {
  Button,
  Input,
  Badge,
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { FaTag, FaVideo } from "react-icons/fa";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import DemonstrationForm from "./DemonstrationForm";

interface User {
  id: number;
  username: string;
}

interface Tag {
  id: number;
  name: string;
}

interface Demonstration {
  id: number;
  title: string;
  user: User;
  tags: Tag[];
  read_tags: Tag[];
  created_at: string;
  updated_at: string;
  demo_video_url: string;
  use_case: string;
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 8px;
  height: 18%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  height: 82%;
  overflow-y: auto;
`;

const DataViewItem = styled.div`
  border-radius: 8px;
  background-color: #ebf2f2;
  width: 80%;
  margin: 28px auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

const InfoRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  margin-top: 10px;
`;

const CreatorName = styled.h5`
  font-weight: bold;
  font-size: 0.8rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.8rem;
`;

const Title = styled.h2`
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 10px;
  &:hover {
    color: #007bff;
  }
`;

const NoDataView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: gray;
  font-size: 24px;
  font-weight: bold;
`;

const ClearFilters = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  background-color: #fc8181;
  font-size: 0.8rem;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6);
  &:hover {
    background-color: #fc8181;
    opacity: 0.7;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
`;

export const DemonstrationList = () => {
  const [demonstrations, setDemonstrations] = useState<Demonstration[]>([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState<number | null>(null);
  const [titleSearch, setTitleSearch] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setLoading } = useLoading();

  const fetchData = async () => {
    if (user && user.company?.id) {
      try {
        setLoading(true);
        const [demoResponse, tagResponse] = await Promise.all([
          apiClient.get(`/api/demonstrations/companies/${user.company.id}/`),
          apiClient.get(`/api/demonstrations/tags/${user.company.id}/`),
        ]);

        // データを更新日時で降順にソート
        const sortedDemonstrations = demoResponse.data.sort(
          (a: any, b: any) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );

        setDemonstrations(sortedDemonstrations);
        setTags(tagResponse.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const openForm = () => setIsFormVisible(true);
  const closeForm = () => setIsFormVisible(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleSearch(event.target.value);
  };

  const resetSearch = () => {
    setTitleSearch("");
    setSelectedTag(null);
  };

  const filteredDemonstrations = demonstrations.filter((demo) => {
    const matchesTitle = demo.title
      .toLowerCase()
      .includes(titleSearch.toLowerCase());

    const matchesTag = selectedTag
      ? (demo.read_tags || []).some((tag) => {
          return tag.id === selectedTag;
        })
      : true;

    return matchesTitle && matchesTag;
  });

  const handleTagClick = (tagId: number) => {
    setSelectedTag(tagId);
  };

  const handleTitleClick = (demoId: number) => {
    navigate(`/demonstration/${demoId}`);
  };

  const itemTemplate = (demonstration: Demonstration): JSX.Element => {
    return (
      <DataViewItem key={demonstration.id}>
        {demonstration.read_tags && demonstration.read_tags.length > 0 && (
          <TagContainer>
            {demonstration.read_tags.map((tag) => (
              <Badge
                key={tag.id}
                colorScheme="gray"
                variant="solid"
                p="2"
                borderRadius="lg"
                display="flex"
                alignItems="center"
                gap="1"
                onClick={() => handleTagClick(tag.id)}
                cursor="pointer"
                _hover={{ opacity: 0.7 }}
              >
                <FaTag />
                {tag.name}
              </Badge>
            ))}
          </TagContainer>
        )}
        <InfoRow>
          <DateContainer>
            <div>
              更新日:{" "}
              {new Date(demonstration.updated_at).toLocaleDateString("ja-JP", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
            </div>
          </DateContainer>
          <CreatorName>{demonstration.user.username}</CreatorName>
        </InfoRow>
        <Title onClick={() => handleTitleClick(demonstration.id)}>
          {demonstration.title}
        </Title>
      </DataViewItem>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <Heading as="h2" size="md" mb={4} ml={3} mt={5}>
          デモ一覧
        </Heading>
        <Header>
          <Flex alignItems="center" gap="2" ml="10px" width="100%" mr="20px">
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={SearchIcon} color="gray.500" />}
              />
              <Input
                value={titleSearch}
                onChange={handleSearchChange}
                placeholder="タイトルで検索..."
                bg="white"
                border="1px"
                borderColor="gray.300"
              />
              {titleSearch && (
                <InputRightElement
                  children={
                    <Icon
                      as={CloseIcon}
                      color="red.200"
                      onClick={resetSearch}
                      cursor="pointer"
                    />
                  }
                />
              )}
            </InputGroup>
          </Flex>
          <Flex gap="2" mr="12px">
            <Button
              leftIcon={<FaVideo />}
              colorScheme="blue"
              fontSize={14}
              width={24}
              onClick={openForm}
            >
              デモ登録
            </Button>
          </Flex>
        </Header>
      </HeaderContainer>
      <Content>
        {selectedTag && (
          <ClearFilters>
            <StyledButton onClick={resetSearch}>絞り込みをクリア</StyledButton>
          </ClearFilters>
        )}
        {filteredDemonstrations.length > 0 ? (
          <DataView
            value={filteredDemonstrations}
            itemTemplate={itemTemplate}
            layout="grid"
          />
        ) : (
          <NoDataView>デモがまだ存在しません</NoDataView>
        )}
      </Content>
      <DemonstrationForm
        closeForm={closeForm}
        isVisible={isFormVisible}
        onSuccessfulSubmit={fetchData}
        demonstrationId={null}
      />
    </Container>
  );
};
