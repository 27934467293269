/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Divider,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  RadioGroup,
  Radio,
  HStack,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarOfDavid } from "@fortawesome/free-solid-svg-icons";
import { FaTrash } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import { format as formatDate } from "date-fns";
import styled from "styled-components";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import ShareReportDialog from "./ShareReportDialog";
import FeedbackModal from "./FeedbackModal";
import { useLoading } from "./LoadingContext";
import { useMessage } from "./MessageContext";
import NoPermission from "./NoPermission";

const Container = styled.div`
  height: 100%;
  width: 80%;
  margin: auto;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  top: 16px;
  right: 16px;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.4em;
`;

const RightAlignItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin-bottom: 0.4em;
`;

const StyledRouterLink = styled(RouterLink)`
  color: #3182ce;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const LeftAlignButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.4em;
`;

const StyledTableContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const AudioPlayerContainer = styled(Box)`
  width: 92%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledTable = styled(Table)`
  background-color: white;
  th {
    width: 30%;
  }
  td {
    width: 70%;
  }
`;

interface User {
  id: number;
  username: string;
  email: string;
}

interface TalkScript {
  id: number;
  name: string;
}

interface Report {
  id: number;
  user: User;
  title: string;
  date: string;
  talk_script: TalkScript;
  improvements: string;
  goal_achieved: boolean;
  remarks: string;
  audio_file_url: string;
  reviewers: number[];
  is_score_requested: boolean;
  is_score_finished: boolean;
  created_at: string;
  updated_at: string;
}

interface Feedback {
  id: number;
  report: number;
  user: User;
  feedback: string;
  rating: number;
  created_at: string;
  updated_at: string;
}

interface Scoring {
  id: number;
  score: number;
  evaluation_text: string;
  report: number;
  user: User;
  created_at: string;
  updated_at: string;
}

const ReportDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [report, setReport] = useState<Report | null>(null);
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [currentReportId, setCurrentReportId] = useState<number | null>(null);
  const [currentFeedbackId, setCurrentFeedbackId] = useState<number | null>(
    null
  );
  const [feedbackUpdated, setFeedbackUpdated] = useState(false);
  const audioPlayerRef = useRef<AudioPlayer>(null);
  const { setLoading } = useLoading();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editDate, setEditDate] = useState("");
  const [editRemarks, setEditRemarks] = useState("");
  const [editImprovements, setEditImprovements] = useState("");
  const [editTalkScriptId, setEditTalkScriptId] = useState("");
  const [talkScripts, setTalkScripts] = useState<TalkScript[]>([]);
  const [scoring, setScoring] = useState<Scoring | null>(null);
  const [editGoalAchieved, setEditGoalAchieved] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const { setMessage } = useMessage();
  const toast = useToast();

  const openFeedbackModal = (reportId: number, feedbackId?: number) => {
    setCurrentReportId(reportId);
    setCurrentFeedbackId(feedbackId !== undefined ? feedbackId : null);
    setFeedbackModalOpen(true);
  };

  const changePlaybackRate = (rateString: string) => {
    const rate = parseFloat(rateString);
    if (audioPlayerRef.current && audioPlayerRef.current.audio.current) {
      audioPlayerRef.current.audio.current.playbackRate = rate;
    }
  };

  useEffect(() => {
    const fetchTalkScripts = async () => {
      try {
        const { data } = await apiClient.get("/api/talk_scripts/");
        setTalkScripts(data);
      } catch (error) {
        console.error("Error fetching talk scripts:", error);
      }
    };
    fetchTalkScripts();
  }, [user]);

  // 必要情報のフェッチ
  useEffect(() => {
    setLoading(true);
    apiClient
      .get(`/api/reviews/reports/${id}/`)
      .then((response) => {
        if (!response.data.reviewers.includes(user?.id)) {
          setNoPermission(true);
        }
        setReport(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setNoPermission(true);
        }
        console.error("Could not fetch report:", error);
      });

    apiClient
      .get(`/api/reviews/reports/${id}/feedbacks/`)
      .then((response) => {
        setFeedbacks(response.data);

        const userFeedback = response.data.find(
          (feedback: any) => feedback.user.id === user?.id
        );
        setFeedbackGiven(!!userFeedback);
      })
      .catch((error) => {
        console.error("Could not fetch feedbacks:", error);
      });

    // スコアリングデータのフェッチ
    apiClient
      .get(`/api/reviews/reports/${id}/scorings/`)
      .then((response) => {
        setScoring(response.data.length > 0 ? response.data[0] : null);
      })
      .catch((error) => {
        console.error("Could not fetch scorings:", error);
      })
      .finally(() => {
        setLoading(false);
      });

    fetchFeedbacks();
  }, [id, user, feedbackUpdated]);

  const handleDelete = () => {
    const confirmDelete = window.confirm("本当にこのレポートを削除しますか？");
    if (confirmDelete) {
      setLoading(true);
      apiClient
        .delete(`/api/reviews/reports/${id}/`)
        .then((response) => {
          navigate("/dashboard?tab=report");
          setMessage("商談記録を削除しました。", "success");
        })
        .catch((error) => {
          setMessage("商談記録の削除に失敗しました。", "error");
          console.error("Could not delete report:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // モーダルを開く
  const handleShare = () => {
    setIsShareDialogOpen(true);
  };

  // モーダルを閉じる
  const closeShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  const handleBack = () => {
    navigate("/dashboard?tab=report");
  };

  const fetchFeedbacks = () => {
    apiClient
      .get(`/api/reviews/reports/${id}/feedbacks/`)
      .then((response) => setFeedbacks(response.data))
      .catch((error) => console.error("Could not fetch feedbacks:", error));
  };

  const deleteFeedback = (feedbackId: number) => {
    const confirmDelete = window.confirm(
      "本当にこのフィードバックを削除しますか？"
    );
    if (confirmDelete) {
      setLoading(true);
      apiClient
        .delete(`/api/reviews/reports/${id}/feedbacks/${feedbackId}/`)
        .then(() => {
          setMessage("削除しました。", "success");
          setFeedbackUpdated((prev) => !prev);
          fetchFeedbacks();
        })
        .catch((error) => {
          console.error("Could not delete feedback:", error);
          setMessage("削除に失敗しました。", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // モーダルを開く関数
  const openEditModal = () => {
    if (report) {
      setEditDate(formatDate(new Date(report.date), "yyyy-MM-dd"));
      setEditRemarks(report.remarks || "");
      setEditImprovements(report.improvements || "");
      setEditTalkScriptId(
        report.talk_script ? report.talk_script.id.toString() : ""
      );
      setEditGoalAchieved(report.goal_achieved);
      setIsEditModalOpen(true);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      const updatedData = {
        date: editDate,
        remarks: editRemarks,
        improvements: editImprovements,
        talk_script: editTalkScriptId ? Number(editTalkScriptId) : null,
        goal_achieved: editGoalAchieved,
      };
      // APIエンドポイントへのリクエスト
      const response = await apiClient.patch(
        `/api/reviews/reports/${id}/`,
        updatedData
      );

      if (response.status === 200) {
        setReport(response.data);
        setMessage("商談記録を更新しました。", "success");
      }
    } catch (error) {
      console.error("Error updating report:", error);
      setMessage("商談記録の更新に失敗しました。", "error");
    } finally {
      setLoading(false);
    }

    setIsEditModalOpen(false);
  };

  // スコアリング依頼ボタンのハンドラ
  const handleScoringRequest = async () => {
    try {
      setLoading(true);
      const response = await apiClient.post(
        `/api/reviews/reports/${id}/request_scoring/`
      );
      if (response.status === 200) {
        toast({
          title:
            "スコアリング依頼を受け付けました。24時間以内にスコアリングします。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setReport((prevReport) =>
          prevReport ? { ...prevReport, is_score_requested: true } : prevReport
        );
      } else {
        toast({
          title: "スコアリング依頼の送信に失敗しました。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("スコアリング依頼の送信に失敗しました。", error);
      toast({
        title: "スコアリング依頼の送信に失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {noPermission ? (
        <NoPermission />
      ) : (
        <Container>
          <Box p={4}>
            <HeadingContainer>
              <LeftAlignButton>
                <Button colorScheme="gray" size="md" onClick={handleBack}>
                  戻る
                </Button>
              </LeftAlignButton>
              <ButtonContainer>
                {report && user && report.user.id === user.id && (
                  <IconButton
                    icon={<FaTrash />}
                    aria-label="削除"
                    colorScheme="red"
                    onClick={handleDelete}
                  />
                )}
                {report && user && report.user.id === user.id && (
                  <Button
                    colorScheme="blue"
                    size="md"
                    ml={4}
                    onClick={openEditModal}
                  >
                    編集
                  </Button>
                )}
                {user && user.company?.can_score_deals && (
                  <Button
                    colorScheme="purple"
                    size="md"
                    ml={4}
                    onClick={handleScoringRequest}
                    isDisabled={
                      report?.is_score_requested || report?.is_score_finished
                    }
                  >
                    {report?.is_score_requested
                      ? report?.is_score_finished
                        ? "スコアリング済"
                        : "受け付け済"
                      : "スコアリング依頼"}
                  </Button>
                )}
                {user &&
                  user.plan === "team" &&
                  report &&
                  report.user.id === user.id && (
                    <Button
                      colorScheme="teal"
                      size="md"
                      ml={4}
                      onClick={handleShare}
                    >
                      共有
                    </Button>
                  )}
                {id && (
                  <ShareReportDialog
                    isOpen={isShareDialogOpen}
                    onClose={closeShareDialog}
                    reportId={String(id)}
                    companyId={
                      user?.company?.id ? String(user.company.id) : undefined
                    }
                  />
                )}
                {report &&
                  user &&
                  report.user.id !== user.id &&
                  report.reviewers.includes(user.id) && (
                    <Button
                      colorScheme="teal"
                      size="md"
                      ml={4}
                      onClick={() => openFeedbackModal(report.id)}
                      isDisabled={feedbackGiven}
                    >
                      {feedbackGiven ? "フィードバック済" : "フィードバック"}
                    </Button>
                  )}
              </ButtonContainer>
            </HeadingContainer>
            <VStack
              spacing={6}
              align="stretch"
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                marginBottom: "36px",
              }}
            >
              <Box justifyContent="flex-end">
                <RightAlignItem>
                  {report && (
                    <>
                      <Text>
                        <strong>記録作成日</strong>{" "}
                        {formatDate(
                          new Date(report.updated_at),
                          "yyyy/MM/dd HH:mm"
                        )}
                      </Text>
                      <Text>
                        <strong>商談実施者</strong> {report.user.username}
                      </Text>
                    </>
                  )}
                </RightAlignItem>
              </Box>

              <Box>
                {report && (
                  <>
                    <VStack align="start" spacing={3}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={4}
                        mb={4}
                      >
                        <Heading as="h2" size="lg" textAlign="center" mb={2}>
                          {report.title}
                        </Heading>
                      </Box>
                      <StyledTableContainer>
                        <StyledTable variant="simple">
                          <Tbody>
                            <Tr>
                              <Th>実施日</Th>
                              <Td>
                                {formatDate(
                                  new Date(report.date),
                                  "yyyy/MM/dd"
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Th>使用したトークスクリプト</Th>
                              <Td>
                                {report.talk_script && (
                                  <StyledRouterLink
                                    to={`/script/${report.talk_script.id}`}
                                  >
                                    {report.talk_script.name}
                                  </StyledRouterLink>
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Th>
                                トークスクリプトに改善が必要な点や上手くできなかった箇所
                              </Th>
                              <Td>{report.improvements}</Td>
                            </Tr>
                            <Tr>
                              <Th>商談の目的を達成できましたか？</Th>
                              <Td>
                                <Text color="red.300" fontWeight="bold">
                                  {report.goal_achieved
                                    ? "達成できました"
                                    : "達成できませんでした"}
                                </Text>
                              </Td>
                            </Tr>
                            <Tr>
                              <Th>商談実施者のコメント</Th>
                              <Td>{report.remarks}</Td>
                            </Tr>
                          </Tbody>
                        </StyledTable>
                      </StyledTableContainer>
                    </VStack>
                    {report && report.audio_file_url && (
                      <AudioPlayerContainer>
                        <AudioPlayer
                          ref={audioPlayerRef}
                          src={report.audio_file_url}
                          onPlay={(e) => console.log("onPlay")}
                          // 他のプロパティやイベントハンドラー
                        />
                        <Select
                          width="180px"
                          alignSelf="flex-start"
                          onChange={(e) => changePlaybackRate(e.target.value)}
                        >
                          <option value="1">1.0倍速</option>
                          <option value="1.25">1.25倍速</option>
                          <option value="1.5">1.5倍速</option>
                          <option value="2">2.0倍速</option>
                        </Select>
                      </AudioPlayerContainer>
                    )}
                  </>
                )}
              </Box>
              {scoring && (
                <Box
                  borderWidth="1px"
                  borderRadius="lg"
                  borderColor="gray.200"
                  p={4}
                  mt={2}
                  mb={2}
                  bg="gray.50"
                >
                  <Heading size="md" mb={4}>
                    商談スコア
                  </Heading>
                  <Text fontSize="lg" mb={2}>
                    <strong>得点:</strong> {scoring.score} / 100
                  </Text>
                  <Progress
                    colorScheme="blue"
                    size="sm"
                    value={scoring.score}
                    max={100}
                    mb={4}
                  />
                  <Text fontSize="lg" mb={2}>
                    <strong>評価:</strong>
                  </Text>
                  <pre
                    style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
                  >
                    {scoring.evaluation_text}
                  </pre>
                </Box>
              )}

              {feedbacks.length > 0 && (
                <Box p={4} borderWidth={1} borderRadius="md">
                  <Heading as="h4" size="md">
                    フィードバック
                  </Heading>
                  <Divider mt={4} mb={4} />
                  {feedbacks.map((feedback, index) => (
                    <Box key={index} mb={4}>
                      <Box display="flex" justifyContent="left" ml={2} mb={2}>
                        <Text mr={3}>
                          <strong>{feedback.user.username}</strong>
                        </Text>
                        {user && feedback.user.id === user.id && (
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={<EditIcon />}
                              size="sm"
                            />
                            <MenuList>
                              <MenuItem
                                onClick={() =>
                                  report &&
                                  openFeedbackModal(report.id, feedback.id)
                                }
                              >
                                編集
                              </MenuItem>
                              <MenuItem
                                color="red.500"
                                onClick={() => deleteFeedback(feedback.id)}
                              >
                                削除
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </Box>
                      <Box justifyContent="space-between" mb={4} ml={2}>
                        <Text fontSize="sm">
                          {formatDate(
                            new Date(feedback.updated_at),
                            "yyyy/MM/dd HH:mm"
                          )}
                        </Text>
                      </Box>
                      <Box justifyContent="space-between" mb={4} ml={2}>
                        <Heading as="h5" size="sm">
                          5段階評価
                        </Heading>
                        <Text>
                          {Array.from({ length: 5 }, (_, i) => (
                            <FontAwesomeIcon
                              key={i}
                              icon={
                                i < feedback.rating ? faStar : faStarOfDavid
                              }
                              color={i < feedback.rating ? "gold" : "gray"}
                            />
                          ))}
                        </Text>
                      </Box>
                      <Box justifyContent="space-between" mb={4} ml={2}>
                        <Heading as="h5" size="sm" mb={1}>
                          フィードバック
                        </Heading>
                        <Text fontSize="sm">{feedback.feedback}</Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </VStack>
          </Box>
          <FeedbackModal
            isOpen={isFeedbackModalOpen}
            onClose={() => {
              setFeedbackModalOpen(false);
              setFeedbackUpdated(!feedbackUpdated);
            }}
            reportId={currentReportId}
            feedbackId={currentFeedbackId}
            refreshFeedbacks={fetchFeedbacks}
          />
          <Modal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>商談記録の編集</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>実施日</FormLabel>
                  <Input
                    type="date"
                    value={editDate}
                    onChange={(e) => setEditDate(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>使用したトークスクリプト</FormLabel>
                  <Select
                    value={editTalkScriptId}
                    onChange={(e) => setEditTalkScriptId(e.target.value)}
                  >
                    {talkScripts.map((script) => (
                      <option key={script.id} value={script.id.toString()}>
                        {script.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mt={6}>
                  <FormLabel>
                    トークスクリプトに改善が必要な点や上手くできなかった箇所
                  </FormLabel>
                  <Textarea
                    value={editImprovements}
                    onChange={(e) => setEditImprovements(e.target.value)}
                  />
                </FormControl>
                <FormControl mt={6}>
                  <FormLabel>商談の目的を達成できましたか？</FormLabel>
                  <RadioGroup
                    onChange={(value) => setEditGoalAchieved(value === "true")}
                    value={editGoalAchieved.toString()}
                  >
                    <HStack>
                      <Radio value="true">達成できました</Radio>
                      <Radio value="false">達成できませんでした</Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
                <FormControl mt={6}>
                  <FormLabel>商談実施者のコメント</FormLabel>
                  <Textarea
                    value={editRemarks}
                    onChange={(e) => setEditRemarks(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleSaveChanges}>
                  保存
                </Button>
                <Button onClick={() => setIsEditModalOpen(false)}>
                  キャンセル
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Container>
      )}
    </>
  );
};

export default ReportDetail;
