/** @format */

import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Textarea,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  Tag,
  TagLabel,
  VStack,
  Text,
  useOutsideClick,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FiUpload } from "react-icons/fi";
import styled from "styled-components";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";
import TagManagementModal from "./TagManagementModal";

const ResponsiveButton = styled(Button)`
  font-size: 60%;
  padding: 6px 12px;
  @media (max-width: 719px) {
    font-size: 40%;
    padding: 4px 8px;
  }
`;

const FileName = styled(InputRightAddon)`
  font-size: 60%;
  padding: 4px 8px;
  @media (max-width: 719px) {
    font-size: 40%;
  }
`;

const TagHeading = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const TagLink = styled.button`
  background: none;
  border: none;
  color: teal;
  cursor: pointer;
  font-size: 12px;
  padding-left: 8px;
  text-decoration: underline;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
`;

const TagStyled = styled(Tag)`
  margin-bottom: 8px;
`;

const TagListHeader = styled.div`
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 2px;
  color: gray;
`;

const SubmitButtonContainer = styled.div`
  margin-top: 24px;
`;

interface DemonstrationFormProps {
  closeForm: () => void;
  isVisible: boolean;
  onSuccessfulSubmit: () => void;
  demonstrationId?: number | null;
}

interface DemoTagProps {
  id: number;
  name: string;
}

// デモフォームコンポーネント
const DemonstrationForm: React.FC<DemonstrationFormProps> = ({
  closeForm,
  isVisible,
  onSuccessfulSubmit,
  demonstrationId = null,
}) => {
  const [title, setTitle] = useState("");
  const [useCase, setUseCase] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [fileName, setFileName] = useState("");
  const [allTags, setAllTags] = useState<DemoTagProps[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedTags, setSelectedTags] = useState<DemoTagProps[]>([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isTagVisible, setIsTagVisible] = useState(false);
  const inputRef = React.useRef(null);
  const toast = useToast();
  const { user } = useAuth();
  const { setLoading } = useLoading();

  useEffect(() => {
    fetchAllTags();
  }, [user?.company?.id]);

  useEffect(() => {
    if (demonstrationId) {
      loadExistingData(demonstrationId);
    }
  }, [demonstrationId]);

  const loadExistingData = async (id: number) => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/demonstrations/${id}/`);
      const data = response.data;
      setTitle(data.title);
      setUseCase(data.use_case);
      setFileName(data.demo_video_url);

      setSelectedTags(data.read_tags);
    } catch (error) {
      console.error("Failed to fetch demonstration details:", error);
      toast({
        title: "エラー",
        description: "データの読み込みに失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useOutsideClick({
    ref: inputRef,
    handler: () => setDropdownOpen(false),
  });

  const handleTagSelect = (tagId: number, tagName: string) => {
    const tag = { id: tagId, name: tagName };
    if (!selectedTags.some((t) => t.id === tagId)) {
      setSelectedTags((prev) => [...prev, tag]);
    }
  };

  const removeTag = (tagId: number) => {
    setSelectedTags((prev) => prev.filter((t) => t.id !== tagId));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const filteredTags = inputValue
    ? allTags.filter(
        (tag) =>
          tag.name.toLowerCase().includes(inputValue.toLowerCase()) &&
          !selectedTags.includes(tag)
      )
    : allTags.filter((tag) => !selectedTags.includes(tag));

  const fetchAllTags = async () => {
    if (user?.company?.id) {
      try {
        const response = await apiClient.get(
          `/api/demonstrations/tags/${user.company.id}/`
        );
        const sortedTags = response.data.sort((a: any, b: any) =>
          a.name.localeCompare(b.name, "ja")
        );
        setAllTags(sortedTags);
      } catch (error) {
        console.error("Failed to fetch tags:", error);
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const selectedFile = files[0];
      if (selectedFile.type === "video/mp4" && selectedFile.size <= 500000000) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
      } else {
        toast({
          title: "無効なファイル",
          description:
            "MP4ファイルのみアップロード可能です。ファイルサイズは500MB以下にしてください。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        event.target.value = "";
      }
    }
  };

  const handleSubmit = async () => {
    if (!title || !useCase) {
      toast({
        title: "必須項目が未入力です",
        description: "すべての項目を入力してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (title.length > 50) {
      toast({
        title: "タイトルが長すぎます",
        description: "50文字以内で入力してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!user?.company?.id) {
      toast({
        title: "エラー",
        description: "会社情報が見つかりません。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("use_case", useCase);
    formData.append("company_id", user.company.id.toString());
    selectedTags.forEach((tag) => {
      formData.append("tags", tag.id.toString());
    });

    try {
      let response;
      if (demonstrationId) {
        // 更新処理
        response = await apiClient.put(
          `/api/demonstrations/${demonstrationId}/`,
          formData
        );
      } else {
        // 新規登録処理
        response = await apiClient.post("/api/demonstrations/", formData);
      }
      const createdDemo = response.data;

      // ファイルが選択されていればアップロード
      if (file) {
        const fileData = new FormData();
        fileData.append("file", file);
        const fileUploadResponse = await apiClient.post(
          `/api/demonstrations/${createdDemo.id}/upload_media/`,
          fileData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
      }

      toast({
        title: demonstrationId ? "更新成功" : "登録成功",
        description: `デモの${demonstrationId ? "更新" : "登録"}に成功しました`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onSuccessfulSubmit();
      closeForm();
    } catch (error) {
      toast({
        title: demonstrationId ? "更新失敗" : "登録失敗",
        description: `デモの${demonstrationId ? "更新" : "登録"}に失敗しました`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFileButtonClick = () => {
    // 隠されたファイルインプットを参照し、クリックイベントを発火させる
    const fileInput = document.getElementById("file-input");
    fileInput?.click();
  };

  const openTagForm = () => setIsTagVisible(true);
  const closeTagForm = () => setIsTagVisible(false);

  const resetForm = () => {
    setTitle("");
    setUseCase("");
    setFile(null);
    setFileName("");
    setSelectedTags([]);
    setErrors([]);
  };

  useEffect(() => {
    if (isVisible && !demonstrationId) {
      resetForm();
    }
  }, [isVisible, demonstrationId]);

  return (
    <Modal
      isOpen={isVisible}
      onClose={closeForm}
      size={{ base: "full", md: "2xl", lg: "4xl" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{demonstrationId ? "デモ更新" : "デモ登録"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={4}>
            <FormControl mb={4} isInvalid={errors.includes("title")} isRequired>
              <FormLabel>タイトル（50字以内）</FormLabel>
              <Input
                placeholder="デモのタイトルを入力"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl
              mb={4}
              isInvalid={errors.includes("useCase")}
              isRequired
            >
              <FormLabel>説明</FormLabel>
              <Textarea
                placeholder="説明を入力"
                value={useCase}
                onChange={(e: any) => setUseCase(e.target.value)}
              />
            </FormControl>
            <FormControl mb={4} isInvalid={errors.includes("file")} isRequired>
              <FormLabel htmlFor="file-input">
                {demonstrationId
                  ? "デモ動画（mp4形式）【更新する場合のみ入力してください】"
                  : "デモ動画（mp4形式）"}
              </FormLabel>
              <InputGroup size="lg">
                <Input
                  id="file-input"
                  name="file"
                  type="file"
                  onChange={handleFileChange}
                  accept="video/mp4"
                  style={{ display: "none" }}
                />
                <ResponsiveButton
                  leftIcon={<FiUpload />}
                  colorScheme="gray"
                  variant={file ? "outline" : "solid"}
                  onClick={handleFileButtonClick}
                  mr={2}
                >
                  <span className="button-text">
                    {file ? "ファイルを変更" : "ファイルを選択"}
                  </span>
                </ResponsiveButton>
                {file && <FileName children={fileName} borderRadius="md" />}
              </InputGroup>
            </FormControl>
            <VStack align="start" ref={inputRef} spacing={4}>
              <TagHeading>
                <div>タグ</div>
                <TagLink onClick={openTagForm}>タグを登録する</TagLink>
              </TagHeading>
              <TagContainer>
                {selectedTags.map((tag) => (
                  <TagStyled size="md" key={tag.id} borderRadius="lg">
                    <TagLabel>{tag.name}</TagLabel>
                    <IconButton
                      aria-label={`Remove tag ${tag}`}
                      icon={<CloseIcon />}
                      size="xs"
                      onClick={() => removeTag(tag.id)}
                    />
                  </TagStyled>
                ))}
                <Input
                  placeholder="タグを検索"
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={() => {
                    fetchAllTags();
                    setDropdownOpen(true);
                  }}
                />
              </TagContainer>
              {isDropdownOpen && (
                <Box
                  border="1px"
                  borderColor="gray.200"
                  mt={1}
                  p={2}
                  bg="white"
                  width="100%"
                >
                  <TagListHeader>タグ一覧</TagListHeader>
                  {filteredTags.length > 0 ? (
                    filteredTags.map((tag) => (
                      <Text
                        key={tag.id}
                        p={1}
                        cursor="pointer"
                        _hover={{ bg: "gray.100" }}
                        onClick={() => handleTagSelect(tag.id, tag.name)}
                      >
                        {tag.name}
                      </Text>
                    ))
                  ) : (
                    <Text p={1}>候補がありません</Text>
                  )}
                </Box>
              )}
            </VStack>
            <SubmitButtonContainer>
              <Button colorScheme="teal" onClick={handleSubmit}>
                {demonstrationId ? "更新" : "登録"}
              </Button>
            </SubmitButtonContainer>
          </Box>
        </ModalBody>
      </ModalContent>
      <TagManagementModal
        closeTagForm={closeTagForm}
        isVisible={isTagVisible}
      />
    </Modal>
  );
};

export default DemonstrationForm;
