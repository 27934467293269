/** @format */

import React, { useEffect, useState } from "react";
import {
  Heading,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Select,
  Box,
  useDisclosure,
  Textarea,
  useToast,
  Image,
  Badge,
} from "@chakra-ui/react";
import { DataView } from "primereact/dataview";
import { FaRegGrinWink } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import manIcon from "./assets/man.png";
import womanIcon from "./assets/woman.png";

interface Persona {
  id: number;
  name: string;
  age: number;
  gender: string;
  industry: string;
  job_type: string;
  position: string;
  company_size: string;
  annual_revenue: string;
  product_service: string;
  current_challenges: string;
  personality: string;
  decision_making_power: string;
  user: number | null;
  company: number | null;
}

type Options = {
  [key: string]: string;
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 8px;
  height: 18%;
`;

const Content = styled.div`
  flex: 1;
  height: 82%;
  overflow-y: auto;
`;

const DataViewItem = styled.div`
  border-radius: 8px;
  background-color: #ebf2f2;
  width: 88%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #d7e7e7;
    transform: scale(1.02);
  }
`;

const InfoRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  margin-top: 10px;
`;

const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
  margin-left: 8px;
`;

const NoDataView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: gray;
  font-size: 24px;
  font-weight: bold;
`;

const PersonaList: React.FC = () => {
  const [personas, setPersonas] = useState<Persona[]>([]);
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(null);
  const [purpose, setPurpose] = useState("");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPurposeModalOpen,
    onOpen: onPurposeModalOpen,
    onClose: onPurposeModalClose,
  } = useDisclosure();
  const { user } = useAuth();
  const toast = useToast();
  const [errors, setErrors] = useState<any>({});
  const { setLoading } = useLoading();
  const [newPersona, setNewPersona] = useState({
    id: null,
    name: "",
    age: 0,
    gender: "male",
    industry: "manufacturer",
    job_type: "corporate",
    position: "president",
    company_size: "1-29",
    annual_revenue: "under_10M",
    product_service: "",
    current_challenges: "",
    personality: "logical",
    decision_making_power: "none",
    user: user?.id || null,
    company: user?.company?.id || null,
  });

  const genderOptions: Options = {
    male: "男性",
    female: "女性",
  };

  const companySizeOptions: Options = {
    "1-29": "1-29人",
    "30-99": "30-99人",
    "100-299": "100-299人",
    "300-999": "300-999人",
    "1000-": "1000人以上",
  };

  const industryOptions: Options = {
    manufacturer: "メーカー（自動車、素材）",
    service_infra:
      "サービス・インフラ（人材、コンサルティング、ホテル、電力、インターネットプロバイダ）",
    trading_company: "商社（総合商社、専門商社）",
    software: "ソフトウエア（ゲーム、SaaS）",
    retail: "小売（スーパー、コンビニ）",
    advertising_media: "広告・出版・マスコミ",
    finance: "金融（銀行、保険）",
    public_office: "官公庁・公社・団体",
  };

  const jobTypeOptions: Options = {
    corporate: "経営",
    sales: "営業",
    sales_planning: "営業企画",
    technical_sales: "技術営業",
    cs_cx: "カスタマーサポート／カスタマーサクセス",
    product_planning: "商品企画／サービス企画",
    research: "リサーチ／市場調査",
    advertising: "広告宣伝",
    public_relations: "広報／PR／IR",
    procurement: "購買／資材調達",
    logistics: "物流企画／倉庫管理／在庫管理",
    international_trade: "貿易／国際業務",
    corporate_planning: "経営企画／事業企画",
    accounting: "経理／財務／税務／会計",
    general_affairs: "総務",
    human_resources: "人事",
    legal: "法務／知的財産／特許",
    internal_audit: "内部監査",
    assistant: "アシスタント",
    consultant: "コンサルタント",
    it_engineer: "ITエンジニア",
    physically_engineer: "モノづくり系エンジニア",
    civil_engineer: "建築／土木系エンジニア",
    material_engineer: "素材／化学／食品系エンジニア",
    medical: "医療系専門職",
    asset_specialist: "金融系専門職",
    real_estate_specialist: "不動産専門職",
    service: "販売／サービス職",
    creative: "クリエイティブ職",
  };

  const positionOptions: Options = {
    president: "代表取締役社長",
    senior_managing_director: "専務取締役",
    managing_director: "常務取締役",
    director: "本部長（事業部長）",
    manager: "部長",
    deputy_director: "次長",
    leader: "課長",
    sub_leader: "係長",
    chief: "主任",
    general: "一般社員",
  };

  const personalityOptions: Options = {
    logical: "論理的",
    creative: "創造的",
    pragmatic: "実利的",
    empathetic: "共感的",
    detail_oriented: "細部志向",
  };

  const decisionMakingPowerOptions: Options = {
    none: "影響力も決定権もない",
    influencer: "影響力はあるが、決定権はない",
    partial: "部分的に決定権がある",
    full: "完全な決定権がある",
  };

  const annualRevenueOptions: Options = {
    under_10M: "年間売上999万円以下",
    under_50M: "年間売上1000~4999万円",
    under_100M: "年間売上5000~9999万円",
    under_1B: "年間売上1~9億円",
    under_5B: "年間売上10~49億円",
    under_10B: "年間売上50~99億円",
    under_50B: "年間売上100-499億円",
    under_100B: "年間売上500-999億円",
    over_100B: "年間売上1000億円以上",
  };

  const fetchPersonas = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(
        `/api/personas/?company=${user?.company?.id}`
      );
      setPersonas(response.data);
    } catch (error) {
      console.error("壁打ち相手の取得に失敗しました:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPersonas();
  }, []);

  const handleEditClick = async (personaId: number) => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/personas/${personaId}/`);
      setNewPersona(response.data);
      onOpen();
    } catch (error) {
      console.error("壁打ち相手の取得に失敗しました:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterClick = () => {
    setNewPersona({
      id: null,
      name: "",
      age: 0,
      gender: "male",
      industry: "manufacturer",
      job_type: "corporate",
      position: "president",
      company_size: "1-29",
      annual_revenue: "under_10M",
      product_service: "",
      current_challenges: "",
      personality: "logical",
      decision_making_power: "none",
      user: user?.id || null,
      company: user?.company?.id || null,
    });
    onOpen();
  };

  const handleDeleteClick = async (personaId: number) => {
    const confirmed = window.confirm("本当にこの壁打ち相手を削除しますか？");
    if (!confirmed) {
      return;
    }

    try {
      setLoading(true);
      await apiClient.delete(`/api/personas/${personaId}/`);
      toast({
        title: "削除成功",
        description: "壁打ち相手の削除に成功しました",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      fetchPersonas();
    } catch (error) {
      console.error("壁打ち相手の削除に失敗しました:", error);
      toast({
        title: "エラー",
        description: "壁打ち相手の削除に失敗しました",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let validationErrors: any = {};
    if (!newPersona.name) validationErrors.name = "名前は必須です";
    if (!newPersona.age || isNaN(Number(newPersona.age)))
      validationErrors.age = "年齢は数値である必要があります";
    if (!newPersona.product_service)
      validationErrors.product_service = "製品・サービスは必須です";
    if (!newPersona.current_challenges)
      validationErrors.current_challenges = "課題は必須です";
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast({
        title: "入力エラー",
        description: "すべての必須フィールドを正しく入力してください",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      if (newPersona.id) {
        await apiClient.put(`/api/personas/${newPersona.id}/`, newPersona);
        toast({
          title: "成功",
          description: "壁打ち相手の更新に成功しました",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        await apiClient.post("/api/personas/", newPersona);
        toast({
          title: "成功",
          description: "壁打ち相手の登録に成功しました",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
      fetchPersonas();
      onClose();
    } catch (error) {
      console.error("壁打ち相手の登録に失敗しました:", error);
      toast({
        title: "エラー",
        description: "壁打ち相手の登録に失敗しました",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setNewPersona((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePersonaClick = (persona: Persona) => {
    setSelectedPersona(persona);
    onPurposeModalOpen();
  };

  const handleCreateBrickWall = async () => {
    if (!selectedPersona || !purpose) {
      toast({
        title: "入力エラー",
        description: "すべての必須フィールドを正しく入力してください",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      const response = await apiClient.post("/api/brick_walls/", {
        persona: selectedPersona.id,
        purpose: purpose,
      });
      const { id } = response.data;
      apiClient.post("/api/cohort_analysis_logs/create_log/", {
        company: user?.company?.id,
        event_type: "brick_wall",
        additional_info: {
          brick_wall_id: id,
        },
      });
      onPurposeModalClose();
      navigate(`/brick-wall/${id}`);
    } catch (error) {
      console.error("Error creating brick wall:", error);
      toast({
        title: "エラー",
        description: "BrickWallの作成に失敗しました。",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const itemTemplate = (persona: Persona): JSX.Element => {
    return (
      <DataViewItem
        key={persona.id}
        onClick={() => handlePersonaClick(persona)}
      >
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center" mr={4}>
            {persona.gender === "male" && (
              <Image src={manIcon} alt="man icon" boxSize="46px" />
            )}
            {persona.gender === "female" && (
              <Image src={womanIcon} alt="woman icon" boxSize="46px" />
            )}
            <Title>{persona.name}</Title>
          </Flex>
          {persona.user === user?.id && (
            <Flex ml="auto" alignItems="center">
              <Box
                as={MdEdit}
                size="30px"
                cursor="pointer"
                p={1}
                border="1px"
                borderColor="gray.300"
                borderRadius="md"
                backgroundColor="white"
                onClick={() => handleEditClick(persona.id)}
              />
              <Box
                as={MdDelete}
                size="30px"
                cursor="pointer"
                p={1}
                color="red.400"
                border="1px"
                borderColor="gray.300"
                borderRadius="md"
                backgroundColor="white"
                ml={1}
                onClick={() => handleDeleteClick(persona.id)}
              />
            </Flex>
          )}
        </Flex>
        <InfoRow>
          <Flex alignItems="center">
            <Badge colorScheme="teal" mr={1}>
              年齢
            </Badge>
            {persona.age}歳
          </Flex>
          <Flex alignItems="center">
            <Badge colorScheme="teal" mr={1}>
              性別
            </Badge>
            {genderOptions[persona.gender]}
          </Flex>
          <Flex alignItems="center">
            <Badge colorScheme="teal" mr={1}>
              職種
            </Badge>
            {jobTypeOptions[persona.job_type]}
          </Flex>
          <Flex alignItems="center">
            <Badge colorScheme="teal" mr={1}>
              役職
            </Badge>
            {positionOptions[persona.position]}
          </Flex>
          <Flex alignItems="center">
            <Badge colorScheme="teal" mr={1}>
              業界
            </Badge>
            {industryOptions[persona.industry]}
          </Flex>
        </InfoRow>
      </DataViewItem>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <Heading as="h2" size="md" mb={4} ml={3} mt={5}>
          壁打ち相手の選択
        </Heading>
        <Flex alignItems="center" width="100%" justifyContent="flex-end">
          <Button
            leftIcon={<FaRegGrinWink />}
            colorScheme="blue"
            fontSize={14}
            p={2}
            mr={4}
            onClick={handleRegisterClick}
          >
            壁打ち相手の登録
          </Button>
        </Flex>
      </HeaderContainer>
      <Content>
        {personas.length > 0 ? (
          <DataView
            value={personas}
            itemTemplate={itemTemplate}
            layout="grid"
          />
        ) : (
          <NoDataView>壁打ち相手がまだ存在しません</NoDataView>
        )}
      </Content>
      <Modal isOpen={isPurposeModalOpen} onClose={onPurposeModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>新しく壁打ちを始める</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="purpose" isRequired>
              <FormLabel>壁打ちの目的</FormLabel>
              <Textarea
                placeholder="例：商談のアポを取りたい"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleCreateBrickWall}>
              作成
            </Button>
            <Button variant="ghost" onClick={onPurposeModalClose}>
              キャンセル
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {newPersona.id ? "壁打ち相手情報の更新" : "壁打ち相手情報の登録"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box backgroundColor="orange.100" borderRadius="md" p={3} mb={6}>
              <Heading as="h3" size="sm" mb={3}>
                パーソナリティ
              </Heading>
              <FormControl id="name" mb={4} isRequired>
                <FormLabel>名前</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={newPersona.name}
                  onChange={handleInputChange}
                  backgroundColor="white"
                />
              </FormControl>
              <Flex gap={2} mb={4}>
                <FormControl id="age" mr={4} isRequired>
                  <FormLabel>年齢</FormLabel>
                  <Input
                    type="number"
                    name="age"
                    backgroundColor="white"
                    value={newPersona.age}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl id="gender" isRequired>
                  <FormLabel>性別</FormLabel>
                  <RadioGroup
                    name="gender"
                    value={newPersona.gender}
                    onChange={(value) =>
                      setNewPersona((prevState) => ({
                        ...prevState,
                        gender: value,
                      }))
                    }
                  >
                    <Stack direction="row">
                      <Radio value="male" backgroundColor="white">
                        男性
                      </Radio>
                      <Radio value="female" backgroundColor="white">
                        女性
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Flex>
              <FormControl id="personality" mb={4} isRequired>
                <FormLabel>性格タイプ</FormLabel>
                <Select
                  name="personality"
                  backgroundColor="white"
                  value={newPersona.personality}
                  onChange={handleInputChange}
                >
                  <option value="logical">論理的タイプ</option>
                  <option value="creative">創造的タイプ</option>
                  <option value="pragmatic">実利的タイプ</option>
                  <option value="empathetic">共感的タイプ</option>
                  <option value="detail_oriented">細部志向タイプ</option>
                </Select>
              </FormControl>
            </Box>
            <Box backgroundColor="green.100" borderRadius="md" p={3} mb={6}>
              <Heading as="h3" size="sm" mb={3}>
                仕事
              </Heading>
              <FormControl id="industry" mb={4} isRequired>
                <FormLabel>業界</FormLabel>
                <Select
                  name="industry"
                  backgroundColor="white"
                  value={newPersona.industry}
                  onChange={handleInputChange}
                >
                  <option value="manufacturer">メーカー（自動車、素材）</option>
                  <option value="service_infra">
                    サービス・インフラ（人材、コンサルティング、ホテル、電力、インターネットプロバイダ）
                  </option>
                  <option value="trading_company">
                    商社（総合商社、専門商社）
                  </option>
                  <option value="software">ソフトウエア（ゲーム、SaaS）</option>
                  <option value="retail">小売（スーパー、コンビニ）</option>
                  <option value="advertising_media">
                    広告・出版・マスコミ
                  </option>
                  <option value="finance">金融（銀行、保険）</option>
                  <option value="public_office">官公庁・公社・団体</option>
                </Select>
              </FormControl>
              <FormControl id="product_service" mb={4} isRequired>
                <FormLabel>製品・サービス</FormLabel>
                <Input
                  type="text"
                  backgroundColor="white"
                  name="product_service"
                  placeholder="例：自動車、企業向けシステム、不動産…"
                  value={newPersona.product_service}
                  onChange={handleInputChange}
                />
              </FormControl>
              <Flex gap={2} mb={4}>
                <FormControl id="company_size" isRequired>
                  <FormLabel>会社の規模</FormLabel>
                  <Select
                    name="company_size"
                    backgroundColor="white"
                    value={newPersona.company_size}
                    onChange={handleInputChange}
                  >
                    <option value="1-29">1-29人</option>
                    <option value="30-99">30-99人</option>
                    <option value="100-299">100-299人</option>
                    <option value="300-999">300-999人</option>
                    <option value="1000-">1000人以上</option>
                  </Select>
                </FormControl>
                <FormControl id="annual_revenue" isRequired>
                  <FormLabel>年間売上</FormLabel>
                  <Select
                    name="annual_revenue"
                    backgroundColor="white"
                    value={newPersona.annual_revenue}
                    onChange={handleInputChange}
                  >
                    <option value="under_10M">999万円以下</option>
                    <option value="under_50M">1000~4999万円</option>
                    <option value="under_100M">5000~9999万円</option>
                    <option value="under_1B">1~9億円</option>
                    <option value="under_5B">10~49億円</option>
                    <option value="under_10B">50~99億円</option>
                    <option value="under_50B">100-499億円</option>
                    <option value="under_100B">500-999億円</option>
                    <option value="over_100B">1000億円以上</option>
                  </Select>
                </FormControl>
              </Flex>
              <Flex gap={2} mb={4}>
                <FormControl id="job_type" isRequired>
                  <FormLabel>職種</FormLabel>
                  <Select
                    name="job_type"
                    backgroundColor="white"
                    value={newPersona.job_type}
                    onChange={handleInputChange}
                  >
                    <option value="corporate">経営</option>
                    <option value="sales">営業</option>
                    <option value="sales_planning">営業企画</option>
                    <option value="technical_sales">技術営業</option>
                    <option value="cs_cx">
                      カスタマーサポート／カスタマーサクセス
                    </option>

                    <option value="product_planning">
                      商品企画／サービス企画
                    </option>
                    <option value="research">市場調査</option>
                    <option value="advertising">広告宣伝</option>
                    <option value="public_relations">広報／PR／IR</option>
                    <option value="procurement">購買／資材調達</option>
                    <option value="logistics">
                      物流企画／倉庫管理／在庫管理
                    </option>
                    <option value="international_trade">貿易／国際業務</option>
                    <option value="corporate_planning">
                      経営企画／事業企画
                    </option>
                    <option value="accounting">経理／財務／税務／会計</option>
                    <option value="general_affairs">総務</option>
                    <option value="human_resources">人事</option>
                    <option value="legal">法務／知的財産／特許</option>
                    <option value="internal_audit">内部監査</option>
                    <option value="assistant">アシスタント</option>
                    <option value="consultant">コンサルタント</option>
                    <option value="it_engineer">ITエンジニア</option>
                    <option value="physically_engineer">
                      モノづくり系エンジニア
                    </option>
                    <option value="civil_engineer">
                      建築／土木系エンジニア
                    </option>
                    <option value="material_engineer">
                      素材／化学／食品系エンジニア
                    </option>
                    <option value="medical">医療系専門職</option>
                    <option value="asset_specialist">金融系専門職</option>
                    <option value="real_estate_specialist">不動産専門職</option>
                    <option value="service">販売／サービス職</option>
                    <option value="creative">クリエイティブ職</option>
                  </Select>
                </FormControl>
                <FormControl id="position" isRequired>
                  <FormLabel>役職</FormLabel>
                  <Select
                    name="position"
                    backgroundColor="white"
                    value={newPersona.position}
                    onChange={handleInputChange}
                  >
                    <option value="president">代表取締役社長</option>
                    <option value="senior_managing_director">専務取締役</option>
                    <option value="managing_director">常務取締役</option>
                    <option value="director">本部長（事業部長）</option>
                    <option value="manager">部長</option>
                    <option value="deputy_director">次長</option>
                    <option value="leader">課長</option>
                    <option value="sub_leader">係長</option>
                    <option value="chief">主任</option>
                    <option value="general">一般社員</option>
                  </Select>
                </FormControl>
              </Flex>
              <FormControl id="current_challenges" mb={4} isRequired>
                <FormLabel>抱えている課題</FormLabel>
                <Textarea
                  name="current_challenges"
                  backgroundColor="white"
                  placeholder="例：売上は順調ですが、人材リソースが不足している"
                  value={newPersona.current_challenges}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="decision_making_power" mb={4} isRequired>
                <FormLabel>購買決定力</FormLabel>
                <Select
                  name="decision_making_power"
                  backgroundColor="white"
                  value={newPersona.decision_making_power}
                  onChange={handleInputChange}
                >
                  <option value="none">影響力も決定権もない</option>
                  <option value="influencer">
                    影響力はあるが、決定権はない
                  </option>
                  <option value="partial">
                    影響力はあり、決定権も部分的にある
                  </option>
                  <option value="full">全てを決定する権利がある</option>
                </Select>
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {newPersona.id ? "更新" : "登録"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              キャンセル
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default PersonaList;
