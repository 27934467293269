/** @format */

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Box,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";

interface User {
  id: number;
  username: string;
  email: string;
}

interface TalkScript {
  id: number;
  name: string;
}
interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  reportId?: number | null;
  feedbackId?: number | null;
  refreshFeedbacks: () => void;
}

interface Report {
  id: number;
  user: User;
  title: string;
  date: string;
  talk_script: TalkScript;
  goal_achieved: boolean;
  remarks: string;
  audio_file_url: string;
  reviewers: number[];
  created_at: string;
  updated_at: string;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  reportId,
  feedbackId,
  refreshFeedbacks,
}) => {
  const [feedbackInput, setFeedbackInput] = useState<string>("");
  const [ratingInput, setRatingInput] = useState<number | null>(null);
  const { user } = useAuth();
  const [report, setReport] = useState<Report | null>(null);
  const { setLoading } = useLoading();
  const toast = useToast();

  useEffect(() => {
    if (reportId) {
      setLoading(true);
      apiClient
        .get(`/api/reviews/reports/${reportId}/`)
        .then((response) => {
          setReport(response.data);
        })
        .catch((error) => {
          console.error("Could not fetch report:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [reportId]);

  useEffect(() => {
    if (feedbackId) {
      setLoading(true);
      setFeedbackInput("");
      setRatingInput(null);
      apiClient
        .get(`/api/reviews/reports/${reportId}/feedbacks/${feedbackId}/`)
        .then((response) => {
          setFeedbackInput(response.data.feedback);
          setRatingInput(response.data.rating);
        })
        .catch((error) => {
          console.error("Could not fetch feedback:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setFeedbackInput("");
      setRatingInput(null);
    }
  }, [reportId, feedbackId]);

  const submitFeedback = async () => {
    const payload = {
      feedback: feedbackInput,
      rating: ratingInput,
      report: reportId,
    };

    try {
      setLoading(true);
      // feedbackIdが存在する場合は更新処理
      if (feedbackId) {
        await apiClient.put(
          `/api/reviews/reports/${reportId}/feedbacks/${feedbackId}/`,
          payload
        );
        toast({
          title: "更新しました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        // それ以外の場合は新規作成
        await apiClient.post(
          `/api/reviews/reports/${reportId}/feedbacks/`,
          payload
        );
        toast({
          title: "登録しました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        apiClient.post("/api/cohort_analysis_logs/create_log/", {
          company: user?.company?.id,
          event_type: "create_feedback",
          additional_info: {
            report_id: reportId,
            feedback_rating: ratingInput,
            feedback: feedbackInput,
            reported_user: report?.user.id,
            report_is_goal_achieved: report?.goal_achieved,
            talk_script_id_used_by: report?.talk_script.id,
          },
        });
      }

      onClose();
      refreshFeedbacks();
    } catch (error) {
      console.error("Could not submit feedback:", error);
      toast({
        title: "失敗しました。",
        description: "再試行してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>フィードバックを入力</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={6}>
            <FormLabel>5段階評価（1低評価~5高評価）</FormLabel>
            <Slider
              aria-label="slider-ex-1"
              value={ratingInput || 0}
              min={1}
              max={5}
              step={1}
              onChange={(val) => setRatingInput(val)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Box display="flex" justifyContent="space-between">
              <Text fontSize="sm">1</Text>
              <Text fontSize="sm">2</Text>
              <Text fontSize="sm">3</Text>
              <Text fontSize="sm">4</Text>
              <Text fontSize="sm">5</Text>
            </Box>
          </FormControl>
          <FormControl>
            <FormLabel>フィードバック内容</FormLabel>
            <Textarea
              placeholder="例：トークスクリプトに沿って、商談を進められたのは良かったです。お客様の話す内容をもっと注意深く聞くようにしてください。"
              value={feedbackInput}
              onChange={(e) => setFeedbackInput(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={submitFeedback}>
            保存
          </Button>
          <Button variant="ghost" onClick={onClose}>
            キャンセル
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
