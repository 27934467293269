/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Input,
  Heading,
  ButtonProps,
  Textarea,
  FormControl,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";
import { useMessage } from "./MessageContext";

const Container = Box;

const StyledButton: React.FC<ButtonProps & { isLoading?: boolean }> = ({
  isLoading,
  ...props
}) => (
  <Button
    {...props}
    sx={{
      width: "25%",
      height: "48px",
      fontSize: "18px",
    }}
  >
    {isLoading ? <Spinner /> : props.children}
  </Button>
);

const CreateTalkScript: React.FC = () => {
  const [talkScriptName, setTalkScriptName] = useState("");
  const [purpose, setPurpose] = useState("");
  const { loading, setLoading } = useLoading();
  const { setMessage } = useMessage();
  const navigate = useNavigate();

  const createInitialNode = async (talkScriptId: string) => {
    try {
      const response = await apiClient.post("/api/talks/", {
        talk_script: talkScriptId,
        node_type: "initial",
      });
      const initialNodeId = response.data.id;
      await apiClient.patch(`/api/talk_scripts/${talkScriptId}/`, {
        initial_node: initialNodeId,
      });
    } catch (error) {
      console.error("Error creating initial node:", error);
    }
  };

  //トークスクリプトの作成
  const handleCreateNewTalkScript = async () => {
    if (!talkScriptName.trim() || !purpose.trim()) {
      setMessage(
        "トークスクリプト名、達成したい目的は必須入力項目です。",
        "error"
      );
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await apiClient.post("/api/talk_scripts/", {
        name: talkScriptName,
        purpose: purpose,
      });
      const newTalkScriptId = response.data.id;
      setTalkScriptName("");
      setPurpose("");
      await createInitialNode(newTalkScriptId);
      navigate(`/script/${newTalkScriptId}?viewMode=false`);
    } catch (error) {
      console.error("Error creating new talk script:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/dashboard?tab=talkscript");
  };

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Heading as="h2" size="lg" mb={8}>
        新しいトークスクリプト
      </Heading>
      <FormControl id="talkScriptName" mb={6} width="70%">
        <FormLabel>トークスクリプト名</FormLabel>
        <Input
          placeholder="例：電話でのアウトバウンドコール"
          type="text"
          size="lg"
          value={talkScriptName}
          onChange={(e) => setTalkScriptName(e.target.value)}
        />
      </FormControl>
      <FormControl id="purpose" mb="8" width="70%">
        <FormLabel>達成したい目的</FormLabel>
        <Textarea
          placeholder="例：商談アポイントを獲得すること"
          size="lg"
          value={purpose}
          onChange={(e) => setPurpose(e.target.value)}
        />
      </FormControl>
      <StyledButton
        onClick={handleCreateNewTalkScript}
        isDisabled={loading}
        isLoading={loading}
        backgroundColor="#DB793D"
        color="white"
        _hover={{ opacity: 0.7 }}
      >
        始める
      </StyledButton>
      <StyledButton
        onClick={handleCancel}
        isDisabled={loading}
        backgroundColor="#9e9e9e"
        color="white"
        _hover={{ opacity: 0.7 }}
        mt={5}
      >
        キャンセル
      </StyledButton>
    </Container>
  );
};

export default CreateTalkScript;
