/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Stack,
  useToast,
} from "@chakra-ui/react";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";

type ShareReportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  reportId: string;
  companyId: string | undefined;
};

type User = {
  id: number;
  username: string;
  email: string;
};

type UserRole = {
  role: string;
  user: User;
};

type Company = {
  admin: number;
  max_users: number;
  company_users: UserRole[];
};

const ShareReportDialog: React.FC<ShareReportDialogProps> = ({
  isOpen,
  onClose,
  reportId,
  companyId,
}) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [reportUsers, setReportUsers] = useState<number[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const toast = useToast();
  const dummyRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (isOpen) {
      apiClient
        .get(`api/companies/${companyId}/`)
        .then((response) => {
          setCompany(response.data);
        })
        .catch((error) => console.error(error));

      apiClient
        .get(`api/reviews/reports/${reportId}/`)
        .then((response) => {
          setReportUsers(response.data.reviewers);
        })
        .catch((error) => console.error(error));
    }
  }, [isOpen, companyId, reportId]);

  useEffect(() => {
    setSelectedUsers(reportUsers);
  }, [reportUsers]);

  const handleCheckboxChange = (userId: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const handleShareClick = () => {
    setLoading(true);
    apiClient
      .put(`/api/reviews/reports/${reportId}/`, {
        reviewer_ids: selectedUsers,
      })
      .then(() => {
        toast({
          title: "共有設定に成功しました",
          description:
            "選択・選択解除されたユーザーとの共有を設定いたしました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "共有に失敗しました",
          description: "何か問題が発生しました。再度お試しください。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={dummyRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>レポートの共有・解除</AlertDialogHeader>
          <AlertDialogBody>
            <Stack spacing={3}>
              {company?.company_users.map(
                (companyUser) =>
                  loggedInUser?.id !== companyUser.user.id && (
                    <Checkbox
                      key={companyUser.user.id}
                      isChecked={selectedUsers.includes(companyUser.user.id)}
                      onChange={() => handleCheckboxChange(companyUser.user.id)}
                    >
                      {" "}
                      {companyUser.user.username}
                    </Checkbox>
                  )
              )}
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>キャンセル</Button>
            <Button colorScheme="blue" onClick={handleShareClick} ml={3}>
              共有
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ShareReportDialog;
