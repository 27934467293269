import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";

const WaitingApproval: React.FC = () => {
  return (
    <VStack
      spacing={4}
      justify="center"
      align="center"
      height="100%"
      width="80%"
      margin="auto"
    >
      <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
        <Text fontSize="xl" color="teal.600">
          管理者によるチームへの承認待ちです。
        </Text>
        <Text mt={4}>
          Eメールアドレス、パスワードでサインアップしたお客様はメールアドレスの有効性確認メールを送信していますので、登録したメールアドレスの受信ボックスを確認し、有効性チェックを完了させて、お待ちください。
          管理者によるチームへの参加が許可されますと、ご登録いただいたメールアドレスに承認完了のメールが届きますので、この画面は一旦閉じてお待ちください。
        </Text>
      </Box>
    </VStack>
  );
};

export default WaitingApproval;
