/** @format */

import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  Text,
  Icon,
} from "@chakra-ui/react";
import styled from "styled-components";
import { FaGoogle } from "react-icons/fa";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "./firebaseConfig";
import { useAuth } from "./AuthProvider";
import { useMessage } from "./MessageContext";
import { useLoading } from "./LoadingContext";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1em;
`;

const FormBox = styled(Box)`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 2em;
  width: 100%;
  max-width: 400px;
`;

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { setMessage } = useMessage();
  const { checkToken, setIsAuthenticated, isAuthenticated } = useAuth();
  const { setLoading } = useLoading();

  // ログイン済みの場合、/dashboard/に遷移する
  useEffect(() => {
    setLoading(true);
    if (isAuthenticated) {
      // セッションストレージからリダイレクト先のURLを取得
      const redirectTo = sessionStorage.getItem("redirectTo");
      if (redirectTo) {
        navigate(redirectTo);
        sessionStorage.removeItem("redirectTo");
      } else {
        navigate("/dashboard");
      }
    }
    setLoading(false);
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setMessage("ログインに成功しました。", "success");
      setIsAuthenticated(true);
      navigate("/dashboard");
      // ログイン成功時に checkToken を呼び出す
      await checkToken();
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(axiosError);
      if (axiosError.response) {
        console.error("Error response:", axiosError.response);
      }
      setMessage(
        "ログインに失敗しました。Eメールアドレスとパスワードを確認してください。",
        "error"
      );
    }
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user) {
        setMessage("ログインに成功しました。", "success");
        setIsAuthenticated(true);
        await checkToken();
        navigate("/dashboard");
      }
    } catch (error) {
      setMessage("Googleログインに失敗しました。", "error");
    }
    setLoading(false);
  };

  return (
    <Container>
      <FormBox>
        <Text fontSize="2xl" mb={6}>
          ログイン
        </Text>
        <form onSubmit={handleSubmit}>
          <FormControl id="email" isRequired mb={4}>
            <FormLabel>Eメールアドレス</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          </FormControl>
          <FormControl id="password" isRequired mb={6}>
            <FormLabel>パスワード</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </FormControl>
          <Button
            type="submit"
            width="full"
            mb={4}
            backgroundColor="#DB793D"
            color="white"
            _hover={{ opacity: 0.7 }}
          >
            ログイン
          </Button>
          <Button
            leftIcon={<Icon as={FaGoogle} boxSize={6} />}
            type="button"
            onClick={handleGoogleLogin}
            backgroundColor="#4285F4"
            color="white"
            mb={4}
            _hover={{ opacity: 0.8 }}
            width="full"
          >
            Googleでログイン
          </Button>
        </form>
        <Text mt={2} fontSize="sm">
          <Link
            color="teal.500"
            href="https://site.talkscript.app/terms-of-service"
            isExternal
          >
            利用規約
          </Link>{" "}
          と
          <Link
            color="teal.500"
            href="https://site.talkscript.app/privacy-policy"
            isExternal
          >
            プライバシーポリシー
          </Link>
          に同意してログインします
        </Text>
        <Text mt={2}>
          <Link color="teal.500" href="/forgot-password">
            パスワードをお忘れですか？
          </Link>
        </Text>
      </FormBox>
    </Container>
  );
};

export default Login;
