/** @format */

import apiClient from "./apiClient";

export const speak = (text: string, gender: string, callback?: () => void) => {
  // エンドポイントのURL
  const ttsEndpoint = "/api/common/google-text-to-speech/";

  // リクエストのボディ
  const requestBody = {
    text: text,
    gender: gender,
  };

  // サーバサイドのエンドポイントにリクエストを送信
  apiClient
    .post(ttsEndpoint, requestBody, { responseType: "blob" })
    .then((response) => {
      const audioBlob = response.data;
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.onended = () => {
        if (callback) {
          callback();
        }
      };
      audio.play();
    })
    .catch((error) => {
      console.error("Error fetching TTS:", error);
    });
};
