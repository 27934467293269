import React, { createContext, useContext, useState } from "react";

interface MessageContextValue {
  message: string;
  status: "info" | "success" | "warning" | "error";
  setMessage: (
    message: string,
    status?: "info" | "success" | "warning" | "error",
    timeout?: number
  ) => void;
}

const MessageContext = createContext<MessageContextValue | null>(null);

export const useMessage = (): MessageContextValue => {
  const context = useContext(MessageContext);
  if (context === null) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};

interface MessageProviderProps {
  children: React.ReactNode;
}

export const MessageProvider: React.FunctionComponent<MessageProviderProps> = ({
  children,
}) => {
  const [message, setMessageState] = useState("");
  const [status, setStatus] = useState<
    "info" | "success" | "warning" | "error"
  >("info");

  const setMessage = (
    message: string,
    status: "info" | "success" | "warning" | "error" = "info",
    timeout: number = 4000
  ) => {
    setMessageState(message);
    setStatus(status);
    setTimeout(() => {
      setMessageState("");
    }, timeout);
  };

  return (
    <MessageContext.Provider value={{ message, status, setMessage }}>
      {children}
    </MessageContext.Provider>
  );
};
