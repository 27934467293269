import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebaseの設定
const firebaseConfig = {
  apiKey: "AIzaSyA5flLdurj1imdpPqWx8b0Y6RmJdZ6kUu4",
  authDomain: "talkscripteeapi20230420.firebaseapp.com",
  projectId: "talkscripteeapi20230420",
  storageBucket: "talkscripteeapi20230420.appspot.com",
  messagingSenderId: "820312785713",
  appId: "1:820312785713:web:c94b28898c29bc84c9e610",
  measurementId: "G-08LHVDQGCQ",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
