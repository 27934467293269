/** @format */

import React, { createContext, useContext, useState, useEffect } from "react";
import apiClient from "./apiClient";
import { auth } from "./firebaseConfig";
import { useMessage } from "./MessageContext";

interface Company {
  id: number;
  name: string;
  industry: string;
  can_score_deals: boolean;
  is_dormant: boolean;
}

interface CompanyUser {
  role: string;
  class_type: string;
}
interface User {
  id: number;
  email: string;
  username: string;
  provider: string;
  plan: string;
  email_verified: boolean;
  company: Company | null;
  is_company_admin: boolean;
  company_user: CompanyUser | null;
  is_approval: boolean;
}
interface AuthContextValue {
  isAuthenticated: boolean;
  user: User | null;
  checkToken: () => Promise<void>;
  setIsAuthenticated: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { setMessage } = useMessage();

  //トークンの有効性チェック
  const checkToken = async () => {
    const firebaseUser = auth.currentUser;
    if (firebaseUser && firebaseUser.email) {
      await firebaseUser.reload();
      const email = firebaseUser.email;
      const email_verified = firebaseUser.emailVerified;
      try {
        const response = await apiClient.post("/api/users/profiles/fuid/", {
          firebase_uid: firebaseUser.uid,
        });

        if (!response.data.is_approval) {
          // 未承認の場合、ログアウト処理を実行
          auth.signOut();
          setIsAuthenticated(false);
          setMessage(
            "管理者によるチームへの承認が完了していません。管理者にチームへの参加承認を依頼してください。",
            "info"
          );
          return;
        }

        let userCompany = null;
        let companyUser = null;
        let is_company_admin = false;
        if (
          response.data.plan === "team" ||
          response.data.plan === "enterprise"
        ) {
          const [companyResponse, companyUserResponse] = await Promise.all([
            apiClient.get("/api/companies/user_company/"),
            apiClient.get(
              `/api/companies/company_user_details/${response.data.id}/`
            ),
          ]);

          userCompany = {
            id: companyResponse.data[0].id,
            name: companyResponse.data[0].name,
            industry: companyResponse.data[0].industry,
            can_score_deals: companyResponse.data[0].can_score_deals,
            is_dormant: companyResponse.data[0].is_dormant,
          };
          companyUser = companyUserResponse.data;
          is_company_admin = companyResponse.data[0].admin === response.data.id;
          if (userCompany.is_dormant) {
            auth.signOut();
            setIsAuthenticated(false);
            setMessage("休止期間中です。管理者にお問い合わせ下さい。", "error");
            return;
          }
        }
        setUser({
          id: response.data.id,
          email: email,
          username: response.data.username,
          provider: response.data.provider,
          plan: response.data.plan,
          email_verified: email_verified,
          company: userCompany,
          is_company_admin: is_company_admin,
          company_user: companyUser,
          is_approval: response.data.is_approval,
        });
        setIsAuthenticated(true);
      } catch (error) {
        console.error("ユーザー情報の取得に失敗しました。", error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        await checkToken();
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        checkToken,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
