/** @format */

import React, { useState, useEffect, useRef } from "react";
import { format, parseISO } from "date-fns";
import {
  IconButton,
  Text,
  VStack,
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useToast,
  Button,
  Badge,
  chakra,
} from "@chakra-ui/react";
import {
  MdMic,
  MdMicNone,
  MdDeleteForever,
  MdOutlineVolumeUp,
} from "react-icons/md";
import AudioPlayer from "react-h5-audio-player";
import styled, { keyframes, css } from "styled-components";
import "react-h5-audio-player/lib/styles.css";
import apiClient from "./apiClient";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";

interface RecorderProps {
  talk_id: string;
  talk_script_id: string;
}

interface Utterance {
  id: number;
  url: string;
  transcribed_text?: string;
  similarity_percentage?: number;
  talk: string;
  talk_script: string;
  created_at: string;
}

interface TranscriptComparisonResult {
  compared: string;
}

const BlinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;

const ChakraMdOutlineVolumeUp = chakra(MdOutlineVolumeUp);
const BlinkingIcon = styled(ChakraMdOutlineVolumeUp)`
  animation: ${css`
    ${BlinkAnimation} 2s infinite
  `};
`;

const Recorder: React.FC<RecorderProps> = ({ talk_id, talk_script_id }) => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunks = useRef<Blob[]>([]);
  const [utterance, setUtterance] = useState<Utterance | null>(null);
  const { loading, setLoading } = useLoading();
  const [isPlaying, setIsPlaying] = useState(false);
  const [savingRecording, setSavingRecording] = useState(false);
  const [isCompared, setIsCompared] = useState(false);
  const [isTextOpen, setIsTextOpen] = useState(false);
  const [selectedTranscript, setSelectedTranscript] =
    useState<TranscriptComparisonResult | null>(null);
  const toast = useToast();
  const { user } = useAuth();

  const fetchUtterance = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/utterances/list/${talk_id}/`);
      if (response.data && response.data.length > 0) {
        setUtterance(response.data[0]);
        if (!response.data[0].transcribed_text) {
          const notTranscribedTextHtml = `
      <div style='background-color: #ffffff; padding: 10px; border-radius: 8px;'>
      <span style="font-weight: bold; color: #2a0cb0;">一致度</span>ボタンで、トークスクリプトとの一致度を計測
      </div>`;

          setSelectedTranscript({
            compared: notTranscribedTextHtml,
          });
          setIsCompared(false);
        } else {
          const transcribedTextHtml = `
      <div style='background-color: #f1f2e4; padding: 10px; border-radius: 8px;'>
        ${response.data[0].transcribed_text}
      </div>`;
          setSelectedTranscript({ compared: transcribedTextHtml });
          if (response.data[0].similarity_percentage) {
            setIsCompared(true);
          }
        }
        setIsTextOpen(true);
      } else {
        setUtterance(null);
        setIsTextOpen(false);
        setSelectedTranscript(null);
        setIsCompared(false);
      }
    } catch (error) {
      console.error("Error fetching utterance:", error);
      setUtterance(null);
      setIsTextOpen(false);
      setSelectedTranscript(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUtterance();
    setIsPlaying(false);
  }, [talk_id]);

  const startRecording = async () => {
    const micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const mediaRecorder = new MediaRecorder(micStream);

    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.onstop = async () => {
      // 録音停止時の処理を変更
      if (recordedChunks.current.length > 0) {
        const mimeType = recordedChunks.current[0].type;
        if (!mimeType) {
          console.error("録音データの形式が確認できません。");
          toast({
            title: "録音エラー",
            description: "録音データの形式が確認できません。",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        const blob = new Blob(recordedChunks.current, { type: mimeType });
        await sendAudioToServer(blob);
      }
      recordedChunks.current = [];
    };

    mediaRecorder.start();
    setRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);

      // Stop all tracks to release the microphone
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  const sendAudioToServer = async (blob: Blob) => {
    // MIMEタイプからファイル拡張子を取得
    const fileExtension = blob.type.split("/")[1];

    const formData = new FormData();
    formData.append("file", blob, `recording.${fileExtension}`);
    formData.append("mime_type", blob.type);
    formData.append("talk_id", talk_id);
    formData.append("talk_script_id", talk_script_id);

    setLoading(true);
    setSavingRecording(true);
    try {
      await apiClient.post("/api/utterances/", formData);

      apiClient.post("/api/cohort_analysis_logs/create_log/", {
        company: user?.company?.id,
        event_type: "recording",
        additional_info: {
          talk_script_id: talk_script_id,
          talk_id: talk_id,
        },
      });
      // サーバーから最新のUtteranceデータをフェッチ
      await fetchUtterance();
      toast({
        title: "保存成功",
        description: "録音データが正常に保存されました。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "保存失敗",
        description: "録音データの保存に失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSavingRecording(false);
      setLoading(false);
    }
  };

  const fetchSimilarityPercentage = async (utteranceId: number) => {
    try {
      setLoading(true);
      const response = await apiClient.get(
        `/api/utterances/compare/${utteranceId}/`
      );
      if (response.status === 200) {
        await fetchUtterance();
      }
    } catch (error) {
      console.error("Error fetching similarity:", error);
      toast({
        title: "取得失敗",
        description: "一致度の取得に失敗しました。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteUtterance = async (utteranceId: number) => {
    // ユーザーに削除の確認を求める
    const isConfirmed = window.confirm(
      "この発話データを削除してもよろしいですか？"
    );

    if (isConfirmed) {
      try {
        setLoading(true);
        await apiClient.delete(`/api/utterances/${utteranceId}/`);
        toast({
          title: "削除成功",
          description: "発話データが正常に削除されました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setUtterance(null);
        setIsTextOpen(false);
        setIsCompared(false);
        setSelectedTranscript(null);
        await fetchUtterance();
      } catch (error) {
        console.error("Error deleting utterance:", error);
        toast({
          title: "削除失敗",
          description: "発話データの削除に失敗しました。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePlayClick = async () => {
    if (utterance) {
      setIsPlaying(true);
    }
  };

  return (
    <>
      {savingRecording && (
        <Modal isOpen={savingRecording} onClose={() => {}}>
          <ModalOverlay />
          <ModalContent backgroundColor="white" mx="auto" my={6}>
            <ModalHeader>録音データを保存中</ModalHeader>
            <ModalBody textAlign="center" mb={4}>
              <Text>保存しています…</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Flex
        direction="column"
        border="1px solid black"
        borderRadius="lg"
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.9)"
        backgroundColor="white"
        width="320px"
      >
        {isTextOpen && (
          <Flex direction="column" align="center" p={2} borderRadius="lg">
            <Flex
              justify="space-between"
              align="center"
              w="full"
              position="relative"
              mb={2}
            >
              <Flex align="center" ml={2}>
                <Text fontSize="lg" fontWeight="bold">
                  あなたの発話
                </Text>
              </Flex>
              <Flex mr={2}>
                <Button
                  bg="#d66af7"
                  color="white"
                  fontSize="sm"
                  height="30px"
                  width="80px"
                  boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
                  _hover={{ opacity: 0.7 }}
                  onClick={() => {
                    if (
                      utterance &&
                      utterance.id &&
                      utterance.similarity_percentage === null
                    ) {
                      fetchSimilarityPercentage(utterance.id);
                    }
                  }}
                  isDisabled={isCompared || loading}
                >
                  一致度
                </Button>
              </Flex>
            </Flex>
            <Box w="full">
              <Box
                dangerouslySetInnerHTML={{
                  __html: selectedTranscript?.compared || "",
                }}
                p={2}
              />
              <Flex direction="column" align="center">
                {utterance?.similarity_percentage != null && (
                  <Flex align="center">
                    <Badge
                      ml={2}
                      colorScheme="blue"
                      px={2}
                      py={1}
                      borderRadius="full"
                    >
                      お手本一致 {utterance.similarity_percentage}%
                    </Badge>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Flex>
        )}
        <Flex direction="row" justify="space-between" align="center" w="full">
          <VStack p={4} m="auto" w="26%">
            {!recording ? (
              <IconButton
                icon={<MdMic />}
                size="lg"
                colorScheme="blue"
                aria-label="Start Recording"
                onClick={startRecording}
              />
            ) : (
              <IconButton
                icon={<MdMicNone />}
                size="lg"
                colorScheme="red"
                aria-label="Stop Recording"
                onClick={stopRecording}
              />
            )}
            <Text fontSize="lg" fontWeight="bold" color="gray.500">
              {recording ? "停止" : "録音"}
            </Text>
          </VStack>
          <Box w="74%">
            <Flex alignItems="center" mt={1} ml={1} mr={2}>
              <Text fontSize="sm" fontWeight="bold" color="gray.500" flex="1">
                {utterance
                  ? `記録：${format(
                      parseISO(utterance.created_at),
                      "yyyy/MM/dd HH:mm"
                    )}`
                  : "発話データが存在しません"}
                {utterance && (
                  <IconButton
                    aria-label="Delete utterance"
                    icon={<MdDeleteForever size="24px" />}
                    onClick={() => deleteUtterance(utterance.id)}
                    color="red.400"
                    variant="ghost"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                  />
                )}
              </Text>
            </Flex>
            <Flex alignItems="center" mb={2} ml={1} mr={2}>
              {utterance && (
                <AudioPlayer
                  src={utterance.url}
                  onPlay={handlePlayClick}
                  autoPlayAfterSrcChange={false}
                  showSkipControls={false}
                  showJumpControls={false}
                />
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Recorder;
