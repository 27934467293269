/** @format */

import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  Text,
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  CircularProgress,
  useToast,
  Image,
  chakra,
} from "@chakra-ui/react";
import { MdOutlineVolumeUp } from "react-icons/md";
import { FiCircle } from "react-icons/fi";
import {
  BsFillMicFill,
  BsStopCircleFill,
  BsFillSignStopFill,
} from "react-icons/bs";
import styled, { keyframes, css } from "styled-components";
import apiClient from "./apiClient";
import { speak } from "./SpeechUtils";
import mypicIcon from "./assets/mypic.png";
import clipicIcon from "./assets/clipic.png";

const StyledText = styled.p<{ color?: string; fontWeight?: string }>`
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 18px;
  color: ${(props) => props.color || "inherit"};
  font-weight: ${(props) => props.fontWeight || "inherit"};
`;

const BlinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 660px;
  height: 100%;
`;

const ChatContainer = styled(Box)`
  position: absolute;
  display: flex;
  margin-top: 106px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  max-height: calc(100% - 188px);
  width: 96%;
  margin-bottom: 226px;
`;

const VoiceContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 104px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  button {
    height: 100%;
  }
`;

const MessageBubble = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 60%;
  position: relative;
  display: flex;
  align-items: center;
`;

const UserMessage = styled(MessageBubble)`
  background-color: #d7f0f5;
  align-self: flex-start;
`;

const AssistantMessage = styled(MessageBubble)`
  background-color: #e7dff7;
  align-self: flex-end;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.role === "user" ? "flex-start" : "flex-end"};
  align-items: center;
  width: 100%;
  margin: 5px 0;
`;

const ChakraMdOutlineVolumeUp = chakra(MdOutlineVolumeUp);
const BlinkingIcon = styled(ChakraMdOutlineVolumeUp)`
  animation: ${css`
    ${BlinkAnimation} 2s infinite
  `};
`;

interface DialogueProps {
  dialogueHistoryId: number | null;
}

interface DialogueHistory {
  expected_question_id: number;
  expected_question_question: string;
  talk_id: number;
  talk_content: string;
  history: {
    messages: Array<{ role: string; content: string }>;
  };
}

const Dialogue: React.FC<DialogueProps> = ({ dialogueHistoryId }) => {
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunks = useRef<Blob[]>([]);
  const [dialogueHistory, setDialogueHistory] =
    useState<DialogueHistory | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [underGenProcess, setUnderGenProcess] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [instructionModal, setInstructionModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("まだ話さないでください");
  const toast = useToast();

  //必要情報のフェッチ
  const fetchDialogueHistory = async (id: number) => {
    try {
      const response = await apiClient.get(
        `/api/expected_questions/dialogue_histories/${id}/`
      );
      setDialogueHistory(response.data);
    } catch (error) {
      console.error("Error fetching dialogue history:", error);
    }
  };

  // dialogueHistoryIdの存在を確認
  useEffect(() => {
    if (dialogueHistoryId) {
      fetchDialogueHistory(dialogueHistoryId);
    }
  }, [dialogueHistoryId]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [dialogueHistory]);

  //音声のテキスト化
  const sendVoiceDataToAPI = async (blob: Blob) => {
    try {
      const formData = new FormData();
      if (dialogueHistoryId !== null) {
        const mimeType =
          recordedChunks.current && recordedChunks.current.length > 0
            ? recordedChunks.current[0].type
            : null;
        if (!mimeType) {
          console.error("MIME type is missing from the recorded chunks");
          return;
        }
        formData.append("audio", blob, "voice.webm");
        formData.append("dialogue_history_pk", dialogueHistoryId.toString());
        formData.append("mime_type", mimeType);
      } else {
        console.error("dialogueHistoryId is null");
        return;
      }

      const response = await apiClient.post(
        "/api/expected_questions/dialogue_histories/voice/",
        formData,
        {
          headers: {
            ...apiClient.defaults.headers.common,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.status) {
        if (response.data.status === "history_updated_with_voice_text") {
          fetchDialogueHistory(dialogueHistoryId);
          await updateHistoryWithGPT(dialogueHistoryId);
        }
      }
    } catch (error) {
      console.error("Error sending voice data:", error);
      toast({
        title: "エラーが発生しました。",
        description:
          "発話データのデジタル化に失敗しました。60秒以内で話す内容をまとめ、周囲のノイズに注意して、もう一度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setUnderGenProcess(false);
    }
  };

  //返答の生成
  const updateHistoryWithGPT = async (historyId: number) => {
    try {
      const response = await apiClient.post(
        `/api/expected_questions/dialogue_histories/update_with_ai/`,
        { dialogue_history_pk: historyId }
      );
      if (response.data && response.data.status === "success") {
        if (dialogueHistoryId) {
          fetchDialogueHistory(dialogueHistoryId);
        }
        const filteredContent = filterSystemText(
          response.data.generated_content
        );
        setIsSpeaking(true);
        speak(filteredContent, "male", () => setIsSpeaking(false));
        setUnderGenProcess(false);
      } else {
        // 必要に応じてエラーメッセージや処理を追加
        console.error("Failed to update history with GPT:", response.data);
        toast({
          title: "エラーが発生しました。",
          description:
            "返答の生成に失敗しました。何度かお試しいただき、解決しない場合にはサポートにお知らせください。",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        setUnderGenProcess(false);
      }
    } catch (error) {
      console.error("Error updating history with GPT:", error);
      toast({
        title: "エラーが発生しました。",
        description:
          "返答の生成に失敗しました。何度かお試しいただき、解決しない場合にはサポートにお知らせください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setUnderGenProcess(false);
    }
  };

  //録音処理
  const startRecording = async () => {
    setModalMessage("話してください");
    setInstructionModal(true);
    setTimeout(() => {
      setInstructionModal(false);
    }, 1000);

    const micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const mediaRecorder = new MediaRecorder(micStream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.start();
    setRecording(true);
  };

  const stopRecording = () => {
    setRecording(false);
    setUnderGenProcess(true);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunks.current);
        sendVoiceDataToAPI(blob);
        recordedChunks.current = [];
      };
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  };

  const filterSystemText = (text: string) => {
    const systemTextStart = text.indexOf("#前述の文章に対して");
    const expectedQuestionBackgroundStart =
      text.indexOf("#対話に関する補足情報");

    if (expectedQuestionBackgroundStart !== -1) {
      return text.substring(0, expectedQuestionBackgroundStart).trim();
    }

    if (systemTextStart !== -1) {
      return text.substring(0, systemTextStart).trim();
    }

    return text;
  };

  return (
    <Container>
      <ChatContainer ref={chatContainerRef}>
        {dialogueHistory && (
          <VStack spacing={12} align="stretch">
            {dialogueHistory.history.messages.slice(1).map((message, index) => {
              const filteredContent = filterSystemText(message.content);
              return message.role === "user" ? (
                <MessageWrapper role="user" key={index}>
                  <Image
                    src={mypicIcon}
                    alt="マイイメージ"
                    boxSize="48px"
                    style={{ marginRight: "12px" }}
                  />
                  <UserMessage>{filteredContent}</UserMessage>
                </MessageWrapper>
              ) : (
                <MessageWrapper role="assistant" key={index}>
                  <AssistantMessage>{filteredContent}</AssistantMessage>
                  <Image
                    src={clipicIcon}
                    alt="クライアントイメージ"
                    boxSize="48px"
                    style={{ marginLeft: "12px" }}
                  />
                </MessageWrapper>
              );
            })}
          </VStack>
        )}
      </ChatContainer>

      <VoiceContainer>
        {!recording && !underGenProcess ? (
          <Button
            mt={6}
            p={6}
            colorScheme="blue"
            size="md"
            fontSize="20px"
            borderRadius="50"
            boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)"
            leftIcon={<BsFillMicFill size="24px" />}
            onClick={startRecording}
          >
            AI顧客に発話
          </Button>
        ) : underGenProcess ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress isIndeterminate color="blue.400" size="32px" />
            <Text fontSize="20px" fontWeight="bold" marginTop="8px">
              AI思考中
            </Text>
          </Box>
        ) : (
          <Button
            mt={6}
            p={6}
            colorScheme="red"
            size="md"
            fontSize="20px"
            borderRadius="50"
            boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)"
            leftIcon={<BsStopCircleFill size="24px" />}
            onClick={stopRecording}
          >
            発話終了
          </Button>
        )}
      </VoiceContainer>

      <Modal isOpen={isSpeaking} onClose={() => setIsSpeaking(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <BlinkingIcon boxSize={24} mx="auto" />
          </ModalHeader>
          <ModalBody>
            <StyledText>AIが返答しています</StyledText>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={instructionModal}
        onClose={() => setInstructionModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={5}
          >
            <ModalBody>
              {modalMessage === "まだ話さないでください" ? (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={4}>
                    <BsFillSignStopFill size="100" color="#d9626d" />
                  </Box>
                  <StyledText color="#d9626d" fontWeight="bold">
                    {modalMessage}
                  </StyledText>
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={4}>
                    <FiCircle size="100" color="#6bd6c8" />
                  </Box>
                  <StyledText color="#6bd6c8" fontWeight="bold">
                    {modalMessage}
                  </StyledText>
                </Flex>
              )}
            </ModalBody>
          </Flex>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Dialogue;
