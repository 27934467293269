/** @format */

import axios from "axios";
import getCookieValue from "./getCookieValue";
import { auth } from "./firebaseConfig";

// ローカル環境
/*
const apiClient = axios.create({
  baseURL: "http://localhost:8000/",
  withCredentials: true,
});
*/

//ステージング環境
/*
const apiClient = axios.create({
  baseURL:
    "https://staging-backend-dot-talkscripteeapi20230420.an.r.appspot.com/",
  withCredentials: true,
});
*/

//本番環境
const apiClient = axios.create({
  baseURL: "https://backend-dot-talkscripteeapi20230420.an.r.appspot.com/",
  withCredentials: true,
});

apiClient.interceptors.request.use(
  async (config) => {
    // Firebase SDKを使用してトークンを取得
    const firebaseUser = auth.currentUser;
    if (firebaseUser) {
      const token = await firebaseUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }

    // CSRFトークンをヘッダーに追加
    config.headers["X-CSRFToken"] = getCookieValue("csrftoken");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
