/** @format */

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Box,
  VStack,
  HStack,
  IconButton,
  Tag,
  TagLabel,
  InputLeftElement,
  InputGroup,
  Icon,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaTag } from "react-icons/fa";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";

interface TagManagementModalProps {
  closeTagForm: () => void;
  isVisible: boolean;
}

interface DemoTag {
  id: number;
  name: string;
}

const TagManagementModal: React.FC<TagManagementModalProps> = ({
  isVisible,
  closeTagForm,
}) => {
  const [tags, setTags] = useState<DemoTag[]>([]);
  const [inputValue, setInputValue] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    if (user?.company?.id) {
      fetchTags();
    }
  }, [user?.company?.id]);

  const fetchTags = async () => {
    const response = await apiClient.get(
      `/api/demonstrations/tags/${user?.company?.id}/`
    );
    setTags(response.data);
  };

  const addTag = async (tagName: string) => {
    const tagExists = tags.some((tag) => tag.name === tagName);
    if (!tagExists) {
      await createNewTag(tagName);
      setInputValue("");
    }
  };

  const createNewTag = async (tagName: string) => {
    if (user?.company?.id) {
      try {
        await apiClient.post(`/api/demonstrations/tags/${user.company.id}/`, {
          name: tagName,
        });
        await fetchTags();
      } catch (error) {
        console.error("Failed to create new tag:", error);
      }
    }
  };

  const handleDeleteTag = async (tagId: number) => {
    try {
      await apiClient.delete(`/api/demonstrations/tags/${tagId}/delete/`);
      await fetchTags();
    } catch {
      console.error("Failed to delete tag");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (inputValue) {
      addTag(inputValue);
    }
  };

  return (
    <Modal isOpen={isVisible} onClose={closeTagForm}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>タグ管理</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="start" spacing={2} mb={4}>
            <Box as="form" onSubmit={handleFormSubmit} width="100%">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={FaTag} />}
                />
                <Input
                  placeholder="タグを入力してEnterで追加"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && inputValue) {
                      e.preventDefault();
                      addTag(inputValue);
                    }
                  }}
                />
              </InputGroup>
            </Box>
            <HStack spacing={4} wrap="wrap" mt={4} mb={4} align="center">
              {tags.map((tag) => (
                <Tag size="md" key={tag.id} borderRadius="lg" mt={2} mb={2}>
                  <TagLabel>{tag.name}</TagLabel>
                  <IconButton
                    aria-label={`Remove tag ${tag.name}`}
                    icon={<CloseIcon />}
                    color="red.400"
                    size="xs"
                    ml={1}
                    onClick={() => handleDeleteTag(tag.id)}
                  />
                </Tag>
              ))}
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TagManagementModal;
