/** @format */

import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  VStack,
  Text,
  IconButton,
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  CircularProgress,
  useToast,
  chakra,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { MdOutlineVolumeUp } from "react-icons/md";
import { FiCircle } from "react-icons/fi";
import { FcAssistant, FcManager } from "react-icons/fc";
import {
  BsFillMicFill,
  BsStopCircleFill,
  BsFillSignStopFill,
} from "react-icons/bs";
import styled, { keyframes, css } from "styled-components";
import axios, { AxiosError } from "axios";
import apiClient from "./apiClient";
import { speak } from "./SpeechUtils";
import { useAuth } from "./AuthProvider";

const Container = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-left: 2em;
  padding-right: 2em;
  overflow: hidden;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6em;
  font-weight: bold;
  text-align: left;
  width: 100%;
  background-color: white;
  margin-top: 36px;
  padding: 0 18px;
`;

const ChatContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  height: 100vh;
  padding-left: 26px;
  padding-right: 26px;
  margin-top: 36px;
`;

const VoiceContainer = styled(Box)`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    border-radius: 50px;
    padding: 36px;
    margin-top: 36px;
    margin-bottom: 36px;
  }
`;

const StyledText = styled.p<{ color?: string; fontWeight?: string }>`
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 18px;
  color: ${(props) => props.color || "inherit"};
  font-weight: ${(props) => props.fontWeight || "inherit"};
`;

const BlinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;

const MessageBubble = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  max-width: 60%;
  position: relative;
  display: flex;
  align-items: center;
`;

const UserMessage = styled(MessageBubble)`
  background-color: #ebfcdc;
  align-self: flex-start;
`;

const AssistantMessage = styled(MessageBubble)`
  background-color: #faebee;
  align-self: flex-end;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.role === "user" ? "flex-start" : "flex-end"};
  align-items: center;
  width: 100%;
  margin: 5px 0;
`;

const ModalContainer = styled.div`
  margin: 20px;
  background-color: #282c34;
  color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  font-family: "Courier New", Courier, monospace;
  overflow-y: auto;
  min-height: 200px;
  border: 1px solid #5a5d63;

  pre {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-x: hidden;
  }
`;

const ModalHeading = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #383c44;
  border-radius: 5px 5px 0 0;
  font-family: "Courier New", Courier, monospace;
`;

const ChakraMdOutlineVolumeUp = chakra(MdOutlineVolumeUp);
const BlinkingIcon = styled(ChakraMdOutlineVolumeUp)`
  animation: ${css`
    ${BlinkAnimation} 2s infinite
  `};
`;

interface Simulation {
  prompts: {
    messages: Array<{ role: string; content: string }>;
  };
  chat_content: string;
}

const Simulation = () => {
  const { id } = useParams();
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const toast = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();
  const recordedChunks = useRef<Blob[]>([]);
  const [simulation, setSimulation] = useState<Simulation | null>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [underGenProcess, setUnderGenProcess] = useState(false);
  const [summaryText, setSummaryText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCopied, setIsCopied] = useState(false);
  const [instructionModal, setInstructionModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("まだ話さないでください");

  const handleGoToDashboard = () => {
    navigate("/dashboard?tab=simulation");
  };

  const fetchSimulation = async (id: number) => {
    try {
      const response = await apiClient.get(`/api/simulation_sessions/${id}/`);
      if (!user || response.data.user !== user.id) {
        // simulationのuserとアクセスしているuserのidが一致しない場合
        navigate("/dashboard/");
        return;
      }
      setSimulation(response.data);
      // スクロールロジック
      if (chatContainerRef.current) {
        const container = chatContainerRef.current;
        container.scrollTop = container.scrollHeight;
      }
    } catch (error) {
      console.error("Error fetching simulation:", error);
    }
  };

  useEffect(() => {
    // idの存在を確認
    const idNumber = Number(id);
    if (idNumber) {
      fetchSimulation(idNumber);
    }
  }, [id]);

  const sendVoiceDataToAPI = async (blob: Blob) => {
    try {
      const formData = new FormData();
      if (id) {
        const mimeType =
          recordedChunks.current && recordedChunks.current.length > 0
            ? recordedChunks.current[0].type
            : null;
        if (!mimeType) {
          console.error("MIME type is missing from the recorded chunks");
          return;
        }
        formData.append("audio", blob, "voice.webm");
        formData.append("session_pk", id);
        formData.append("mime_type", mimeType);
      } else {
        console.error("id is null");
        return;
      }

      const response = await apiClient.post(
        "/api/simulation_sessions/voice-to-text/",
        formData,
        {
          headers: {
            ...apiClient.defaults.headers.common,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data.status) {
        if (response.data.status === "prompts_updated_voice_text") {
          // 成功した場合の処理：最新のSimulationSessionオブジェクトを取得
          const idNumber = Number(id);
          fetchSimulation(idNumber);
          // レスポンスを生成
          await updateHistoryWithGPT(idNumber);
        }
      }
    } catch (error) {
      console.error("Error sending voice data:", error);
      // エラーメッセージを設定
      toast({
        title: "エラーが発生しました。",
        description:
          "発話データのデジタル化に失敗しました。60秒以内で話す内容をまとめ、周囲のノイズに注意して、もう一度お試しください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setUnderGenProcess(false);
    }
  };

  const updateHistoryWithGPT = async (sessionId: number) => {
    try {
      const response = await apiClient.post(
        `/api/simulation_sessions/update/`,
        { session_pk: sessionId }
      );
      if (response.data && response.data.status === "success") {
        if (sessionId) {
          fetchSimulation(sessionId);
        }
        setIsSpeaking(true);
        speak(response.data.generated_prompts, "male", () =>
          setIsSpeaking(false)
        );
        setUnderGenProcess(false);
      } else {
        // 必要に応じてエラーメッセージや処理を追加
        console.error(
          "Failed to update simulation_sessions with GPT:",
          response.data
        );
        toast({
          title: "エラーが発生しました。",
          description:
            "返答の生成に失敗しました。何度かお試しいただき、解決しない場合にはサポートにお知らせください。",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        setUnderGenProcess(false);
      }
    } catch (error) {
      console.error("Error updating history with GPT:", error);
      toast({
        title: "エラーが発生しました。",
        description:
          "返答の生成に失敗しました。何度かお試しいただき、解決しない場合にはサポートにお知らせください。",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setUnderGenProcess(false);
    }
  };

  const startRecording = async () => {
    setModalMessage("まだ話さないでください");
    setInstructionModal(true);
    setTimeout(() => {
      // 1秒後にモーダルのメッセージを変更
      setModalMessage("話してください");
      setTimeout(() => {
        // さらに1秒後にモーダルを閉じる
        setInstructionModal(false);
      }, 1500);
    }, 1500);

    const micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const mediaRecorder = new MediaRecorder(micStream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    mediaRecorder.start();
    setRecording(true);
  };

  const stopRecording = () => {
    setRecording(false);
    setUnderGenProcess(true);
    setTimeout(() => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(recordedChunks.current);
          sendVoiceDataToAPI(blob);
          recordedChunks.current = [];
        };
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }, 300);
  };

  const handleGenerateSummary = async () => {
    setIsLoading(true);
    if (simulation && simulation.chat_content) {
      // simulationに対話内容が格納されている場合、それを要約としてセットする
      setSummaryText(simulation.chat_content);
      setIsLoading(false);
      onOpen();
    } else {
      try {
        const response = await apiClient.get(
          `/api/simulation_sessions/${id}/summary/`
        );

        if (response.status === 200) {
          const idNumber = Number(id);
          fetchSimulation(idNumber);
          setSummaryText(response.data);
          onOpen();
        } else {
          throw new Error("Non-200 response");
        }
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.status === 400
        ) {
          // 400 status codeのハンドリング
          toast({
            title: "対話が行われていません",
            description:
              "この機能は対話を要約する機能です。対話を行わないとこの機能を利用できません。",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          console.error("Error retrieving summary from server:", error);
          toast({
            title: "要約に失敗しました",
            description:
              "要約に失敗しました。時間を置いて、もう一度お試しください。",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top-right",
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const copyToClipboard = async (str: string) => {
    try {
      await navigator.clipboard.writeText(str);
    } catch (err) {
      console.error("Error copying text: ", err);
    }
  };

  // 使用方法:
  const handleCopy = () => {
    if (summaryText) {
      copyToClipboard(summaryText);
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Container>
      <Header>
        <Button colorScheme="gray" onClick={handleGoToDashboard}>
          ダッシュボードへ
        </Button>
        <div style={{ color: "#4299E1" }}>
          発話モードボタンを押して話してください
        </div>
        <Button
          colorScheme="teal"
          size="lg"
          marginLeft={4}
          isDisabled={isLoading}
          onClick={handleGenerateSummary}
        >
          {isLoading ? (
            <>
              <CircularProgress
                size="24px"
                trackColor="gray.200"
                color="blue.500"
                isIndeterminate
                mr={2}
              />
              処理中...
            </>
          ) : (
            "要約する"
          )}
        </Button>
      </Header>
      <ChatContainer ref={chatContainerRef}>
        {simulation && (
          <VStack spacing={14} align="stretch">
            {simulation.prompts.messages.slice(1).map((message, index) => {
              return message.role === "user" ? (
                <MessageWrapper role="user" key={index}>
                  <FcAssistant size={38} style={{ marginRight: "12px" }} />
                  <UserMessage>{message.content}</UserMessage>
                </MessageWrapper>
              ) : (
                <MessageWrapper role="assistant" key={index}>
                  <AssistantMessage>{message.content}</AssistantMessage>
                  <FcManager size={42} style={{ marginLeft: "12px" }} />
                </MessageWrapper>
              );
            })}
          </VStack>
        )}
      </ChatContainer>
      <VoiceContainer>
        {!recording && !underGenProcess ? (
          <Button
            colorScheme="blue"
            size="xl"
            fontSize="22px"
            boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)"
            leftIcon={<BsFillMicFill size="28px" />}
            onClick={startRecording}
          >
            押下で発話モード
          </Button>
        ) : underGenProcess ? (
          <Button
            colorScheme="white"
            size="xl"
            fontSize="22px"
            color="black"
            isDisabled={true}
          >
            <CircularProgress
              size="32px"
              trackColor="gray.200"
              color="blue.500"
              isIndeterminate
              mr={2}
            />
            AI思考中
          </Button>
        ) : (
          <Button
            colorScheme="red"
            size="xl"
            fontSize="22px"
            boxShadow="0px 6px 15px rgba(0, 0, 0, 0.25)"
            leftIcon={<BsStopCircleFill size="28px" />}
            onClick={stopRecording}
          >
            押下で発話終了
          </Button>
        )}
      </VoiceContainer>
      <Modal isOpen={isSpeaking} onClose={() => setIsSpeaking(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <BlinkingIcon boxSize={24} mx="auto" />
          </ModalHeader>
          <ModalBody>
            <StyledText>AIが返答しています</StyledText>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeading>
            <Box display="flex" alignItems="center">
              <Text color="#fff">要約と助言</Text>
              <Box ml={3}>
                {isCopied ? (
                  <Text color="#fff">コピーしました</Text>
                ) : (
                  <IconButton
                    aria-label="Copy"
                    icon={<CopyIcon />}
                    size="sm"
                    onClick={handleCopy}
                  />
                )}
              </Box>
            </Box>
            <ModalCloseButton color="#fff" ml={3} />
          </ModalHeading>
          <ModalBody>
            <ModalContainer>
              <pre>{summaryText}</pre>
            </ModalContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={instructionModal}
        onClose={() => setInstructionModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={5}
          >
            <ModalBody>
              {modalMessage === "まだ話さないでください" ? (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={4}>
                    <BsFillSignStopFill size="100" color="#d9626d" />
                  </Box>
                  <StyledText color="#d9626d" fontWeight="bold">
                    {modalMessage}
                  </StyledText>
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={4}>
                    <FiCircle size="100" color="#6bd6c8" />
                  </Box>
                  <StyledText color="#6bd6c8" fontWeight="bold">
                    {modalMessage}
                  </StyledText>
                </Flex>
              )}
            </ModalBody>
          </Flex>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Simulation;
