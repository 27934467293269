/** @format */

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Text, Badge, Flex, useToast } from "@chakra-ui/react";
import { FaTag, FaEdit, FaTrashAlt, FaLightbulb } from "react-icons/fa";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useAuth } from "./AuthProvider";
import { useLoading } from "./LoadingContext";
import apiClient from "./apiClient";
import DemonstrationForm from "./DemonstrationForm";
import NoPermission from "./NoPermission";

interface User {
  id: number;
  username: string;
}

interface DemoTag {
  id: number;
  name: string;
}

interface Demonstration {
  id: number;
  title: string;
  user: User;
  company: number;
  read_tags: DemoTag[];
  created_at: string;
  updated_at: string;
  demo_video_url: string;
  use_case: string;
}

interface ButtonProps {
  canEdit: boolean;
}

const DetailContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Header = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  padding: 26px;
`;

const SubHeader = styled(Flex)`
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 12px;
  padding: 6px;
  border-bottom: 1px solid #ddd;
`;

const VideoContainer = styled.div`
  width: 66%;
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  margin: 28px auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
`;

const TagContainer = styled.div`
  width: 90%;
  position: relative;
  margin: 0 auto 20px;
  margin-topd: 20px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: start;
  margin-bottom: 36px;
`;

const TitleText = styled(Text)`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  word-wrap: break-word;
  width: 86%;
  margin-right: 20px;
`;

const SubTitle = styled(Text)`
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: absolute;
  top: -15px;
  left: 14px;
  border-radius: 30px;
  background: white;
  color: gray;
  padding: 0;
  svg {
    margin-right: 4px;
  }
`;

const BoxContainer = styled.div`
  width: 90%;
  margin: 0 auto 20px;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: relative;
  align-items: center;
  justify-content: start;
  margin-bottom: 36px;
`;

const InfoText = styled(Text)`
  font-size: 0.8rem;
  color: gray;
  width: "14%";
  text-align: right;
`;

const LoadingText = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
`;

const NoAccessText = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
  color: red;
`;

const BackButton = styled(Button)`
  margin: 0;
`;

const ButtonGroup = styled(Flex)`
  gap: 10px;
`;

const EditButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => prop !== "canEdit",
})<ButtonProps>`
  display: ${({ canEdit }) => (canEdit ? "block" : "none")};
`;

const DeleteButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => prop !== "canEdit",
})<ButtonProps>`
  display: ${({ canEdit }) => (canEdit ? "block" : "none")};
`;
const DemonstrationDetail = () => {
  const { demoId } = useParams();
  const { user } = useAuth();
  const [demonstration, setDemonstration] = useState<Demonstration>();
  const [canEdit, setCanEdit] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const toast = useToast();

  const fetchDemonstration = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/demonstrations/${demoId}/`);
      if (response.data.company !== user?.company?.id) {
        setNoPermission(true);
      } else {
        setDemonstration(response.data);
        setCanEdit(!!(user && user.id === response.data.user.id));
      }
    } catch (error: any) {
      console.error("Error fetching demonstration details:", error);
      if (error.response && error.response.status === 404) {
        setNoPermission(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDemonstration();
  }, [demoId, user]);

  const handleBack = () => {
    navigate("/dashboard?tab=demo");
  };

  const handleDelete = async () => {
    if (window.confirm("本当にこのデモを削除してもよろしいですか？")) {
      try {
        setLoading(true);
        await apiClient.delete(`/api/demonstrations/${demoId}/`);
        toast({
          title: "成功",
          description: "デモを削除しました",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/dashboard?tab=demo");
      } catch (error) {
        console.error("削除中にエラーが発生しました:", error);
        toast({
          title: "失敗",
          description: "デモの削除に失敗しました",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const openForm = () => setIsFormVisible(true);
  const closeForm = () => setIsFormVisible(false);

  if (noPermission || !demonstration) {
    return (
      <Flex height="100%" align="center" justify="center">
        <NoPermission />
      </Flex>
    );
  }

  return (
    <DetailContainer>
      <Header>
        <BackButton colorScheme="gray" onClick={handleBack}>
          戻る
        </BackButton>
        <ButtonGroup>
          <EditButton
            colorScheme="blue"
            canEdit={canEdit}
            leftIcon={<FaEdit />}
            onClick={openForm}
          >
            編集
          </EditButton>
          <DeleteButton
            colorScheme="red"
            canEdit={canEdit}
            leftIcon={<FaTrashAlt />}
            onClick={handleDelete}
          >
            削除
          </DeleteButton>
        </ButtonGroup>
      </Header>
      <Flex direction="column" align="center" p="4">
        <SubHeader>
          <TitleText>{demonstration.title}</TitleText>
          <InfoText>
            更新：
            {new Date(demonstration.updated_at).toLocaleString("ja-JP", {
              hour12: false,
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
            {demonstration.user.username}
          </InfoText>
        </SubHeader>
        <VideoContainer>
          <ReactPlayer
            url={demonstration.demo_video_url}
            controls
            width="100%"
            height="100%"
          />
        </VideoContainer>
        <TagContainer>
          <SubTitle>
            <FaTag />
            タグ
          </SubTitle>
          {demonstration.read_tags.map((tag) => (
            <Badge key={tag.id} colorScheme="teal">
              {tag.name}
            </Badge>
          ))}
        </TagContainer>
        <BoxContainer>
          <SubTitle>
            <FaLightbulb />
            説明
          </SubTitle>
          {demonstration.use_case}
        </BoxContainer>
      </Flex>
      <DemonstrationForm
        closeForm={closeForm}
        isVisible={isFormVisible}
        onSuccessfulSubmit={fetchDemonstration}
        demonstrationId={demoId ? Number(demoId) : null}
      />
    </DetailContainer>
  );
};

export default DemonstrationDetail;
