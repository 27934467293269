/** @format */

import { useCallback, useState, useMemo, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ReactFlow, {
  Node,
  Edge,
  MarkerType,
  Background,
  Controls,
  MiniMap,
  useReactFlow,
  Connection,
} from "reactflow";
import {
  Box,
  Alert,
  AlertIcon,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Badge,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerOverlay,
  DrawerContent,
  useToast,
} from "@chakra-ui/react";
import {
  HiPlusCircle,
  HiHome,
  HiPlay,
  HiPencil,
  HiXCircle,
} from "react-icons/hi";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaRegEdit,
} from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";
import { BsShareFill } from "react-icons/bs";
import { MdOutlineEditOff } from "react-icons/md";
import { TiThList } from "react-icons/ti";
import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "reactflow/dist/style.css";
import styled, { keyframes, css } from "styled-components";
import apiClient from "./apiClient";
import { useAuth } from "./AuthProvider";
import TextUpdaterNode from "./TextUpdaterNode";
import EdgeUpdatePopup from "./EdgeUpdatePopup";
import { useViewMode } from "./ViewModeContext";
import { useLoading } from "./LoadingContext";
import { Hearing } from "./Hearing";
import ShareTalkScriptDialog from "./ShareTalkScriptDialog";
import Recorder from "./Recorder";
import NoPermission from "./NoPermission";

const StyledContainer = styled.div<StyledContainerProps>`
  width: 100vw;
  height: 100%;
  background-color: ${(props) => (props.$viewMode ? "#51579e" : "#c4c8f5")};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledReactFlow = styled(ReactFlow)`
  flex-grow: 1;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: transparent;
  align-items: center;
  flex-direction: row;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 100%;
  text-align: center;
`;

const DisplayText = styled.h2`
  width: 45%;
  font-size: 1.1em;
  background-color: #f7f9ff;
  color: #575757;
  font-family: inherit;
  margin: 0;
  font-weight: bold;
  border-radius: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  padding: 8px 16px;

  @media (max-width: 720px) {
    width: 70%;
    font-size: 0.9em;
  }
`;

const EditableInput = styled.input`
  width: 45%;
  font-size: 1.1em;
  background-color: #f7f9ff;
  color: #575757;
  font-family: inherit;
  margin: 0;
  font-weight: bold;
  border-radius: 50px;
  text-align: center;
  text-align: center;
  padding: 8px 16px;
  overflow: visible;

  @media (max-width: 720px) {
    width: 70%;
    font-size: 0.9em;
  }
`;

const RecorderFloating = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
`;

const FloatingRightContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  position: absolute;
  top: 92px;
  right: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    top: 140px;
  }
`;

const FloatingLeftContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  position: absolute;
  top: 92px;
  left: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    top: 140px;
  }
`;

const FloatingRowContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  position: absolute;
  top: 92px;
  right: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    top: 140px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const FloatingRightNotViewButton = styled.button`
  background-color: #db793d;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const FloatingRightNotViewHearingButton = styled.button`
  background-color: #7db1c7;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const FloatingRightViewHearingButton = styled.button`
  background-color: #7db1c7;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const FloatingRightViewButton = styled.button<FloatingRightViewButtonProps>`
  background-color: #d66af7;
  color: white;
  border: none;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
      background-color: #e0e0e0;
      cursor: default;
      pointer-events: none;
      &:hover {
        opacity: 1; // ホバー時の透明度の変更を無効化
      }
    `}
`;

const FloatingToolButton = styled.button`
  background-color: #66ad92;
  width: 142px;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const FloatingRoleplayButton = styled.button`
  background-color: #d66af7;
  width: 142px;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e0e0e0;
  margin: 8px 0;
`;

const TargetIconWrapper = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TargetIcon = styled(TbTargetArrow)`
  color: white;
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

const blinkAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkingText = styled.div`
  position: absolute;
  background: white;
  border-radius: 50px;
  padding: 4px;
  color: #e895a7;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.7;
  animation: ${css`
    ${blinkAnimation} 3s linear infinite
  `};
`;

interface EdgeMarker {
  type: MarkerType;
  color?: string;
  width?: number;
  height?: number;
  markerUnits?: string;
  orient?: string;
  strokeWidth?: number;
}

type EdgeMarkerType = string | EdgeMarker;

interface FlowProps {}

interface FloatingRightViewButtonProps {
  $isDisabled: boolean;
}

interface Demonstration {
  id: string;
  title: string;
  use_case: string;
  demo_video_url: string | null;
}

interface Talk {
  id: string;
  title: string | null;
  content: string | null;
  content_wysiwyg: string | null;
  creator: string;
  talk_script: string;
  position_x: number;
  position_y: number;
  isInitialNode: boolean;
  node_type: string;
  link_url: string | null;
  is_independent: boolean;
  has_questions: boolean;
  media_url: string | null;
  demo?: Demonstration;
}

interface EdgeOrder {
  id: string;
  source: string;
  target: string;
  label: string;
  type: string;
  animated: boolean;
  deletable: boolean;
  labelStyle?: React.CSSProperties;
  markerEnd?: EdgeMarkerType;
}

interface StyledContainerProps {
  $viewMode: boolean;
  $isViewer: boolean;
}

interface User {
  id: number;
  username: string;
  email: string;
}

const nodeTypes = { textUpdater: TextUpdaterNode };

const TalkScript: React.FC<FlowProps> = () => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [talkScriptName, setTalkScriptName] = useState<string | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const talk_script_id = useParams<{ id: string }>().id;
  if (!talk_script_id) {
    throw new Error("No TalkScript ID provided");
  }
  const [selectedNodes, setSelectedNodes] = useState<{
    [key: string]: boolean;
  }>({});
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isEdgePopupOpen, setIsEdgePopupOpen] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState<{
    id: string;
    label: string;
    deletable: boolean;
  } | null>(null);
  const defaultViewport = { x: 0, y: 0, zoom: 1 };
  const { viewMode, setViewMode, isViewer, setIsViewer } = useViewMode();
  const location = useLocation();
  const [currentNode, setCurrentNode] = useState<Node<
    any,
    string | undefined
  > | null>(null);
  const [selectedNextEdge, setSelectedNextEdge] = useState<Edge | null>(null);
  const { setCenter } = useReactFlow();
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [currentEditor, setCurrentEditor] = useState<User | null>(null);
  const [selectedPreviousEdge, setSelectedPreviousEdge] = useState<Edge | null>(
    null
  );
  const [multipleNextEdges, setMultipleNextEdges] = useState<Edge[]>([]);
  const [multiplePreviousEdges, setMultiplePreviousEdges] = useState<Edge[]>(
    []
  );
  const [isAtStart, setIsAtStart] = useState<boolean>(false);
  const [initialNodeId, setInitialNodeId] = useState<number | null>(null);
  const [isAtEnd, setIsAtEnd] = useState<boolean>(false);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [isNextModalOpen, setIsNextModalOpen] = useState(false);
  const [isPrevModalOpen, setIsPrevModalOpen] = useState(false);
  const [isPurposeModalOpen, setIsPurposeModalOpen] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [isEditingPurpose, setIsEditingPurpose] = useState(false);
  const [editedPurpose, setEditedPurpose] = useState("");
  const [isHearingModalOpen, setIsHearingModalOpen] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const { loading, setLoading } = useLoading();
  const toast = useToast();

  //特定スタイリング用変数
  const disabledForNotViewer =
    isViewer ||
    (!isViewer && currentEditor && user && currentEditor.id !== user.id) ||
    loading;

  //talk_script.nameをwizwig的に変更できる機能
  const updateTalkScriptName = useCallback(
    async (newName: string) => {
      try {
        await apiClient.patch(`/api/talk_scripts/${talk_script_id}/`, {
          name: newName,
        });
        setTalkScriptName(newName);
        setIsEditingName(false);
      } catch (error) {
        console.error("Error updating talk script name:", error);
      }
    },
    [talk_script_id]
  );
  //talk_script.nameの変更処理
  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTalkScriptName(e.target.value);
    },
    []
  );

  const handleBlur = useCallback(() => {
    if (talkScriptName) {
      updateTalkScriptName(talkScriptName);
    } else {
      fetchTalks();
      toast({
        title: "トークスクリプト名が未設定です",
        description:
          "トークスクリプト名を空白にすることはできません。トークスクリプト名を設定してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [talkScriptName, updateTalkScriptName]);

  //DBからnodes情報を取得し、Propsへ展開する処理。取得したnodeデータを元に動的にedgesも生成する
  const fetchTalks = useCallback(async () => {
    try {
      const { data: talkScript } = await apiClient.get(
        `/api/talk_scripts/${talk_script_id}/`
      );

      setTalkScriptName(talkScript.name);
      setPurpose(talkScript.purpose);

      const initialNodeId = talkScript.initial_node;
      setInitialNodeId(initialNodeId);

      const initialEdges = await generateEdges(talk_script_id);

      const { data: talks } = await apiClient.get(
        `/api/talks/?talk_script_id=${talk_script_id}`
      );

      const initialNodes = await Promise.all(
        talks.map(async (talk: Talk) => {
          return {
            id: talk.id.toString(),
            type: "textUpdater",
            dragHandle: ".custom-drag-handle",
            position: { x: talk.position_x, y: talk.position_y },
            data: {
              title: talk.title,
              content: talk.content,
              content_wysiwyg: talk.content_wysiwyg,
              talk_script: talk.talk_script,
              position_x: talk.position_x,
              position_y: talk.position_y,
              node_type: talk.node_type,
              link_url: talk.link_url,
              media_url: talk.media_url,
              demo: talk.demo
                ? {
                    id: talk.demo.id,
                    title: talk.demo.title,
                    use_case: talk.demo.use_case,
                    demo_video_url: talk.demo.demo_video_url,
                  }
                : null,
              onSelect: (id: string) => handleNodeSelect(id, true),
              isInitialNode: talk.id === initialNodeId,
              fetchTalks: fetchTalks,
              edges: initialEdges,
              isIndependent: talk.is_independent,
              hasQuestions: talk.has_questions,
            },
          };
        })
      );

      setNodes(initialNodes);
      setEdges(initialEdges);
    } catch (error) {
      console.error("Error fetching talks:", error);
    }
  }, [talk_script_id]);

  const handleNodeSelect = (nodeId: string, isSelected: boolean) => {
    setSelectedNodes((prevNodes) => ({
      ...prevNodes,
      [nodeId]: isSelected,
    }));
  };

  // 選択されたノードを更新する関数
  const handlePlayMedia = (nodeId: string) => {
    const selectedNode = nodes.find((node) => node.id === nodeId);
    if (selectedNode) {
      setCurrentNode(selectedNode);
      setIsMediaModalOpen(true);
    }
  };

  const nodesWithSelection = useMemo(() => {
    const updatedNodes = nodes.map((node) => ({
      ...node,
      type: "textUpdater",
      data: {
        ...node.data,
        nodeId: node.id,
        onSelect: handleNodeSelect,
        onPlayMedia: handlePlayMedia,
        isMediaModalOpen: isMediaModalOpen,
      },
      className: selectedNodes[node.id] ? "selected" : "",
    }));

    return updatedNodes;
  }, [nodes, selectedNodeId, handlePlayMedia, isMediaModalOpen]);

  const generateEdges = useCallback(
    async (talkScriptId: any): Promise<EdgeOrder[]> => {
      try {
        const edgesResponse = await apiClient.get(
          `/api/talks/edges/?talk_script_id=${talkScriptId}`
        );
        const edgesData = edgesResponse.data;

        const edges: EdgeOrder[] = edgesData.map((edge: any): EdgeOrder => {
          return {
            id: String(edge.id),
            source: String(edge.source),
            target: String(edge.target),
            label: truncateLabel(edge.label, 31),
            type: "default",
            animated: true,
            deletable: edge.deletable,
            labelStyle: {
              fontSize: "12px",
            },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 30,
              height: 30,
              color: "#b85ab0",
            },
          };
        });

        return edges;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    []
  );

  const handleGoBack = () => {
    navigate("/dashboard?tab=talkscript");
  };

  useEffect(() => {
    fetchTalks();
  }, [talk_script_id, fetchTalks]);

  useEffect(() => {
    const fetchTalkScript = async () => {
      setLoading(true);
      if (user === null) {
        console.error("User is not authenticated");
        return;
      }

      try {
        const { data: talkScript } = await apiClient.get(
          `/api/talk_scripts/${talk_script_id}/`
        );

        setIsOwner(talkScript.owner === user.id);

        if (!talkScript.users.includes(user.id)) {
          setNoPermission(true);
          return;
        }

        if (talkScript.editing_user) {
          setCurrentEditor(talkScript.editing_user);
        } else {
          startEditing();
        }

        const { data: viewerStatus } = await apiClient.get(
          `/api/talk_scripts/check-viewer-status/${talk_script_id}/`
        );

        setIsViewer(viewerStatus.is_viewer);

        if (
          viewerStatus.is_viewer ||
          (user &&
            talkScript.is_being_edited &&
            talkScript.editing_user.id !== user.id)
        ) {
          setViewMode(true);
        }
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          setNoPermission(true);
        } else if (error instanceof Error) {
          if (error.message === "Unauthorized") {
            console.error("You are not authorized to view this talk script.");
          } else {
            console.error("Error fetching talk script:", error.message);
          }
        } else {
          console.error("An unexpected error occurred:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTalkScript();
  }, [talk_script_id, user, viewMode]);

  const handleNodeDragStop = async (node: any) => {
    const {
      id,
      position: { x, y },
    } = node;
    try {
      await apiClient.patch(`/api/talks/${id}/`, {
        position_x: x,
        position_y: y,
      });
      setNodes((prevNodes) => {
        return prevNodes.map((n) =>
          n.id === id ? { ...n, position: { x, y } } : n
        );
      });
    } catch (error) {
      console.error("Failed to update node position:", error);
    }
  };

  const handleNodeDrag = (node: any) => {
    setNodes((prevNodes) => {
      return prevNodes.map((n) =>
        n.id === node.id
          ? { ...n, position: { x: node.position.x, y: node.position.y } }
          : n
      );
    });
  };

  // エッジがクリックされたときに呼び出される関数
  const handleEdgeClick = useCallback(
    (event: React.MouseEvent, edge: Edge) => {
      if (viewMode) return;
      // クリックされたEdgeのidとEdgeのlabelをEdgeUpdatePopupに渡す
      setSelectedEdge({
        id: edge.id,
        label: edge.label as string,
        deletable: edge.deletable ?? false,
      });
      setIsEdgePopupOpen(true);
    },
    [viewMode]
  );

  // EdgeUpdatePopupを閉じるための関数
  const refetchEdges = useCallback(async () => {
    const newEdges = await generateEdges(talk_script_id);
    setEdges(newEdges);
    setIsEdgePopupOpen(false);
  }, [generateEdges]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const viewModeFromQuery = params.get("viewMode");

    if (viewModeFromQuery !== null && viewModeFromQuery === "true") {
      setViewMode(true);
      endEditingIfEditing();
    }
  }, [location.search]);

  const initializeViewMode = async () => {
    setIsMediaModalOpen(false);
    const initialNode = nodes.find((node) => node.data.isInitialNode);
    if (initialNode) {
      setCurrentNode(initialNode);
      setCenter(initialNode.position.x + 178, initialNode.position.y + 340, {
        zoom: 1,
        duration: 1000,
      });
    } else {
      setCurrentNode(null);
    }
  };

  // 前のノードに移動する関数
  const goToPrevious = () => {
    if (!currentNode) return;

    apiClient.post("/api/cohort_analysis_logs/create_log/", {
      company: user?.company?.id,
      event_type: "roleplay_goToPrevious",
      additional_info: {
        currentNode_id: currentNode.id,
        talk_script_id: talk_script_id,
      },
    });

    const currentEdges = edges.filter(
      (edge) => edge.target === currentNode.id.toString()
    );
    if (currentEdges.length === 1) {
      const previousTalk = nodes.find(
        (node) => node.id === currentEdges[0].source
      );
      if (previousTalk) {
        setCurrentNode(previousTalk);
        const newX =
          previousTalk.position.x +
          (previousTalk.data.node_type === "demo" ? 220 : 178);

        setCenter(newX, previousTalk.position.y + 340, {
          zoom: 1,
          duration: 1000,
        });
      }
    } else if (currentEdges.length > 1) {
      // 複数のエッジが見つかったときにはそれらを設定する
      setMultiplePreviousEdges(currentEdges);
      setIsPrevModalOpen(true);
    }
  };

  // goToNext 関数は次のように修正します
  const goToNext = () => {
    if (!currentNode) return;

    apiClient.post("/api/cohort_analysis_logs/create_log/", {
      company: user?.company?.id,
      event_type: "roleplay_goToNext",
      additional_info: {
        currentNode_id: currentNode.id,
        talk_script_id: talk_script_id,
      },
    });

    const currentEdges = edges.filter(
      (edge) => edge.source === currentNode.id.toString()
    );

    if (currentEdges.length === 1) {
      const nextTalk = nodes.find((node) => node.id === currentEdges[0].target);
      if (nextTalk) {
        setCurrentNode(nextTalk);
        const newX =
          nextTalk.position.x +
          (nextTalk.data.node_type === "demo" ? 220 : 178);
        setCenter(newX, nextTalk.position.y + 340, {
          zoom: 1,
          duration: 1000,
        });
      }
    } else if (currentEdges.length > 1) {
      // 複数のエッジが見つかったときにはそれらを設定する
      setMultipleNextEdges(currentEdges);
      setIsNextModalOpen(true);
    }
  };

  // 選択されたエッジを設定する関数
  const handlePreviousEdgeSelect = (edge: Edge) => {
    setSelectedPreviousEdge(edge);
    setIsPrevModalOpen(false);
  };

  // 選択されたエッジを設定する関数
  const handleNextEdgeSelect = (edge: Edge) => {
    setSelectedNextEdge(edge);
    setIsNextModalOpen(false);
  };

  // selectedPreviousEdgeが変化したときに前のノードを設定する
  useEffect(() => {
    if (selectedPreviousEdge) {
      const previousTalk = nodes.find(
        (node) => node.id === selectedPreviousEdge.source
      );
      if (previousTalk) {
        setCurrentNode(previousTalk);
        const newX =
          previousTalk.position.x +
          (previousTalk.data.node_type === "demo" ? 220 : 178);
        setCenter(newX, previousTalk.position.y + 340, {
          zoom: 1,
          duration: 1000,
        });
      }
      setSelectedPreviousEdge(null);
      setMultiplePreviousEdges([]);
      setIsPrevModalOpen(false);
    }
  }, [selectedPreviousEdge, nodes]);

  // selectedNextEdgeが変化したときに次のノードを設定する
  useEffect(() => {
    if (selectedNextEdge) {
      const nextTalk = nodes.find(
        (node) => node.id === selectedNextEdge.target
      );
      if (nextTalk) {
        setCurrentNode(nextTalk);
        const newX =
          nextTalk.position.x +
          (nextTalk.data.node_type === "demo" ? 220 : 178);
        setCenter(newX, nextTalk.position.y + 340, {
          zoom: 1,
          duration: 1000,
        });
      }
      setSelectedNextEdge(null);
      setMultipleNextEdges([]);
      setIsNextModalOpen(false);
    }
  }, [selectedNextEdge, nodes]);

  const openShareDialog = () => {
    setIsShareDialogOpen(true);
  };

  const closeShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  // 編集状態を取得する関数
  const fetchEditState = async () => {
    try {
      const response = await apiClient.get(
        `/api/talk_scripts/${talk_script_id}/`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // 編集を開始する関数
  const startEditing = async () => {
    try {
      await apiClient.post(
        `/api/talk_scripts/${talk_script_id}/start_editing/`
      );
    } catch (error) {
      console.error(error);
    }
  };

  // 編集を終了する関数
  const endEditing = async () => {
    try {
      await apiClient.post(`/api/talk_scripts/${talk_script_id}/end_editing/`);
    } catch (error) {
      console.error(error);
    }
  };

  const startEditingIfNotEditing = async () => {
    const { is_being_edited, editing_user } = await fetchEditState();
    if (!is_being_edited || (user && editing_user.id === user.id)) {
      try {
        await startEditing();
        setViewMode(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const endEditingIfEditing = async () => {
    const { is_being_edited, editing_user } = await fetchEditState();
    if (is_being_edited && user && editing_user.id === user.id) {
      try {
        await endEditing();
        setViewMode(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // nodesUpdatedをトグルするたびにinitializeViewModeを呼び出す
  useEffect(() => {
    if (viewMode) {
      initializeViewMode();
    }
  }, [nodes]);

  // viewModeが変わったときだけfetchTalksを呼び出す
  useEffect(() => {
    if (viewMode) {
      fetchTalks();
    }
  }, [viewMode]);

  useEffect(() => {
    if (viewMode && currentNode) {
      const isStartNode = !edges.some(
        (edge) => edge.target === currentNode.id.toString()
      );
      setIsAtStart(isStartNode);
    }
  }, [viewMode, currentNode, edges]);

  // 現在のノードが変更されたときに実行するuseEffectを追加
  useEffect(() => {
    if (!currentNode) return;

    if (viewMode && currentNode) {
      const isEndNode = !edges.some(
        (edge) => edge.source === currentNode.id.toString()
      );

      setIsAtEnd(isEndNode);
    }
  }, [viewMode, currentNode, edges]);

  const truncateLabel = (label: string, maxLength: number): string => {
    return label.length > maxLength
      ? label.substring(0, maxLength - 1) + "…"
      : label;
  };

  const handleConnect = useCallback(
    (params: Connection) => {
      // sourceとtargetがnullでないことを確認
      if (!params.source || !params.target) {
        console.warn("Invalid source or target values.");
        return;
      }
      // デフォルトのエッジスタイル属性
      const defaultEdgeStyle = {
        type: "default",
        animated: true,
        deletable: true,
        labelStyle: {
          fontSize: "12px",
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 30,
          height: 30,
          color: "#b85ab0",
        },
      };

      // 新しいエッジオブジェクトを作成
      const newEdge = {
        id: `temporary-${params.source}-${params.target}`,
        source: params.source,
        target: params.target,
        label: `トークID：${params.target}へ移動`,
        ...defaultEdgeStyle,
      };

      // フロントエンドの状態を更新
      setEdges((els) => [...els, newEdge]);

      // バックエンドに新しいエッジを作成することを通知
      apiClient
        .post(`/api/talks/edges/`, {
          source: params.source,
          target: params.target,
          label: newEdge.label,
          talk_script: talk_script_id,
          deletable: newEdge.deletable,
        })
        .then((response) => {
          // 新しいエッジのIDを更新するか、他の必要な処理を行う
          const responseEdge = {
            ...defaultEdgeStyle,
            id: String(response.data.id),
            source: String(response.data.source),
            target: String(response.data.target),
            label: truncateLabel(response.data.label, 31),
          };
          setEdges((els) =>
            els.map((e) => (e.id === newEdge.id ? responseEdge : e))
          );
          fetchTalks();
        })
        .catch((error) => {
          console.error("Error creating edge:", error);
        });
    },
    [setEdges]
  );

  const createNewNode = async () => {
    setLoading(true);
    try {
      // 新しいTalkオブジェクトの位置を計算
      const lastTalkNode = nodes[nodes.length - 1];
      const newPositionX = lastTalkNode
        ? lastTalkNode.data.position_x + 580
        : 0;
      const newPositionY = lastTalkNode ? lastTalkNode.data.position_y : 0;
      // ReactFlowのビューエリアの変換値を取得する方法;
      const newNodeData = {
        talk_script: talk_script_id,
        position_x: newPositionX,
        position_y: newPositionY,
      };

      const response = await apiClient.post("/api/talks/", newNodeData);
      fetchTalks();
      setCenter(
        response.data.position_x + 178,
        response.data.position_y + 340,
        {
          zoom: 1,
          duration: 1000,
        }
      );
      return response.data.id;
    } catch (error) {
      console.error("Error creating new talk:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createNewLinkedNode = async () => {
    setLoading(true);
    try {
      // 新しいTalkオブジェクトの位置を計算
      const lastTalkNode = nodes[nodes.length - 1];
      const newPositionX = lastTalkNode
        ? lastTalkNode.data.position_x + 580
        : 0;
      const newPositionY = lastTalkNode ? lastTalkNode.data.position_y : 0;
      // ReactFlowのビューエリアの変換値を取得する方法;
      const newNodeData = {
        talk_script: talk_script_id,
        position_x: newPositionX,
        position_y: newPositionY,
        node_type: "linked",
      };

      const response = await apiClient.post("/api/talks/", newNodeData);
      fetchTalks();
      setCenter(
        response.data.position_x + 178,
        response.data.position_y + 340,
        {
          zoom: 1,
          duration: 1000,
        }
      );
      return response.data.id;
    } catch (error) {
      console.error("Error creating new talk:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createNewDemoNode = async () => {
    setLoading(true);
    try {
      // 新しいTalkオブジェクトの位置を計算
      const lastTalkNode = nodes[nodes.length - 1];
      const newPositionX = lastTalkNode
        ? lastTalkNode.data.position_x + 580
        : 0;
      const newPositionY = lastTalkNode ? lastTalkNode.data.position_y : 0;
      // ReactFlowのビューエリアの変換値を取得する方法;
      const newNodeData = {
        talk_script: talk_script_id,
        position_x: newPositionX,
        position_y: newPositionY,
        node_type: "demo",
      };

      const response = await apiClient.post("/api/talks/", newNodeData);
      fetchTalks();
      setCenter(
        response.data.position_x + 220,
        response.data.position_y + 340,
        {
          zoom: 1,
          duration: 1000,
        }
      );
      return response.data.id;
    } catch (error) {
      console.error("Error creating new talk:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      if (!oldEdge.id) {
        console.error("Edge does not have an ID!");
        return;
      }

      const updatedEdge = {
        ...oldEdge,
        source: newConnection.source || oldEdge.source,
        target: newConnection.target || oldEdge.target,
        label: `トークID：${newConnection.target}へ移動`,
      };

      // フロントエンドの状態を更新
      setEdges((els) =>
        els.map((e) => (e.id === oldEdge.id ? updatedEdge : e))
      );

      // バックエンドに更新を通知
      apiClient
        .put(`/api/talks/edges/${oldEdge.id}/`, {
          source: updatedEdge.source,
          target: updatedEdge.target,
          label: `トークID：${newConnection.target}へ移動`,
        })
        .then(() => {
          fetchTalks();
        })
        .catch((error) => {
          console.error("Error updating edge:", error);
        });
    },
    [setEdges]
  );

  useEffect(() => {
    // 非同期処理を実行するための内部関数を定義
    const checkMediaUrl = async () => {
      if (!currentNode?.data.media_url) {
        return;
      }

      if (viewMode) {
        setIsMediaModalOpen(true);
      }
    };

    checkMediaUrl();
  }, [currentNode, viewMode]);

  const openPurposeModal = () => {
    setIsEditingPurpose(false);
    setIsPurposeModalOpen(true);
  };

  const toggleEditPurpose = () => {
    setIsEditingPurpose(!isEditingPurpose);
    setEditedPurpose(purpose);
  };

  const updatePurpose = async () => {
    if (!editedPurpose.trim()) {
      toast({
        title: "エラー",
        description: "達成したい目的を空欄にできません。",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    try {
      await apiClient.patch(`/api/talk_scripts/${talk_script_id}/`, {
        purpose: editedPurpose,
      });
      setPurpose(editedPurpose);
      setIsEditingPurpose(false);
      toast({
        title: "達成したい目的が更新されました。",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchTalks();
    } catch (error) {
      console.error("達成したい目的の更新に失敗しました:", error);
      toast({
        title: "達成したい目的の更新に失敗しました。",
        description: "もう一度試してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toggleHearingModal = () => setIsHearingModalOpen(!isHearingModalOpen);

  const handleTalkSelected = (talk: any) => {
    setCurrentNode(talk);

    const newX = talk.position_x + (talk.node_type === "demo" ? 220 : 178);
    setCenter(newX, talk.position_y + 340, {
      zoom: 1,
      duration: 1000,
    });

    setIsHearingModalOpen(false);
  };

  return (
    <>
      {noPermission ? (
        <NoPermission />
      ) : (
        <StyledContainer $viewMode={viewMode} $isViewer={isViewer}>
          {viewMode ? (
            <HeaderWrapper>
              <TargetIconWrapper onClick={openPurposeModal}>
                <TargetIcon />
              </TargetIconWrapper>
              <DisplayText>{talkScriptName || "名前を入力"}</DisplayText>
            </HeaderWrapper>
          ) : (
            <HeaderWrapper>
              <TargetIconWrapper onClick={openPurposeModal}>
                <TargetIcon />
              </TargetIconWrapper>
              {isEditingName ? (
                <EditableInput
                  value={talkScriptName || ""}
                  onChange={handleNameChange}
                  onBlur={handleBlur}
                  autoFocus
                />
              ) : (
                <DisplayText
                  onClick={() => setIsEditingName(true)}
                  className={talkScriptName ? "" : "placeholder"}
                >
                  {talkScriptName || "名前を入力"}
                </DisplayText>
              )}
            </HeaderWrapper>
          )}
          {viewMode && currentNode && !isMediaModalOpen && (
            <RecorderFloating>
              <Recorder
                talk_id={currentNode.id}
                talk_script_id={talk_script_id}
              />
            </RecorderFloating>
          )}

          <StyledReactFlow
            nodes={nodesWithSelection}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodeDrag={(event, node) => handleNodeDrag(node)}
            onNodeDragStop={(event, node) => handleNodeDragStop(node)}
            onEdgeClick={viewMode ? undefined : handleEdgeClick}
            defaultViewport={defaultViewport}
            key={talk_script_id}
            onConnect={handleConnect}
            onEdgeUpdate={handleEdgeUpdate}
          >
            <Background />
            <Controls />
            {viewMode ? null : <MiniMap />}
          </StyledReactFlow>
          {isEdgePopupOpen && selectedEdge && (
            <EdgeUpdatePopup
              isOpen={isEdgePopupOpen}
              edgeId={selectedEdge.id}
              deletable={selectedEdge.deletable}
              fetchTalks={fetchTalks}
              onClose={refetchEdges}
            />
          )}
          {!isViewer &&
          currentEditor &&
          user &&
          currentEditor.id !== user.id ? (
            <Alert status="error" mt={4}>
              <AlertIcon />
              <Box fontWeight="bold">
                {`${currentEditor.username}がロープレに切り替えることで編集可能となります`}
              </Box>
            </Alert>
          ) : null}
          <Modal
            isOpen={isMediaModalOpen}
            onClose={() => setIsMediaModalOpen(false)}
          >
            {currentNode &&
              currentNode.data &&
              currentNode.data.media_url &&
              (currentNode.data.media_url.endsWith(".mp3") ? (
                <ModalContent
                  bottom="24px"
                  mx="auto"
                  my="0"
                  width="340px"
                  position="fixed"
                  padding="4"
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "-42px",
                      top: "-34px",
                      backgroundColor: "transparent",
                      padding: "o",
                      zIndex: 1000,
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        background: "white",
                        borderRadius: "50%",
                        boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
                        padding: "0",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsMediaModalOpen(false)}
                    >
                      <HiXCircle size={34} color="#ff6666" />
                    </button>
                  </div>
                  <AudioPlayer
                    src={currentNode.data.media_url}
                    autoPlay={true}
                    showSkipControls={false}
                    showJumpControls={false}
                  />
                </ModalContent>
              ) : currentNode.data.media_url.endsWith(".mp4") ? (
                <ModalContent
                  bottom="12px"
                  mx="auto"
                  my="0"
                  width="auto"
                  maxWidth="100%"
                  height="200px"
                  position="fixed"
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "-42px",
                      top: "-34px",
                      backgroundColor: "transparent",
                      padding: "o",
                      zIndex: 1000,
                    }}
                  >
                    <button
                      style={{
                        border: "none",
                        background: "white",
                        borderRadius: "50%",
                        boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
                        padding: "0",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsMediaModalOpen(false)}
                    >
                      <HiXCircle size={34} color="#ff6666" />
                    </button>
                  </div>
                  <ReactPlayer
                    url={currentNode.data.media_url}
                    playing={true}
                    width="100%"
                    height="100%"
                    controls={true}
                    onReady={() => setLoading(false)}
                    onStart={() => setLoading(false)}
                  />
                </ModalContent>
              ) : (
                <ModalContent>
                  <ModalHeader>メディアエラー</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>メディアファイルがありません。</ModalBody>
                </ModalContent>
              ))}
          </Modal>
          {viewMode ? (
            <>
              {!isMediaModalOpen && (
                <FloatingRowContainer>
                  <Row>
                    <FloatingRightViewButton
                      onClick={goToPrevious}
                      $isDisabled={loading || isAtStart}
                    >
                      <FaArrowAltCircleLeft
                        size="1.2em"
                        style={{ marginRight: "0.3em" }}
                      />
                      前へ
                    </FloatingRightViewButton>
                    <FloatingRightViewButton
                      onClick={goToNext}
                      $isDisabled={loading || isAtEnd}
                    >
                      <FaArrowAltCircleRight
                        size="1.2em"
                        style={{ marginRight: "0.3em" }}
                      />
                      次へ
                    </FloatingRightViewButton>
                  </Row>
                  <Row>
                    <FloatingRightViewHearingButton
                      onClick={toggleHearingModal}
                      disabled={loading}
                    >
                      <TiThList size="1.2em" style={{ marginRight: "0.3em" }} />
                      ヒアリング
                    </FloatingRightViewHearingButton>
                  </Row>
                </FloatingRowContainer>
              )}
              <Modal
                isOpen={isPrevModalOpen}
                onClose={() => setIsPrevModalOpen(false)}
              >
                <ModalOverlay />
                <ModalContent
                  position="absolute"
                  top="212px"
                  right="28px"
                  transform="translate(-50%, -50%)"
                >
                  <ModalHeader fontSize="md">移動するトークを選択</ModalHeader>
                  <ModalBody>
                    {multiplePreviousEdges.map((edge, index) => (
                      <Box
                        key={index}
                        onClick={() => handlePreviousEdgeSelect(edge)}
                        cursor="pointer"
                        _hover={{ backgroundColor: "gray.100" }}
                        marginBottom="2"
                        padding="3"
                        fontSize="md"
                        color="gray.600"
                      >
                        {edge.label}
                      </Box>
                    ))}
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Modal
                isOpen={isNextModalOpen}
                onClose={() => setIsNextModalOpen(false)}
              >
                <ModalOverlay />
                <ModalContent
                  position="absolute"
                  top="212px"
                  right="28px"
                  transform="translate(-50%, -50%)"
                >
                  <ModalHeader fontSize="md">移動するトークを選択</ModalHeader>
                  <ModalBody>
                    {multipleNextEdges.map((edge, index) => (
                      <Box
                        key={index}
                        onClick={() => handleNextEdgeSelect(edge)}
                        cursor="pointer"
                        _hover={{ backgroundColor: "gray.100" }}
                        marginBottom="2"
                        padding="3"
                        fontSize="md"
                        color="gray.600"
                      >
                        {edge.label}
                      </Box>
                    ))}
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
          ) : (
            <FloatingRightContainer>
              <FloatingRightNotViewButton
                onClick={createNewNode}
                disabled={loading}
              >
                <HiPlusCircle size="1.2em" style={{ marginRight: "0.3em" }} />
                トーク
              </FloatingRightNotViewButton>
              <FloatingRightNotViewButton
                onClick={createNewDemoNode}
                disabled={loading}
              >
                <HiPlusCircle size="1.2em" style={{ marginRight: "0.3em" }} />
                デモトーク
              </FloatingRightNotViewButton>
              <FloatingRightNotViewButton
                onClick={createNewLinkedNode}
                disabled={loading}
              >
                <HiPlusCircle size="1.2em" style={{ marginRight: "0.3em" }} />
                リンクトーク
              </FloatingRightNotViewButton>
              <FloatingRightNotViewHearingButton
                onClick={toggleHearingModal}
                disabled={loading}
              >
                <TiThList size="1.2em" style={{ marginRight: "0.3em" }} />
                ヒアリング
              </FloatingRightNotViewHearingButton>
            </FloatingRightContainer>
          )}

          {!isMediaModalOpen && (
            <FloatingLeftContainer>
              {viewMode ? (
                <FloatingRoleplayButton
                  onClick={startEditingIfNotEditing}
                  disabled={disabledForNotViewer}
                  style={{
                    opacity: disabledForNotViewer ? 0.3 : undefined,
                    pointerEvents: disabledForNotViewer ? "none" : undefined,
                    backgroundColor: disabledForNotViewer
                      ? "#919191"
                      : undefined,
                  }}
                >
                  <HiPencil size="1.2em" style={{ marginRight: "0.3em" }} />
                  編集モード
                </FloatingRoleplayButton>
              ) : (
                <FloatingRoleplayButton
                  onClick={endEditingIfEditing}
                  disabled={loading}
                >
                  <HiPlay size="1.2em" style={{ marginRight: "0.3em" }} />
                  ロープレモード
                </FloatingRoleplayButton>
              )}
              <Divider />
              <Box>
                <>
                  {user && isOwner && (
                    <FloatingToolButton
                      disabled={disabledForNotViewer}
                      onClick={openShareDialog}
                      style={{
                        opacity: disabledForNotViewer ? 0.3 : undefined,
                        pointerEvents: disabledForNotViewer
                          ? "none"
                          : undefined,
                        backgroundColor: disabledForNotViewer
                          ? "#919191"
                          : undefined,
                      }}
                    >
                      <BsShareFill
                        size="1em"
                        style={{ marginRight: "0.5em" }}
                      />
                      共有
                    </FloatingToolButton>
                  )}
                  <ShareTalkScriptDialog
                    isOpen={isShareDialogOpen}
                    onClose={closeShareDialog}
                    talkScriptId={talk_script_id}
                    companyId={user?.company?.id.toString()}
                  />
                </>
              </Box>
              <FloatingToolButton onClick={handleGoBack}>
                <HiHome size="1.2em" style={{ marginRight: "0.3em" }} />
                ホーム
              </FloatingToolButton>
            </FloatingLeftContainer>
          )}
          <Modal
            isOpen={isPurposeModalOpen}
            onClose={() => setIsPurposeModalOpen(false)}
          >
            <ModalOverlay />
            <ModalContent padding="4">
              <ModalHeader
                padding="2"
                display="flex"
                alignItems="center"
                fontSize="lg"
              >
                <Badge colorScheme="purple" borderRadius="full">
                  このトークスクリプトが達成したい目的
                </Badge>
                {!viewMode && isOwner && (
                  <Box
                    as="span"
                    ml="2"
                    cursor="pointer"
                    onClick={toggleEditPurpose}
                  >
                    {isEditingPurpose ? (
                      <MdOutlineEditOff size="1.2em" color="#e07b7b" />
                    ) : (
                      <FaRegEdit size="1.2em" color="#e07b7b" />
                    )}
                  </Box>
                )}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {isEditingPurpose ? (
                  <>
                    <Textarea
                      value={editedPurpose}
                      onChange={(e) => setEditedPurpose(e.target.value)}
                    />
                    <Button mt="4" colorScheme="blue" onClick={updatePurpose}>
                      更新
                    </Button>
                  </>
                ) : (
                  <Text>{purpose}</Text>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <Drawer
            isOpen={isHearingModalOpen}
            placement="left"
            onClose={toggleHearingModal}
          >
            <DrawerOverlay />
            <DrawerContent maxWidth="90%" width="auto">
              <DrawerBody>
                <Hearing
                  talkScriptId={talk_script_id}
                  onTalkSelected={handleTalkSelected}
                />
              </DrawerBody>

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={toggleHearingModal}>
                  閉じる
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </StyledContainer>
      )}
    </>
  );
};

export default TalkScript;
