/** @format */

import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Button,
  Input,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  Select,
  Center,
} from "@chakra-ui/react";
import styled from "styled-components";
import { useAuth } from "./AuthProvider";
import apiClient from "./apiClient";
import { useMessage } from "./MessageContext";
import { useLoading } from "./LoadingContext";

const Container = styled.div`
  height: 100%;
  width: 92%;
  margin: auto;
`;

type User = {
  id: number;
  username: string;
  email: string;
};

type UserRole = {
  role: string;
  class_type: string;
  user: User;
};

type Company = {
  admin: number;
  max_users: number;
  users: User[];
  company_users: UserRole[];
};

const TeamManagement: React.FC = () => {
  const [company, setCompany] = useState<Company | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const { user, checkToken } = useAuth();
  const { setMessage } = useMessage();
  const { setLoading } = useLoading();

  const flexDirectionValue = useBreakpointValue({
    base: "column",
    md: "row",
  }) as "row" | "column" | undefined;

  const fetchCompany = async (companyId: number) => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/companies/${companyId}/`);
      setCompany(response.data);
    } catch (error) {
      console.error(error);
      setMessage("会社情報の取得に失敗しました。", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.company && user.is_company_admin) {
      fetchCompany(user.company.id);
    }
  }, [user]);

  if (user && !user.is_company_admin) {
    return (
      <Center height="100%">
        <Box p={5} bg="gray" borderRadius="md" color="white">
          この情報は管理者のみがアクセスできます
        </Box>
      </Center>
    );
  }

  const handleInviteUser = async () => {
    // メールアドレスの形式を確認する正規表現
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // メールアドレスが空または無効な場合、エラーメッセージを表示
    if (!searchInput.trim()) {
      setMessage("メールアドレスを入力してください", "error");
      return;
    } else if (!emailRegex.test(searchInput)) {
      setMessage("有効なメールアドレスを入力してください", "error");
      return;
    }

    if (company && company.users.length >= company.max_users) {
      setMessage("これ以上ユーザーを招待することはできません", "error");
      return;
    }
    setLoading(true);
    try {
      if (user?.company?.id) {
        await apiClient.post(`/api/companies/${user.company.id}/invite/`, {
          email: searchInput,
        });
        setSearchInput("");
        setMessage("招待リンクを送信いたしました", "success");
        fetchCompany(user.company.id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = async (user_id: number) => {
    const confirmDelete = window.confirm(
      "この操作はサービスからユーザーを削除する操作です。ユーザーを削除すると、このユーザーがサービス内で作成した全てのデータは削除され、復元することはできません。削除する前に必要に応じて、このユーザーが作成したトークスクリプトは管理者に移管してください。本当にユーザーを削除してよろしいですか？"
    );
    if (confirmDelete) {
      setLoading(true);
      try {
        await apiClient.delete(`/api/companies/remove-user/`, {
          data: { user_id },
        });
        setMessage("チームから削除いたしました", "success");
        await checkToken();
        if (user?.company?.id) {
          fetchCompany(user.company.id);
        }
      } catch (error) {
        console.error(error);
        setMessage("削除中にエラーが発生しました", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const remainingSeats = company ? company.max_users - company.users.length : 0;

  const updateRole = async (user_id: number, newRole: string) => {
    try {
      if (user?.company?.id) {
        await apiClient.patch(
          `/api/companies/${user.company.id}/user/${user_id}/role/`,
          {
            role: newRole,
          }
        );
        setMessage("編集権限を変更いたしました", "success");
        await checkToken();
        fetchCompany(user.company.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateClassType = async (user_id: number, newClassType: string) => {
    try {
      if (user?.company?.id) {
        await apiClient.patch(
          `/api/companies/${user.company.id}/user/${user_id}/class_type/`,
          {
            class_type: newClassType,
          }
        );
        setMessage("役割を変更いたしました", "success");
        await checkToken();
        fetchCompany(user.company.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Box mt={10} mb={10}>
        <Heading as="h2" size="md" mb={5}>
          チーム管理
        </Heading>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDirection={flexDirectionValue}
        >
          <Box mb={3} display="flex" alignItems="center">
            <Text fontSize="md" fontWeight="bold">
              チームメンバー数:{company && company.users.length}/
              {company && company.max_users}
            </Text>
          </Box>
          <Flex align="center">
            <Input
              type="email"
              placeholder="Email"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              isDisabled={remainingSeats <= 0}
            />
            <Button
              bg="teal"
              color="white"
              _hover={{ opacity: 0.7 }}
              ml={3}
              onClick={handleInviteUser}
              isDisabled={remainingSeats <= 0}
            >
              招待
            </Button>
          </Flex>
        </Flex>
        <Table mt={5}>
          <Thead>
            <Tr>
              <Th>ユーザー名</Th>
              <Th>メールアドレス</Th>
              <Th>共有トークスクリプトの権限</Th>
              <Th>トレーニングログの閲覧権限</Th>
              <Th>操作</Th>
            </Tr>
          </Thead>
          <Tbody>
            {company?.company_users.map((memberRole) => (
              <Tr key={memberRole.user.id}>
                <Td>{memberRole.user.username}</Td>
                <Td>{memberRole.user.email}</Td>
                <Td>
                  <Select
                    value={memberRole.role}
                    onChange={(e) =>
                      updateRole(memberRole.user.id, e.target.value)
                    }
                  >
                    <option value="viewer">ロープレのみ</option>
                    <option value="editor">編集者</option>
                  </Select>
                </Td>
                <Td>
                  <Select
                    value={memberRole.class_type}
                    onChange={(e) =>
                      updateClassType(memberRole.user.id, e.target.value)
                    }
                  >
                    <option value="member">メンバー</option>
                    <option value="leader">リーダー</option>
                  </Select>
                </Td>
                <Td>
                  <Button
                    bg="red.500"
                    color="white"
                    size="sm"
                    _hover={{ opacity: 0.7 }}
                    onClick={() => handleRemoveUser(memberRole.user.id)}
                    isDisabled={memberRole.user.id === company.admin}
                  >
                    削除
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Container>
  );
};

export default TeamManagement;
