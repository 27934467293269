/** @format */

import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Text,
  Flex,
  Heading,
  useToast,
  Tag,
  chakra,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { MdOutlineVolumeUp, MdCrisisAlert } from "react-icons/md";
import { FaBolt, FaRegTimesCircle } from "react-icons/fa";
import apiClient from "./apiClient";
import { useViewMode } from "./ViewModeContext";
import { useLoading } from "./LoadingContext";
import { useAuth } from "./AuthProvider";
import { speak } from "./SpeechUtils";
import Dialogue from "./Dialogue";

import styled, { keyframes } from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const AddButtonContainer = styled.div`
  margin: 12px 26px;
  text-align: right;
`;

const RedText = styled(Text)`
  color: #e65c70;
  width: 314px;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  margin-bottom: 12px;
  padding: 6px;
  border: 2px solid #e65c70;
  border-radius: 8px;
`;

const StyledText = styled.p<{ color?: string; fontWeight?: string }>`
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 18px;
  color: ${(props) => props.color || "inherit"};
  font-weight: ${(props) => props.fontWeight || "inherit"};
`;

const BlinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;

const ChakraMdOutlineVolumeUp = chakra(MdOutlineVolumeUp);
const BlinkingIcon = styled(ChakraMdOutlineVolumeUp)`
  animation: ${BlinkAnimation} 2s infinite;
`;

const ChakraMdCrisisAlert = chakra(MdCrisisAlert);
const ErrorIcon = styled(ChakraMdCrisisAlert)`
  color: red;
  animation: ${BlinkAnimation} 2s infinite;
`;

interface ExpectedQuestionsTableProps {
  talkId: number;
  talkScriptId: number;
}

interface ExpectedQuestion {
  id: number;
  question: string;
  background: string;
  answer: string;
  talk: number;
  user: number;
  created_at: string;
  updated_at: string;
}

const ExpectedQuestionsTable: React.FC<ExpectedQuestionsTableProps> = ({
  talkId,
  talkScriptId,
}) => {
  const [questions, setQuestions] = useState<ExpectedQuestion[]>([]);
  const [currentQuestion, setCurrentQuestion] =
    useState<ExpectedQuestion | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDetailModalOpen,
    onOpen: onDetailModalOpen,
    onClose: onDetailModalClose,
  } = useDisclosure();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [questionText, setQuestionText] = useState("");
  const [backgroundText, setBackgroundText] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [editingQuestionId, setEditingQuestionId] = useState<number | null>(
    null
  );
  const [dialogueHistoryId, setDialogueHistoryId] = useState<number | null>(
    null
  );
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [randomQuestion, setRandomQuestion] = useState("");
  const [isReactModalOpen, setIsReactModalOpen] = useState(false);
  const [noQuestions, setNoQuestions] = useState(false);
  const { viewMode } = useViewMode();
  const { loading, setLoading } = useLoading();
  const { user } = useAuth();
  const toast = useToast();

  const fetchExpectedQuestions = async () => {
    if (talkId) {
      try {
        setLoading(true);
        const response = await apiClient.get(
          `/api/expected_questions/?talk_id=${talkId}`
        );
        setQuestions(response.data);
      } catch (error) {
        console.error("Error fetching expected questions:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchExpectedQuestions();
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    if (!questionText.trim() || !backgroundText.trim() || !answerText.trim()) {
      setErrorMessage("反論・質問、真意や背景、回答の例は必須入力項目です");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3500);
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      if (editingQuestionId) {
        // 更新処理
        const response = await apiClient.patch(
          `/api/expected_questions/${editingQuestionId}/`,
          {
            question: questionText,
            background: backgroundText,
            answer: answerText,
          }
        );
        if (response.status === 200) {
          toast({
            title: "更新しました。",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setEditingQuestionId(null);
          await fetchExpectedQuestions();
        }
      } else {
        const response = await apiClient.post("/api/expected_questions/", {
          question: questionText,
          background: backgroundText,
          answer: answerText,
          talk: talkId,
          talk_script: talkScriptId,
        });
        toast({
          title: "登録しました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await fetchExpectedQuestions();
      }
    } catch (error) {
      console.error("Error saving the question:", error);
      toast({
        title: "反論・質問の登録に失敗しました。",
        description: "再試行してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleDelete = async (id: number) => {
    if (window.confirm("本当に削除しますか？")) {
      try {
        setLoading(true);
        const response = await apiClient.delete(
          `/api/expected_questions/${id}/`
        );
        toast({
          title: "削除しました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await fetchExpectedQuestions();
      } catch (error) {
        console.error("Error deleting the question:", error);
        toast({
          title: "削除に失敗しました。",
          description: "再試行してください。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdate = (question: ExpectedQuestion) => {
    setEditingQuestionId(question.id);
    setQuestionText(question.question);
    setBackgroundText(question.background);
    setAnswerText(question.answer);
    onOpen();
  };

  const handleNewQuestion = () => {
    setEditingQuestionId(null);
    setQuestionText("");
    setBackgroundText("");
    setAnswerText("");
    onOpen();
  };

  const handleClose = () => {
    setEditingQuestionId(null);
    setQuestionText("");
    setBackgroundText("");
    setAnswerText("");
    onClose();
  };

  const getRandomQuestion = async (
    talkId: number
  ): Promise<{ id: number; question: string; noQuestions?: boolean }> => {
    try {
      const response = await apiClient.get(
        `/api/expected_questions/?talk_id=${talkId}`
      );
      const questions = response.data;
      if (questions.length === 0) {
        return { id: -1, question: "", noQuestions: true };
      }
      const randomQuestion =
        questions[Math.floor(Math.random() * questions.length)];
      return {
        id: randomQuestion.id,
        question: randomQuestion.question,
        noQuestions: false,
      };
    } catch (error) {
      console.error("Failed to fetch questions:", error);
      throw error;
    }
  };

  const handleInquiryButtonClick = async () => {
    setErrorMessage("");
    try {
      setIsSpeaking(true);
      const fetchedRandomQuestion = await getRandomQuestion(talkId);
      if (fetchedRandomQuestion.noQuestions) {
        setErrorMessage("反論・質問が登録されていません");
        setTimeout(() => setIsSpeaking(false), 2000);
        return;
      }
      setRandomQuestion(fetchedRandomQuestion.question);
      speak(fetchedRandomQuestion.question, "male", () => setIsSpeaking(false));
      // 新しいDialogueHistoryオブジェクトを作成
      const dialogueHistoryResponse = await apiClient.post(
        `/api/expected_questions/dialogue_histories/`,
        {
          talk_script_id: talkScriptId,
          expected_question: fetchedRandomQuestion.id,
        }
      );

      await apiClient.post("/api/cohort_analysis_logs/create_log/", {
        company: user?.company?.id,
        event_type: "dialogue",
        additional_info: {
          talk_script_id: talkScriptId,
          expected_question: fetchedRandomQuestion.id,
        },
      });

      const newDialogueHistoryId = dialogueHistoryResponse.data.id;

      setIsReactModalOpen(true);

      // dialogueHistoryの状態を更新
      setDialogueHistoryId(newDialogueHistoryId);
    } catch (error) {
      console.error("Error during inquiry:", error);
    }
  };

  const handleSelectedQuestionClick = async (question: ExpectedQuestion) => {
    setErrorMessage("");
    try {
      setIsSpeaking(true);
      speak(question.question, "male", () => setIsSpeaking(false));
      const response = await apiClient.post(
        `/api/expected_questions/dialogue_histories/`,
        {
          talk_script_id: talkScriptId,
          expected_question: question.id,
        }
      );

      await apiClient.post("/api/cohort_analysis_logs/create_log/", {
        company: user?.company?.id,
        event_type: "dialogue",
        additional_info: {
          talk_script_id: talkScriptId,
          expected_question: question.id,
        },
      });

      setIsReactModalOpen(true);
      setDialogueHistoryId(response.data.id);
    } catch (error) {
      console.error("Error during inquiry:", error);
    } finally {
      setIsSpeaking(false);
    }
  };

  const closeReactModal = () => {
    setIsReactModalOpen(false);
  };

  const NoQuestionsContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="380px"
        width="100%"
        textAlign="center"
      >
        <Text fontSize="xl" fontWeight="bold" marginBottom="4">
          反論・質問は未登録です
        </Text>
        {!viewMode && (
          <Text color="gray.600">
            このトークで想定されるお客様の反論・質問を登録しましょう（複数登録可）
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Container>
      {viewMode && (
        <RedText>本機能はβ版ですので、ご了承の上でご利用ください。</RedText>
      )}
      {!viewMode && (
        <AddButtonContainer>
          <Button
            className="create-talkscript-button"
            onClick={handleNewQuestion}
            aria-label="想定される反論・質問を作成"
            backgroundColor="#7e70ff"
            color="white"
            _hover={{ opacity: 0.7 }}
          >
            反論・質問を作成
          </Button>
        </AddButtonContainer>
      )}
      {questions.length === 0 ? (
        <NoQuestionsContent />
      ) : (
        <>
          {viewMode && (
            <AddButtonContainer>
              <Button
                colorScheme="purple"
                onClick={handleInquiryButtonClick}
                mr={2}
              >
                ランダム応酬話法トレ
              </Button>
            </AddButtonContainer>
          )}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th width="85%">反論・質問の内容</Th>
                {viewMode ? (
                  <Th width="15%">応酬話法トレ</Th>
                ) : (
                  <Th width="15%">操作</Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {questions.map((question: ExpectedQuestion) => (
                <Tr key={question.id}>
                  <Td
                    borderTop="1px solid #e2e8f0"
                    borderBottom="1px solid #e2e8f0"
                    cursor="pointer"
                    onClick={() => {
                      setCurrentQuestion(question);
                      onDetailModalOpen();
                    }}
                    _hover={{ backgroundColor: "#edebf5" }}
                  >
                    <Flex align="center">
                      <FaBolt size="16px" color="#ad83d4" />
                      <Text ml={1}>{question.question}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    {viewMode ? (
                      <Button
                        colorScheme="purple"
                        size="sm"
                        p={5}
                        onClick={() => handleSelectedQuestionClick(question)}
                      >
                        開始
                      </Button>
                    ) : (
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<EditIcon />}
                          variant="outline"
                          isDisabled={question.user !== user?.id}
                        />
                        <MenuList>
                          <MenuItem onClick={() => handleUpdate(question)}>
                            編集
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleDelete(question.id)}
                            color="#d94666"
                          >
                            削除
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingQuestionId ? "反論・質問の編集" : "反論・質問の作成"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && (
              <Text color="red" mb="4">
                {errorMessage}
              </Text>
            )}
            <Text mb="2">
              反論・質問の内容 <span style={{ color: "red" }}>*</span>
            </Text>
            <Textarea
              value={questionText}
              onChange={(e) => {
                setQuestionText(e.target.value);
                setErrorMessage(null);
              }}
              placeholder="例：IPアドレス制限はありますか？"
              mb="4"
            />
            <Text mb="2">
              真意や背景<span style={{ color: "red" }}>*</span>
            </Text>
            <Textarea
              value={backgroundText}
              onChange={(e) => {
                setBackgroundText(e.target.value);
                setErrorMessage(null);
              }}
              placeholder="例：セキュリティが心配"
              mb="4"
            />
            <Text mb="2">
              回答の例 <span style={{ color: "red" }}>*</span>
            </Text>
            <Textarea
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              placeholder="例：IPアドレス制限はございませんが、セキュリティ面を懸念されてのことでしたらクライアント端末認証がございます"
            />
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSubmit}
              isDisabled={loading}
            >
              保存
            </Button>
            <Button variant="ghost" onClick={onClose}>
              キャンセル
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isReactModalOpen} onClose={closeReactModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            backgroundColor="#2b355c"
          >
            <Box
              display="flex"
              alignItems="center"
              marginLeft="12px"
              marginTop="10px"
              width="90%"
            >
              <Flex align="center">
                <FaBolt size="24px" color="white" />
                <Heading size="md" ml={2} color="white">
                  応酬話法トレーニング
                </Heading>
              </Flex>
            </Box>
            <IconButton
              icon={<FaRegTimesCircle size="26px" />}
              aria-label="Close modal"
              color="white"
              backgroundColor="transparent"
              _hover={{
                opacity: 0.7,
              }}
              onClick={closeReactModal}
            />
          </ModalHeader>
          <ModalBody>
            <Dialogue dialogueHistoryId={dialogueHistoryId} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isSpeaking} onClose={() => setIsSpeaking(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {errorMessage || noQuestions ? (
              <ErrorIcon boxSize={24} mx="auto" />
            ) : (
              <BlinkingIcon boxSize={24} mx="auto" />
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {noQuestions ? (
              <StyledText color="red" fontWeight="bold">
                反論が登録されていません
              </StyledText>
            ) : errorMessage ? (
              <StyledText color="red" fontWeight="bold">
                {errorMessage}
              </StyledText>
            ) : (
              <StyledText>反論・質問をランダムで再生しています</StyledText>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDetailModalOpen} onClose={onDetailModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>反論・質問の詳細</ModalHeader>
          <ModalBody>
            <Box>
              <Flex align="center" mb={6}>
                <Tag colorScheme="blue" mr={2}>
                  反論・質問
                </Tag>
                <Text flex="1">{currentQuestion?.question}</Text>
              </Flex>
              <Flex align="center" mb={6}>
                <Tag colorScheme="green" mr={2}>
                  真意や背景
                </Tag>
                <Text flex="1">{currentQuestion?.background}</Text>
              </Flex>
              <Flex align="center" mb={6}>
                <Tag colorScheme="red" mr={2}>
                  回答の例　
                </Tag>
                <Text flex="1">{currentQuestion?.answer}</Text>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onDetailModalClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ExpectedQuestionsTable;
