/** @format */

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  VStack,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  MdEditDocument,
  MdVideoCameraFront,
  MdWbCloudy,
  MdOutlineStackedBarChart,
  MdGroups,
  MdSpatialAudioOff,
} from "react-icons/md";
import TalkScriptList from "./TalkScriptList";
import ReportList from "./ReportList";
import WorkoutLog from "./WorkoutLog";
import PersonaList from "./PersonaList";
import { DemonstrationList } from "./DemonstrationList";
import TeamManagement from "./TeamManagement ";
import { useAuth } from "./AuthProvider";

interface MenuItemProps {
  icon: JSX.Element;
  label: string;
  tabName: string;
}

const Dashboard: React.FC = () => {
  const { user, checkToken } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sidebarWidth =
    useBreakpointValue({ base: "82px", md: "214px" }) || "228px";
  const showIconsOnly = useBreakpointValue({ base: true, md: false });
  let defaultTab = "talkscript";

  switch (params.get("tab")) {
    case "talkscript":
      defaultTab = "talkscript";
      break;
    case "training":
      defaultTab = "training";
      break;
    case "report":
      defaultTab = "report";
      break;
    case "demo":
      defaultTab = "demo";
      break;
    case "management":
      defaultTab = "management";
      break;
    case "personalist":
      defaultTab = "personalist";
      break;

    default:
      defaultTab = "talkscript";
  }

  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    checkToken();
  }, []);

  const MenuItem = ({ icon, label, tabName }: MenuItemProps) => (
    <Flex
      align="center"
      p="2"
      cursor="pointer"
      onClick={() => setActiveTab(tabName)}
      width="100%"
      justifyContent="start"
      bg={activeTab === tabName ? "purple.100" : "transparent"}
      color={activeTab === tabName ? "purple.700" : "darkslategray"}
      fontWeight={activeTab === tabName ? "bold" : "normal"}
      borderRadius="md"
      _hover={{
        textDecoration: "underline",
        textDecorationThickness: "4px",
        textDecorationColor: "purple.500",
        textDecorationStyle: "solid",
      }}
    >
      {showIconsOnly ? (
        <IconButton
          icon={icon}
          aria-label={label}
          variant="ghost"
          width="46px"
          height="46px"
          isRound
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          mt={1}
          mb={1}
          color="darkslategray"
        >
          {icon} <Text ml={1}>{label}</Text>
        </Box>
      )}
    </Flex>
  );

  return (
    <Flex height="100vh" overflow="hidden">
      <Box
        width={sidebarWidth}
        color="darkslategray"
        p="10px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="#f5f5f5"
        overflowY="auto"
      >
        <VStack spacing={4} mt={4} align="stretch" width="100%">
          <MenuItem
            icon={<MdEditDocument />}
            label="トークスクリプト"
            tabName="talkscript"
          />
          <MenuItem icon={<MdVideoCameraFront />} label="デモ" tabName="demo" />
          <MenuItem
            icon={<MdSpatialAudioOff />}
            label="壁打ち（β版）"
            tabName="personalist"
          />
          <MenuItem icon={<MdWbCloudy />} label="商談記録" tabName="report" />
          <MenuItem
            icon={<MdOutlineStackedBarChart />}
            label="トレーニングログ"
            tabName="training"
          />
          {user && user.is_company_admin && (
            <MenuItem
              icon={<MdGroups />}
              label="チーム管理"
              tabName="management"
            />
          )}
        </VStack>
      </Box>
      <Box flex="1" overflowY="auto" maxHeight="100vh">
        {activeTab === "talkscript" && <TalkScriptList />}
        {activeTab === "demo" && <DemonstrationList />}
        {activeTab === "report" && <ReportList />}
        {activeTab === "personalist" && <PersonaList />}
        {activeTab === "training" && (
          <WorkoutLog isActive={activeTab === "training"} />
        )}
        {activeTab === "management" && <TeamManagement />}
      </Box>
    </Flex>
  );
};

export default Dashboard;
